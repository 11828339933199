import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { updateUniversity } from '../../../@redux/dispatch/university';

function UpdateUniversityModal({ isOpen, closeModal }) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const universityDetails = useSelector(state => (state.university?.university_details?.data?.[0]))
    console.log("university details info ", universityDetails)
    const [formData, setFormData] = useState({});
    const dispatch = useDispatch()

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("form data for university are:- ", formData)
        dispatch(updateUniversity(formData, closeModal))

    }
    useEffect(() => {
        setFormData({
            Name: universityDetails?.Name,
            University_Description: universityDetails?.University_Description,
            Country: universityDetails?.Country,
            Website_Link: universityDetails?.Website_Link,
            Requirement: universityDetails?.Requirement,
            id: universityDetails?.id
        })

        // return () => {
        //     setFormData({})
        // }

    }, [universityDetails])
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={closeModal}
            contentLabel="New Application Modal"
            className="shadow-lg rounded-5 modal-lg"
        >
            <ModalHeader className='d-flex justify-content-center'>
                <h1>Update University</h1>
                <button
                    type="button"
                    className="btn-close"
                    // onClick={() => setIsModalOpen(false)}
                    onClick={closeModal}

                />

            </ModalHeader>

            <ModalBody>
                <div className=''>

                    <div>
                        <form onSubmit={handleSubmit}>
                            <div className='row clearfix'>
                                <div className="col-12 ">
                                    <label className='form-check-label' htmlFor='Name'>University Name</label>
                                    <input className="form-control" name="Name" id="Name" placeholder='Enter University Name' value={formData.Name} onChange={handleInputChange} required />

                                </div>

                                <div className="col-12 ">
                                    <label className='form-check-label' htmlFor='University_Description'>Univeristy Description</label>
                                    <textarea className="form-control" id='University_Description' name='University_Description' rows="4" placeholder='Enter Your Description Here...' value={formData.University_Description} onChange={handleInputChange} required />
                                </div>

                                <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                    <label className='form-check-label' htmlFor='Country'>Country</label>
                                    <input className="form-control" id='Country' name='Country' placeholder='Enter Your Country' value={formData.Country} onChange={handleInputChange} required />
                                </div>

                                <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 ">
                                    <label className='form-check-label' htmlFor='Website_Link'>University URL</label>
                                    <input type='url' className="form-control" id='Website_Link' name='Website_Link' placeholder='Enter Your University URL' value={formData.Website_Link} onChange={handleInputChange} required />
                                </div>

                                <div className="col-12 ">
                                    <label className='form-check-label' htmlFor='Requirement'>Requirement</label>
                                    <textarea className="form-control" id='Requirement' name='Requirement' placeholder='Enter Requirement Criteria...' value={formData.Requirement} onChange={handleInputChange} required />
                                </div>
                            </div>

                            <div className='text-end'>
                                <button className='btn btn-gradient px-5'>
                                    SUBMIT
                                </button>
                            </div>

                        </form>
                    </div>


                </div>
            </ModalBody>



        </Modal>
    )
}

export default UpdateUniversityModal