import axios from "axios";
import { getAllMergeTagsStart, getAllMergeTagsSuccess } from "../../../actions/template/GetAllMergeTags";
import { closeLoader, openLoader } from "../../../actions/config";
import apiUrl from "../../../ApiUrl";

export const getAllMergeTagsFields = (Id) => async (dispatch, getState) => {
    const baseUrl = getState().config.baseUrl
    dispatch(getAllMergeTagsStart());
    dispatch(openLoader());
    try {
        const response = await axios.get(`${apiUrl}/api/template/all/fields/details/all/false?id=${Id}`);
        dispatch(getAllMergeTagsSuccess(response.data))
    } catch (error) {
        console.log(error)
    }
    finally {
        dispatch(closeLoader());

    }
};



