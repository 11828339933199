import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isCounsellorStudents: false,
    counStudentsMessage: "",
    error: false,
    counStudentsList: [],
    loading: true
}

export const getCounStudentsSlice = createSlice({
    name: 'getCounStudents',
    initialState,
    reducers: {
        getCounStudentsStart: (state) => {
            return { ...state, counStudentsMessage: "Getting counsellor students list started ...", error: false };
        },
        getCounStudentsSuccess: (state, action) => {
            return { ...state, counStudentsMessage: "All counsellor students fetched successfully", error: false, isCounsellorStudents: true, loading: false, counStudentsList: action.payload };
        },
        resetCounStudents: (state) => {
            return { ...state, loading: true, counStudentsList: [] }
        }

    }
})

export const { getCounStudentsStart, getCounStudentsSuccess, resetCounStudents } = getCounStudentsSlice.actions;

export default getCounStudentsSlice.reducer;