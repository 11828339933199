import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { filterCounUniversity, filterUniversity, resetCounUniversity, resetUniversity } from '../../../@redux/dispatch/filters';

function Filters({ userRole }) {
    const [destination, setDestination] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        console.log("changes on destination ", destination)
    }, [destination])

    const handleDestinationChange = (e) => {
        const { name, checked } = e.target;
        console.log("value of name ", name);
        console.log("value of checked ", checked);
        if (checked) {
            setDestination([...destination, name]);
        }
        else {
            setDestination(destination.filter(dest => dest !== name));
        }
    }

    const handleSubmit = (e) => {
        alert('Hey')
        console.log("inside submit button destination", destination);
        e.preventDefault()
        if (userRole !== 'counsellor') {
            dispatch(filterUniversity({ destination }))
        }
        else {
            dispatch(filterCounUniversity({ destination }));
        }
    }

    const handleReset = (e) => {
        e.preventDefault()
        setDestination([]);
        const checkboxes = document.querySelectorAll('input[type="checkbox"]');
        checkboxes.forEach(checkboxes => checkboxes.checked = false);
        if (userRole !== 'counsellor') {
            dispatch(resetUniversity())
        }
        else {
            dispatch(resetCounUniversity())
        }
    }

    return (

        <div className="col-12 col-lg-12 col-xl-2 col-xxl-2 filter-desktop  customize-mobile  p-1" >
            <div className="f-title ">
                <span id="filter-close">
                    <i className="bi bi-chevron-left" />
                </span>{" "}
                Filters
                <span className="reset-button desktop">
                    <input type="button" name="reset" defaultValue="Reset" onClick={handleReset} />
                </span>
            </div>
            <form onSubmit={handleSubmit}>
                <div className="form-fields ">
                    <h6 className="filter-title">Destination</h6>
                    <div className="checkboxes filter-checkboxes">
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                defaultValue=""
                                name="United Kingdom"
                                id="United Kingdom"
                                onChange={handleDestinationChange}

                            />
                            <label
                                className="form-check-label"
                                htmlFor="United Kingdom"
                            >
                                United Kingdom
                            </label>
                        </div>
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                defaultValue=""
                                name="Australia"
                                id="Australia"
                                onChange={handleDestinationChange}
                            />
                            <label className="form-check-label" htmlFor="Australia">
                                Australia
                            </label>
                        </div>
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                defaultValue=""
                                name="USA"
                                id="USA"
                                onChange={handleDestinationChange}
                            />
                            <label className="form-check-label" htmlFor="USA">
                                USA
                            </label>
                        </div>
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                defaultValue=""
                                name="Canada"
                                id="Canada"
                                onChange={handleDestinationChange}
                            />
                            <label className="form-check-label" htmlFor="Canada">
                                Canada
                            </label>
                        </div>
                    </div>

                    <button className='btn btn-purple' type='submit'>Search Results</button>
                </div>
            </form>

        </div>

    )
}

export default Filters