import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    isMergeTags: false,
    mergeTagsMessage: "",
    error: false,
    allMergeTags: []
}

export const getAllMergeTagsSlice = createSlice({
    name: 'getAllMergeTags',
    initialState,
    reducers: {
        getAllMergeTagsStart: (state) => {
            return { ...state, mergeTagsMessage: "Fetching merge tags started ...", error: false, };
        },
        getAllMergeTagsSuccess: (state, action) => {
            return { ...state, mergeTagsMessage: "All merge tags fetched successfully", error: false, isMergeTags: true, allMergeTags: action.payload };
        }

    }
})

export const { getAllMergeTagsStart, getAllMergeTagsSuccess } = getAllMergeTagsSlice.actions;

export default getAllMergeTagsSlice.reducer;