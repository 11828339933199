import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    isApplication: false,
    applicationMessage: "",
    error: false,
    applicationsList: [],
    agentApplicationsList: [],
    counApplcationsList: [],
    loading: true
}
export const getApplicationsSlice = createSlice({
    name: 'getApplication',
    initialState,
    reducers: {
        getApplicationsStart: (state) => {
            return { ...state, applicationMessage: "Fetching applications started ...", error: false, };
        },
        getApplicationsSuccess: (state, action) => {
            return { ...state, applicationMessage: "All applications fetched successfully", loading: false, error: false, isApplication: true, applicationsList: action.payload };
        },
        getAgentApplicationsStart: (state) => {
            return { ...state, applicationMessage: "Fetching applications started ...", error: false, };
        },
        getAgentApplicationsSuccess: (state, action) => {
            return { ...state, applicationMessage: "All applications fetched successfully", error: false, loading: false, isApplication: true, agentApplicationsList: action.payload };
        },
        getCounApplicationsStart: (state) => {
            return { ...state, applicationMessage: "Fetching applications started ...", error: false, };
        },
        getCounApplicationsSuccess: (state, action) => {
            return { ...state, applicationMessage: "All applications fetched successfully", error: false, loading: false, isApplication: true, counApplcationsList: action.payload };
        },
        resetApplications: (state) => {
            return { ...state, applicationsList: [], agentApplicationsList: [], counApplcationsList: [], loading: true }
        }
    }
})
export const { getApplicationsStart, getApplicationsSuccess, getAgentApplicationsStart, getAgentApplicationsSuccess, getCounApplicationsStart, getCounApplicationsSuccess, resetApplications } = getApplicationsSlice.actions;
export default getApplicationsSlice.reducer;