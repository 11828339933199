import React from 'react'
import MobMenuHeader from '../../MobMenuHeader'
import UnivLeftSidebar from '../../UnivLeftSidebar'

function Layout({ children }) {
    return (
        <div>
            <MobMenuHeader />
            <div className="container-fluid bg-color-50">
                <div className="row clearfix">
                    <UnivLeftSidebar />
                    {children}
                </div>
            </div>
        </div>
    )
}

export default Layout