import React from 'react'
import MobHomeHeader from '../../components/MobHomeHeader'
import HomeNavbar from '../../components/HomeNavbar'
import HomeMainSection from '../../components/HomeMainSection'
import HomeFooter from '../../components/HomeFooter'
import ToastContainer from '../../components/ToastContainer'
import LoginModal from '../../components/Modals/LoginModal'
import RegisterModal from '../../components/Modals/RegisterModal'
import VerifyModal from '../../components/Modals/VerifyModal'
import TestimonialModal from '../../components/Modals/TestimonialModal'

function AgentHomePage() {
    return (
        <>
            <MobHomeHeader />
            <HomeNavbar />
            <HomeMainSection
                title="Empower Students to Reach Their Educational Goals"
                subtitle="Open doors to global opportunities and make studying abroad a reality for your students"
            />
            <HomeFooter />
            <VerifyModal />
            <TestimonialModal />
        </>
    )
}

export default AgentHomePage