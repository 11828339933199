import React, { useEffect, useState } from 'react'
import RegisterModal from '../Modals/RegisterModal'
import LoginModal from '../Modals/LoginModal'
import { Link, NavLink } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setActiveTab } from '../../@redux/actions/config'
import { getUploadLogo } from '../../@redux/dispatch/customisation_dispatch'
import axios from 'axios'
import zIndex from '@mui/material/styles/zIndex'
import NewsletterModal from '../Modals/NewsletterModal'

function HomeNavbar() {
    const userRole = useSelector((state) => (state?.config?.profile_id))
    console.log("in home navbar user role is", userRole)
    const [showPopup, setShowPopup] = useState(true);
    const [email, setEmail] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = (e) => {
        setIsModalOpen(true);
    };
    const closeModal = () => {
        setIsModalOpen(false);
    };



    const handleSubmit = (e) => {
        e.preventDefault();
        // Send email to backend for processing
        console.log('Email submitted:', email);
        // Optionally, you can hide the popup after submission
        setShowPopup(false);
    };

    const handleClose = () => {
        setShowPopup(false);
    };

    const logoUrl = useSelector(state => state.fetch_dns?.fetch_dns_record?.logoUrl)
    const [profile, setProfile] = useState("student")
    const dispatch = useDispatch();
    const handleNavLinkClick = (newProfile) => {
        console.log("New nav link I am getting as ", newProfile)
        setProfile(newProfile);
        dispatch(setActiveTab(newProfile))
    };
    console.log("profile :", profile)

    const imgUrl = useSelector(state => state.customisation.logoUrlRes)
    const customisationRes = useSelector(state => state.customisation)
    console.log("customisation response in home navbar is here ", customisationRes)
    console.log("image url for home navbar is here ", imgUrl)

    return (
        <div className="navbar-container ">
            {/* {showPopup && (
                <div className="newsletter-popup text-center position-fixed w-100 bg-danger py-3 m-auto" style={{ zIndex: '10000000', bottom: '-20px' }} >
                    <div className="popup-content">
                        <h2>Subscribe to Our Newsletter</h2>
                        <form onSubmit={handleSubmit}>
                            <input
                                type="email"
                                placeholder="Enter your email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                            <button type="submit">Subscribe</button>
                        </form>
                        <button className="close-btn" onClick={handleClose}>X</button>
                    </div>
                </div>
            )} */}


            <nav className="navbar navbar-expand-lg sticky-top desktop">
                <div className="container">

                    <img src={(imgUrl && imgUrl?.logo && imgUrl?.logo?.endsWith("undefined")) ? '/images/logo.svg' : imgUrl?.logo} width={151} height={24} />
                    {/* <img src='/images/logo.svg' width={151} height={24} /> */}

                    <button
                        className="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#edbucketNav"
                        aria-controls="edbucketNav"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon" />
                    </button>
                    <div className="collapse navbar-collapse" id="edbucketNav">
                        <ul className="navbar-nav ms-5">
                            <li className="nav-item">
                                <NavLink className="nav-link" aria-current="page" to="/students" onClick={() => handleNavLinkClick("student")} >
                                    For Students
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                {/* <NavLink className="nav-link " aria-current="page" to="/recruiters" onClick={() => handleNavLinkClick("agent")} > */}
                                <NavLink className="nav-link " aria-current="page" to="/counsellors" onClick={() => handleNavLinkClick("agent")} >

                                    For Recruiters / Counsellors
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                {/* <NavLink className="nav-link" aria-current="page" to="/councellors" onClick={() => handleNavLinkClick("counsellor")} > */}
                                <NavLink className="nav-link" aria-current="page" to="/institutions" onClick={() => handleNavLinkClick("counsellor")} >
                                    For Institutions
                                </NavLink>
                            </li>

                        </ul>
                        <ul className="navbar-nav align-items-center ms-auto">
                            <li className="nav-item">
                                <LoginModal userProfile={profile} />
                            </li>
                            {
                                userRole !== 'counsellor' && (
                                    <li className="nav-item">
                                        <RegisterModal userProfile={profile} />
                                    </li>
                                )
                            }

                        </ul>
                    </div>
                </div>
            </nav>

            {/* <NewsletterModal isOpen={isModalOpen} closeModal={closeModal} /> */}

        </div>
    )
}

export default HomeNavbar