// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.left_sidebar__VSS-b {
    display: none;
    /* Hide the sidebar by default */
    /* Your other CSS styles */
}

.left_sidebar__VSS-b.left_show__xuJfO {
    display: block;
    /* Show the sidebar when the "show" class is added */
}`, "",{"version":3,"sources":["webpack://./src/components/LeftSidebar/left.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,gCAAgC;IAChC,0BAA0B;AAC9B;;AAEA;IACI,cAAc;IACd,oDAAoD;AACxD","sourcesContent":[".sidebar {\n    display: none;\n    /* Hide the sidebar by default */\n    /* Your other CSS styles */\n}\n\n.sidebar.show {\n    display: block;\n    /* Show the sidebar when the \"show\" class is added */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sidebar": `left_sidebar__VSS-b`,
	"show": `left_show__xuJfO`
};
export default ___CSS_LOADER_EXPORT___;
