import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAgentStudents } from '../../../@redux/dispatch/agents/GetAgentStudentsReducer'
import { agentStudentDetailsId } from '../../../@redux/dispatch/agents/GetAgentStudentDetailsReducer'
import { useNavigate } from 'react-router-dom'

function AgentStudentInfo({ isCourseName }) {
    const data = useSelector((state) => (state.agentStudents?.agentStudentsList))
    console.log("data info in agent student", data)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getAgentStudents())
    })

    const handleRowClick = (Id) => {
        dispatch(agentStudentDetailsId(Id))
        if (isCourseName != null) {
            navigate(`/applications/new-application?profile_id=${Id}&course_name=${isCourseName}`)
        }
        else {
            navigate("/applications/new-application?profile_id=" + Id)
        }
        console.log("On click on table fetched Id is", Id)
    }
    return (
        <table className='table table-bordered student-data text-center'>
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone</th>
                </tr>
            </thead>
            <tbody>
                {data?.map((student, index) => (
                    <tr key={index} role='button' onClick={() => { handleRowClick(student?.id) }}>
                        <td data-title="Name" className='text-nowrap'> {student?.Full_Name}</td>
                        <td data-title="Email" className='text-nowrap'>{student?.Email}</td>
                        <td data-title="Phone" className='text-nowrap'>{student?.Phone}</td>

                    </tr>
                ))}

            </tbody>
        </table>
    )
}

export default AgentStudentInfo