import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    isStudent: false,
    studentMessage: "",
    error: false,
    studentList: []
}

export const studentListSlice = createSlice({
    name: 'studentList',
    initialState,
    reducers: {
        studentListStart: (state) => {
            return { ...state, studentMessage: "Fetching student list started ...", error: false, studentList: [] };
        },
        studentListSuccess: (state, action) => {
            return { ...state, studentMessage: "Student list fetched successfully", error: false, isStudent: true, studentList: action.payload };
        }

    }
})

export const { studentListStart, studentListSuccess } = studentListSlice.actions;

export default studentListSlice.reducer;