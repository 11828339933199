import axios from "axios";
import { uploadFilesStart, uploadFilesSuccess } from "../../actions/uploadFiles/uploads";
import { uploadDocStart, uploadDocSuccess } from "../../actions/uploadDocuments";
import { closeLoader, openLoader } from "../../actions/config";
import apiUrl from "../../ApiUrl";

export const uploadDocs = (type, category, file) => async (dispatch, getState) => {
    const baseUrl = getState().config.baseUrl
    dispatch(uploadDocStart());
    dispatch(openLoader());
    try {
        const formData = new FormData();
        // formData.append('types', JSON.stringify([type]));
        formData.append('types', JSON.stringify([type]));
        formData.append('category', category);
        formData.append('file[0]', file);
        const response = await axios.post(
            `${apiUrl}/api/growhut/upload`,
            formData,
            // {
            //     headers: {
            //         'Content-Type': 'multipart/form-data'
            //     }
            // }
        );

        dispatch(uploadDocSuccess(response.data))
    } catch (error) {
        console.log(error)
    }
    finally {
        dispatch(closeLoader());
    }
};

export const uploadDocsExt = (description, file) => async (dispatch, getState) => {
    const baseUrl = getState().config.baseUrl
    dispatch(uploadDocStart());
    dispatch(openLoader());
    try {
        console.log("file data in api ", file)
        console.log(" api url value ", apiUrl)
        const formData = new FormData();
        formData.append('file[0]', file);
        formData.append('description[]', description);
        console.log("description is ", description)
        const response = await axios.post(
            `${apiUrl}/api/growhut/upload`,
            formData, {
            headers: {
                'Content-Type': 'multipart/form-data' // Make sure to set the content type as multipart/form-data
            }
        }
        );
        console.log("response data of document extraction ", response.data)
        dispatch(uploadDocSuccess(response.data))
    } catch (error) {
        console.log(error)
    }
    finally {
        dispatch(closeLoader());
    }
};



