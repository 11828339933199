const mob_list = [
    { flag: 'AF', label: '+93', value: '+93' },
    { flag: 'AX', label: '+358', value: '+358' },
    { flag: 'AL', label: '+355', value: '+355' },
    { flag: 'DZ', label: '+213', value: '+213' },
    { flag: 'AS', label: '+1684', value: '+1684' },
    { flag: 'AD', label: '+376', value: '+376' },
    { flag: 'AO', label: '+244', value: '+244' },
    { flag: 'AI', label: '+1', value: '+1' },
    { flag: 'AQ', label: '+672', value: '+672' },
    { flag: 'AG', label: '+1268', value: '+1268' },
    { flag: 'AR', label: '+54', value: '+54' },
    { flag: 'AM', label: '+374', value: '+374' },
    { flag: 'AW', label: '+297', value: '+297' },
    { flag: 'AU', label: '+61', value: '+61' },
    { flag: 'AT', label: '+43', value: '+43' },
    { flag: 'AZ', label: '+994', value: '+994' },
    { flag: 'BS', label: '+1242', value: '+1242' },
    { flag: 'BH', label: '+973', value: '+973' },
    { flag: 'BD', label: '+880', value: '+880' },
    { flag: 'BB', label: '+1246', value: '+1246' },
    { flag: 'BY', label: '+375', value: '+375' },
    { flag: 'BE', label: '+32', value: '+32' },
    { flag: 'BZ', label: '+501', value: '+501' },
    { flag: 'BJ', label: '+229', value: '+229' },
    { flag: 'BM', label: '+1441', value: '+1441' },
    { flag: 'BT', label: '+975', value: '+975' },
    { flag: 'BO', label: '+591', value: '+591' },
    { flag: 'BA', label: '+387', value: '+387' },
    { flag: 'BW', label: '+267', value: '+267' },
    { flag: 'BV', label: '+55', value: '+55' },
    { flag: 'BN', label: '+673', value: '+673' },
    { flag: 'BG', label: '+359', value: '+359' },
    { flag: 'BF', label: '+226', value: '+226' },
    { flag: 'MM', label: '+95', value: '+95' },
    { flag: 'BI', label: '+257', value: '+257' },
    { flag: 'KH', label: '+855', value: '+855' },
    { flag: 'CM', label: '+237', value: '+237' },
    { flag: 'CV', label: '+238', value: '+238' },
    { flag: 'KY', label: '+1345', value: '+1345' },
    { flag: 'CF', label: '+236', value: '+236' },
    { flag: 'TD', label: '+235', value: '+235' },
    { flag: 'CL', label: '+56', value: '+56' },
    { flag: 'CN', label: '+86', value: '+86' },
    { flag: 'CX', label: '+618', value: '+618' },
    { flag: 'CO', label: '+57', value: '+57' },
    { flag: 'KM', label: '+269', value: '+269' },
    { flag: 'CD', label: '+242', value: '+242' },
    { flag: 'CK', label: '+682', value: '+682' },
    { flag: 'CR', label: '+506', value: '+506' },
    { flag: 'CI', label: '+225', value: '+225' },
    { flag: 'HR', label: '+385', value: '+385' },
    { flag: 'CU', label: '+53', value: '+53' },
    { flag: 'CY', label: '+357', value: '+357' },
    { flag: 'CZ', label: '+420', value: '+420' },
    { flag: 'DK', label: '+45', value: '+45' },
    { flag: 'DJ', label: '+253', value: '+253' },
    { flag: 'EC', label: '+593', value: '+593' },
    { flag: 'EG', label: '+20', value: '+20' },
    { flag: 'SV', label: '+503', value: '+503' },
    { flag: 'GQ', label: '+240', value: '+240' },
    { flag: 'ER', label: '+291', value: '+291' },
    { flag: 'EE', label: '+372', value: '+372' },
    { flag: 'ET', label: '+251', value: '+251' },
    { flag: 'FK', label: '+500', value: '+500' },
    { flag: 'FO', label: '+298', value: '+298' },
    { flag: 'FJ', label: '+679', value: '+679' },
    { flag: 'FR', label: '+33', value: '+33' },
    { flag: 'GF', label: '+594', value: '+594' },
    { flag: 'PF', label: '+689', value: '+689' },
    { flag: 'GA', label: '+241', value: '+241' },
    { flag: 'GM', label: '+220', value: '+220' },
    { flag: 'GE', label: '+995', value: '+995' },
    { flag: 'DE', label: '+49', value: '+49' },
    { flag: 'GH', label: '+233', value: '+233' },
    { flag: 'GI', label: '+350', value: '+350' },
    { flag: 'GR', label: '+30', value: '+30' },
    { flag: 'GL', label: '+299', value: '+299' },
    { flag: 'GD', label: '+1473', value: '+1473' },
    { flag: 'GP', label: '+590', value: '+590' },
    { flag: 'GU', label: '+1671', value: '+1671' },
    { flag: 'GT', label: '+502', value: '+502' },
    { flag: 'GG', label: '+44', value: '+44' },
    { flag: 'GN', label: '+224', value: '+224' },
    { flag: 'GW', label: '+245', value: '+245' },
    { flag: 'GY', label: '+592', value: '+592' },
    { flag: 'HT', label: '+509', value: '+509' },
    { flag: 'VA', label: '+379', value: '+379' },
    { flag: 'HN', label: '+504', value: '+504' },
    { flag: 'HK', label: '+852', value: '+852' },
    { flag: 'HU', label: '+36', value: '+36' },
    { flag: 'IS', label: '+354', value: '+354' },
    { flag: 'IN', label: '+91', value: '+91' },
    { flag: 'ID', label: '+62', value: '+62' },
    { flag: 'IR', label: '+98', value: '+98' },
    { flag: 'IQ', label: '+964', value: '+964' },
    { flag: 'IE', label: '+353', value: '+353' },
    { flag: 'IL', label: '+972', value: '+972' },
    { flag: 'IT', label: '+39', value: '+39' },
    { flag: 'JM', label: '+1876', value: '+1876' },
    { flag: 'JP', label: '+81', value: '+81' },
    { flag: 'JO', label: '+962', value: '+962' },
    { flag: 'KZ', label: '+997', value: '+997' },
    { flag: 'KE', label: '+254', value: '+254' },
    { flag: 'KI', label: '+686', value: '+686' },
    { flag: 'KP', label: '+850', value: '+850' },
    { flag: 'KR', label: '+82', value: '+82' },
    { flag: 'KW', label: '+965', value: '+965' },
    { flag: 'KG', label: '+996', value: '+996' },
    { flag: 'LA', label: '+856', value: '+856' },
    { flag: 'LV', label: '+371', value: '+371' },
    { flag: 'LB', label: '+961', value: '+961' },
    { flag: 'LS', label: '+266', value: '+266' },
    { flag: 'LR', label: '+231', value: '+231' },
    { flag: 'LY', label: '+218', value: '+218' },
    { flag: 'LI', label: '+423', value: '+423' },
    { flag: 'LT', label: '+370', value: '+370' },
    { flag: 'LU', label: '+352', value: '+352' },
    { flag: 'MO', label: '+853', value: '+853' },
    { flag: 'MK', label: '+389', value: '+389' },
    { flag: 'MG', label: '+261', value: '+261' },
    { flag: 'MW', label: '+265', value: '+265' },
    { flag: 'MY', label: '+60', value: '+60' },
    { flag: 'MV', label: '+960', value: '+960' },
    { flag: 'ML', label: '+223', value: '+223' },
    { flag: 'MT', label: '+356', value: '+356' },
    { flag: 'MH', label: '+692', value: '+692' },
    { flag: 'MQ', label: '+596', value: '+596' },
    { flag: 'MR', label: '+222', value: '+222' },
    { flag: 'MU', label: '+230', value: '+230' },
    { flag: 'YT', label: '+262', value: '+262' },
    { flag: 'MX', label: '+52', value: '+52' },
    { flag: 'FM', label: '+691', value: '+691' },
    { flag: 'MD', label: '+373', value: '+373' },
    { flag: 'MC', label: '+377', value: '+377' },
    { flag: 'MN', label: '+976', value: '+976' },
    { flag: 'ME', label: '+382', value: '+382' },
    { flag: 'MS', label: '+1664', value: '+1664' },
    { flag: 'MA', label: '+212', value: '+212' },
    { flag: 'MZ', label: '+258', value: '+258' },
    { flag: 'NA', label: '+264', value: '+264' },
    { flag: 'NR', label: '+674', value: '+674' },
    { flag: 'NP', label: '+977', value: '+977' },
    { flag: 'NL', label: '+31', value: '+31' },
    { flag: 'NC', label: '+687', value: '+687' },
    { flag: 'NZ', label: '+64', value: '+64' },
    { flag: 'NI', label: '+505', value: '+505' },
    { flag: 'NE', label: '+227', value: '+227' },
    { flag: 'NG', label: '+234', value: '+234' },
    { flag: 'NU', label: '+683', value: '+683' },
    { flag: 'NO', label: '+47', value: '+47' },
    { flag: 'OM', label: '+968', value: '+968' },
    { flag: 'PK', label: '+92', value: '+92' },
    { flag: 'PW', label: '+680', value: '+680' },
    { flag: 'PS', label: '+970', value: '+970' },
    { flag: 'PA', label: '+507', value: '+507' },
    { flag: 'PG', label: '+675', value: '+675' },
    { flag: 'PY', label: '+595', value: '+595' },
    { flag: 'PE', label: '+51', value: '+51' },
    { flag: 'PH', label: '+63', value: '+63' },
    { flag: 'PN', label: '+870', value: '+870' },
    { flag: 'PL', label: '+48', value: '+48' },
    { flag: 'PT', label: '+351', value: '+351' },
    { flag: 'QA', label: '+974', value: '+974' },
    { flag: 'RO', label: '+40', value: '+40' },
    { flag: 'RU', label: '+7', value: '+7' },
    { flag: 'RW', label: '+250', value: '+250' },
    { flag: 'KN', label: '+1869', value: '+1869' },
    { flag: 'LC', label: '+1758', value: '+1758' },
    { flag: 'VC', label: '+1784', value: '+1784' },
    { flag: 'WS', label: '+685', value: '+685' },
    { flag: 'SM', label: '+378', value: '+378' },
    { flag: 'ST', label: '+239', value: '+239' },
    { flag: 'SA', label: '+966', value: '+966' },
    { flag: 'SN', label: '+221', value: '+221' },
    { flag: 'RS', label: '+381', value: '+381' },
    { flag: 'SC', label: '+248', value: '+248' },
    { flag: 'SL', label: '+232', value: '+232' },
    { flag: 'SG', label: '+65', value: '+65' },
    { flag: 'SK', label: '+421', value: '+421' },
    { flag: 'SI', label: '+386', value: '+386' },
    { flag: 'SB', label: '+677', value: '+677' },
    { flag: 'SO', label: '+252', value: '+252' },
    { flag: 'ZA', label: '+27', value: '+27' },
    { flag: 'SS', label: '+211', value: '+211' },
    { flag: 'ES', label: '+34', value: '+34' },
    { flag: 'LK', label: '+94', value: '+94' },
    { flag: 'SH', label: '+290', value: '+290' },
    { flag: 'SD', label: '+249', value: '+249' },
    { flag: 'SR', label: '+597', value: '+597' },
    { flag: 'SZ', label: '+268', value: '+268' },
    { flag: 'SE', label: '+46', value: '+46' },
    { flag: 'CH', label: '+41', value: '+41' },
    { flag: 'SY', label: '+963', value: '+963' },
    { flag: 'TW', label: '+886', value: '+886' },
    { flag: 'TJ', label: '+992', value: '+992' },
    { flag: 'TZ', label: '+255', value: '+255' },
    { flag: 'TH', label: '+66', value: '+66' },
    { flag: 'TL', label: '+670', value: '+670' },
    { flag: 'TG', label: '+228', value: '+228' },
    { flag: 'TK', label: '+690', value: '+690' },
    { flag: 'TO', label: '+676', value: '+676' },
    { flag: 'TT', label: '+1868', value: '+1868' },
    { flag: 'TN', label: '+216', value: '+216' },
    { flag: 'TR', label: '+90', value: '+90' },
    { flag: 'TM', label: '+993', value: '+993' },
    { flag: 'TC', label: '+1649', value: '+1649' },
    { flag: 'TV', label: '+688', value: '+688' },
    { flag: 'UG', label: '+256', value: '+256' },
    { flag: 'UA', label: '+380', value: '+380' },
    { flag: 'AE', label: '+971', value: '+971' },
    { flag: 'UY', label: '+598', value: '+598' },
    { flag: 'UZ', label: '+998', value: '+998' },
    { flag: 'VU', label: '+678', value: '+678' },
    { flag: 'VN', label: '+84', value: '+84' },
    { flag: 'VI', label: '+1340', value: '+1340' },
    { flag: 'WF', label: '+681', value: '+681' },
    { flag: 'YE', label: '+967', value: '+967' },
    { flag: 'ZM', label: '+260', value: '+260' },
    { flag: 'ZW', label: '+263', value: '+263' }
]

export default mob_list
