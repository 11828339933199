import React from 'react'

function ExampleModal() {
    return (
        <>
            {/* Modal */}
            <div
                className="modal fade position-absolute"
                id="exampleModal"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-fullscreen">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="staticBackdropLabel">
                                &nbsp;
                            </h1>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            />
                        </div>
                        <div className="modal-body">
                            <div className="row mb-3 clearfix">
                                <label htmlFor="sorting" className="col-4 col-sm-5 col-form-label">
                                    Sort by
                                </label>
                                <div className="col-8 col-sm-7">
                                    <select className="form-select" name="sorting" id="sorting">
                                        <option value="relevance">Relevance</option>
                                    </select>
                                </div>
                            </div>
                            <h6 className="filter-title">Destination</h6>
                            <div className="checkboxes">
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        defaultValue=""
                                        name="destination"
                                        id="unitedkingdom"
                                    />
                                    <label className="form-check-label" htmlFor="unitedkingdom">
                                        United Kingdom
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        defaultValue=""
                                        name="destination"
                                        id="australia"
                                    />
                                    <label className="form-check-label" htmlFor="australia">
                                        Australia
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        defaultValue=""
                                        name="destination"
                                        id="usa"
                                    />
                                    <label className="form-check-label" htmlFor="usa">
                                        USA
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        defaultValue=""
                                        name="destination"
                                        id="canada"
                                    />
                                    <label className="form-check-label" htmlFor="canada">
                                        Canada
                                    </label>
                                </div>
                            </div>
                            <h6 className="filter-title">Subjects</h6>
                            <div className="checkboxes">
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        defaultValue=""
                                        name="subjects"
                                        id="machinelearning"
                                    />
                                    <label className="form-check-label" htmlFor="machinelearning">
                                        Machine Learning
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        defaultValue=""
                                        name="subjects"
                                        id="health"
                                    />
                                    <label className="form-check-label" htmlFor="health">
                                        Health
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        defaultValue=""
                                        name="subjects"
                                        id="biotech"
                                    />
                                    <label className="form-check-label" htmlFor="biotech">
                                        Biotech
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        defaultValue=""
                                        name="subjects"
                                        id="chemistry"
                                    />
                                    <label className="form-check-label" htmlFor="chemistry">
                                        Chemistry
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        defaultValue=""
                                        name="subjects"
                                        id="physics"
                                    />
                                    <label className="form-check-label" htmlFor="physics">
                                        Physics
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        defaultValue=""
                                        name="subjects"
                                        id="computerscience"
                                    />
                                    <label className="form-check-label" htmlFor="computerscience">
                                        Computer Science
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        defaultValue=""
                                        name="subjects"
                                        id="maths"
                                    />
                                    <label className="form-check-label" htmlFor="maths">
                                        Maths
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        defaultValue=""
                                        name="subjects"
                                        id="english"
                                    />
                                    <label className="form-check-label" htmlFor="english">
                                        English
                                    </label>
                                </div>
                            </div>
                            <h6 className="filter-title">Course Level</h6>
                            <div className="checkboxes">
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        defaultValue=""
                                        name="courselevel"
                                        id="undergraduate"
                                    />
                                    <label className="form-check-label" htmlFor="undergraduate">
                                        Undergraduate
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        defaultValue=""
                                        name="courselevel"
                                        id="postgraduate"
                                    />
                                    <label className="form-check-label" htmlFor="postgraduate">
                                        Postgraduate
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-bs-dismiss="modal"
                            >
                                Close
                            </button>
                            <button type="button" className="btn btn-primary">
                                Understood
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default ExampleModal