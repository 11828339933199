import React, { useEffect, useState } from 'react';
import axios from "axios"
import Details from '../../students.json'
import Footer from '../../components/Footer'
import { Link, useNavigate } from 'react-router-dom'
import MobMenuHeader from '../../components/MobMenuHeader'
import UnivLeftSidebar from '../../components/UnivLeftSidebar'
import Breadcrump from '../../components/Structures/Breadcrump/Breadcrump';
import { useDispatch, useSelector } from 'react-redux';
import { agentDetails, counsAgentsList } from '../../@redux/dispatch/counsellor/agentDetails';
import Skeleton from 'react-loading-skeleton';

function LoaderSkeleton() {
    return (
        <Skeleton count={4} className='py-3 my-1 mx-2' />
    )
}

function NoDataFound() {
    return (
        <div className='title text-center'>
            <h2 className='text-primary mt-5'> Oops! No agents are listed yet! 😔</h2>
        </div>

    )
}

function Lists() {
    const Details = useSelector((state) => (state?.counAgent?.agentList))

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleRowClick = (Id) => {
        dispatch(agentDetails(Id))
        navigate("view-agent?profile_id=" + Id)
    }
    return (
        <>
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone</th>
                </tr>
            </thead>
            <tbody>
                {Details?.map((data, index) => (
                    <tr key={index} role='button' onClick={() => { handleRowClick(data?.id) }}>
                        <td data-title="Name" className=''>{data?.Vendor_Name}</td>
                        <td data-title="Email">{data?.Email}</td>
                        <td data-title="Phone">{data?.Phone}</td>

                    </tr>
                ))}
            </tbody>
        </>
    )
}

function Agents() {
    // const [Details, setDetails] = useState([]);
    const Details = useSelector((state) => (state?.counAgent?.agentList))
    const NoList = useSelector((state) => (state?.counAgent?.agentList))
    const loading = useSelector((state) => (state?.counAgent?.loading))

    console.log("Details for counsellor in agent page", Details)
    console.log("No List for counsellor in agent page", NoList)
    console.log("Loading for counsellor in agent page", loading)

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const breadcrumps = [
        { text: 'Home', path: '/dashboard' },
        { text: 'Agents ', path: '/agents' }
    ]

    const handleRowClick = (Id) => {
        dispatch(agentDetails(Id))
        navigate("view-agent?profile_id=" + Id)
    }

    useEffect(() => {
        dispatch(counsAgentsList())
    }, []);
    return (

        <div className="col-sm-12 col-md-9 ms-sm-auto col-lg-12 col-xl-10 col-xxl-10 ps-5 ps-lg-2">
            <div className="dashboard-right">
                <div className="desktop">
                    <div className="row top-header sticky-top clearfix">
                        <Breadcrump items={breadcrumps} />

                        <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-5 col-xxl-5">
                            <h2>Agents ({Details?.length})</h2>
                        </div>
                        {/* <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-7 col-xxl-7">
                            <div className="search-bar">
                                <input
                                    type="text"
                                    name="search"
                                    id="search"
                                    className="search-area"
                                    placeholder="Search for Students"
                                />
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className="row mobile clearfix">
                    <div className="col-12">
                        <h2>Hello, Nilesh</h2>
                        <p className="subtext p-0">Welcome back!</p>
                    </div>
                </div>
                <div className="row clearfix">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                        <div className="student-table-box overflow-auto" style={{ height: '75vh' }}>
                            <table
                                className="table table-borderless student-data text-center"
                                id="studentList"
                            >
                                {loading && <LoaderSkeleton />}
                                {(!loading && NoList === undefined) && <NoDataFound />}
                                {(Details?.length > 0) && <Lists />}


                            </table>
                            <nav className="my-5" aria-label="Page navigation example">
                                {/* <ul className="pagination justify-content-center">
                                                <li className="page-item">
                                                    <Link className="page-link">
                                                        <i className="bi bi-chevron-left" />
                                                    </Link>
                                                </li>
                                                <li className="page-item">
                                                    <Link className="page-link active" to="#">
                                                        1
                                                    </Link>
                                                </li>
                                                <li className="page-item">
                                                    <Link className="page-link" to="#">
                                                        2
                                                    </Link>
                                                </li>
                                                <li className="page-item">
                                                    <Link className="page-link" to="#">
                                                        3
                                                    </Link>
                                                </li>
                                                <li className="page-item">
                                                    <Link className="page-link" to="#">
                                                        <i className="bi bi-chevron-right" />
                                                    </Link>
                                                </li>
                                            </ul> */}
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    )
}

export default Agents