import axios from "axios";

import { closeLoader, openLoader } from "../../actions/config";
import apiUrl from "../../ApiUrl";
import toastr from "toastr";

export const generateOTPUnconfirmed = (body, setIsModalOpen, setIsVerifyModalOpen, setIsForgotModalOpen) => async (dispatch, getState) => {
    // dispatch(getCourseStart());
    dispatch(openLoader())
    const baseUrl = getState().config.baseUrl
    try {
        const response = await axios.post(`${apiUrl}/api/cognito/generate/Otp`, body);
        // dispatch(getCourseSuccess(response.data))
        if (response.data) {
            setIsModalOpen(false);
            setIsVerifyModalOpen(true);
            setIsForgotModalOpen(false);
        }
    } catch (error) {
        console.log(error)
    }
    finally {
        dispatch(closeLoader())
    }
};

// export const verifyUnconfirmedOTP = (body, getState) => async (dispatch) => {
//     dispatch(openLoader())
//     const baseUrl = getState().config.baseUrl
//     try {
//         const response = await axios.post(`${apiUrl}/api/cognito/verify/Otp`, body);
//         console.log("Hey my response of verify otp is this one ", response.data)
//         if (response.data) {
//             toastr.success("Email is verified. Now proceed further");
//         }
//     } catch (error) {
//         console.log(error)
//     }
//     finally {
//         dispatch(closeLoader())
//     }
// }