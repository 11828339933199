import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import MobMenuHeader from "../MobMenuHeader";
import UnivLeftSidebar from "../UnivLeftSidebar";
import StudentInfoModal from "../Modals/StudentInfoModal";
import Footer from "../Footer";
import ToastContainer from "../ToastContainer";
import Application1Modal from "../Modals/Application1Modal";
import axios from "axios";
import Breadcrump from "../Structures/Breadcrump/Breadcrump";
import { applicationDetails } from "../../@redux/dispatch/applications/GetApplicationDetailsReducer";
import Skeleton from "react-loading-skeleton";
import { MdArrowOutward } from "react-icons/md";
import { getCounsellorsApplications } from "../../@redux/dispatch/applications/GetApplicationsReducer";
import { getStudentDetails } from "../../@redux/dispatch/students/getDetails";
import { getReleasedLetter } from "../../@redux/dispatch/Generate_offer_letter_dispatch/getReleasedOfferLetter";

function LoaderSkeleton() {
    return (
        <Skeleton count={10} className='py-3 my-1' />
    )
}

function NoDataFound() {
    return (
        <div className='title text-center '>
            <h2 className='text-primary'>Oops! It seems like no students have applied in any courses yet 😔</h2>
        </div>
    )
}

function Lists() {
    const data = useSelector((state) => (state?.applicationList?.counApplcationsList?.data))
    const [currentPage, setCurrentPage] = useState(1);
    const recordsPerPage = 5;
    const lastIndex = currentPage * recordsPerPage;
    const firstIndex = lastIndex - recordsPerPage;
    const records = data.slice(firstIndex, lastIndex);
    const nPage = Math.ceil(data.length / recordsPerPage);
    // const numbers = [...Array(nPage + 1).key()].slice(1);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleRowClick = (Id) => {
        dispatch(applicationDetails(Id))
        navigate("view-student-application?application=" + Id)
    }
    const handleViewBtn = (e, application_id) => {
        e.preventDefault()
        dispatch(getReleasedLetter({ application_id: application_id }))
    }

    const prevPage = () => {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1);
        }
    }

    const nextPage = () => {
        if (currentPage !== nPage) {
            setCurrentPage(currentPage + 1);
        }
    }

    const changeCPage = (Id) => {
        setCurrentPage(Id)
    }

    return (

        <div className='position-relative'>
            <table className="table table-borderless course-table w-100 responsive-tables">
                <thead>
                    <tr>
                        <th>Student</th>
                        <th>Course Name</th>
                        <th>Country</th>
                        <th>Duration</th>
                        <th>Status</th>
                        <th>Offer Letter</th>
                    </tr>
                </thead>
                <tbody  >
                    {
                        records?.map((student, index) => (

                            <tr className='' key={data.id} role='button'>
                                <td role='button' onClick={() => { handleRowClick(student?.id) }}>
                                    {student?.Contact_Name?.["name"]}
                                </td>
                                <td data-title="Course" role='button' onClick={() => { handleRowClick(student?.id) }}>
                                    {student?.Select_Program?.name}
                                    <span>{student.University_Name}</span>
                                </td>
                                <td data-title="Country" role='button' onClick={() => { handleRowClick(student?.id) }}>
                                    {student.Country}
                                    {/* <span>{student.Country}</span> */}
                                </td>
                                <td data-title="Duration" role='button' onClick={() => { handleRowClick(student?.id) }}>{student.Course_Duration}</td>
                                <td data-title="Status" role='button' onClick={() => { handleRowClick(student?.id) }}>{student.Stage}</td>
                                <td data-title="OfferLetter" className=''>
                                    {student.Offer_Letter_Student !== null ? (
                                        <button
                                            className="btn btn-purple"
                                            onClick={(e) => handleViewBtn(e, student?.id)}
                                        >
                                            View
                                        </button>
                                    ) : (
                                        <button className="btn btn-purple disabled" disabled>
                                            View
                                        </button>
                                    )}
                                </td>
                            </tr>

                        ))}
                </tbody>
            </table>

            {
                data?.length > 4 ?
                    <nav className=" position-absolute bottom-0 start-50 translate-middle-x mb-2">
                        <ul class="pagination">
                            <li class="page-item">
                                <a class="page-link" href="#" aria-label="Previous" onClick={prevPage}>
                                    <span aria-hidden="true">&laquo;</span>
                                </a>
                            </li>
                            {/* <li class="page-item"><a class="page-link" href="#" onClick={() => changeCPage(1)}>1</a></li> */}
                            {/* <li class="page-item"><a class="page-link" href="#" onClick={() => changeCPage(2)}>2</a></li> */}
                            <li class="page-item">
                                <a class="page-link" href="#" aria-label="Next" onClick={nextPage}>
                                    <span aria-hidden="true">&raquo;</span>
                                </a>
                            </li>
                        </ul>
                    </nav>

                    :
                    null
            }


        </div>

    )
}

function ApplicationsListCouncellor() {
    // const [data, setData] = useState([]);
    const [recordsPerPage, setRecordsPerPage] = useState(5);
    const data = useSelector((state) => (state?.applicationList?.counApplcationsList?.data))
    const noData = useSelector((state) => (state?.applicationList?.counApplcationsList))
    const loading = useSelector((state) => (state?.applicationList?.loading))
    const dispatch = useDispatch();
    console.log("application list for counsellor", data?.length)
    console.log('No data found for counsellor in application ', noData?.length)
    console.log("Loading info for counsellor in application ", loading)
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const breadcrumps = [
        { text: 'Home', path: '/dashboard' },
        { text: 'All Applications', path: '/applications' }
    ]

    useEffect(() => {
        dispatch(getCounsellorsApplications())
        dispatch(getStudentDetails())
    }, []);

    const handleRecordsChanges = (e) => {
        setRecordsPerPage(parseInt(e.target.value, 10));
    }

    return (
        <>
            <div className="col-xl-10 col-xxl-10 col-md-9 ms-sm-auto col-lg-12">
                <div className="course-grid ">
                    <div className="desktop tablet">
                        <div className="fixed-header sticky-top w-100 clearfix">
                            <Breadcrump items={breadcrumps} />
                            <div className="row align-items-center clearfix">
                                <div className="col-9 col-lg-9">
                                    <h5 className="course-title">Your Applications ({data?.length}) </h5>
                                </div>
                                <div className="col-12 col-lg-3">
                                    <div className="desktop">
                                        <label
                                            htmlFor="records"
                                            className="col-form-label me-3"
                                        >
                                            Records Per Page
                                        </label>
                                        <select
                                            className="select-filter"
                                            name="records"
                                            id="records"
                                            onChange={handleRecordsChanges}
                                        >
                                            <option value={5}>5</option>
                                            <option value={10}>
                                                10
                                            </option>
                                            <option value={15}>15</option>
                                        </select>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center mobile mb-3 clearfix">
                        <div className="col-8">
                            <h5 className="course-title-mobile">Your Applications</h5>
                        </div>
                        <div className="col-4 text-end">
                            <Link
                                to="/create-application"
                                className="btn btn-purple"
                            >
                                New
                            </Link>
                        </div>
                    </div>
                    <div className="box-white applicationScroll ">
                        {loading && <LoaderSkeleton />}
                        {/* {(!loading && noData?.length === undefined) && <NoDataFound />} */}
                        {/* {(!loading && data?.length === 0) && <NoDataFound />} */}
                        {(!loading && data?.length > 0) ?
                            <Lists /> :
                            <NoDataFound />
                        }
                        {/* {(!loading && data?.length > 0) && <Lists />} */}
                    </div>
                </div>
                <ul className="bottom-menu">
                    <li>
                        <Link to="/dashboard">
                            <i className="bi bi-house-fill" />{" "}
                            Home
                        </Link>
                    </li>
                    <li>
                        <Link to="#" id="search-icon">
                            <i className="bi bi-search" />
                            Search
                        </Link>
                    </li>
                    <li>
                        <Link to="/profile">
                            <i className="bi bi-person-fill" />
                            Profile
                        </Link>
                    </li>
                    <li>
                        <Link
                            data-bs-toggle="offcanvas"
                            to="#mobileMenu"
                            role="button"
                            aria-controls="mobileMenuExample"
                        >
                            <i className="bi bi-list" />
                            Menu
                        </Link>
                    </li>
                </ul>
            </div>
            <Application1Modal />
            <ToastContainer />
        </>

    )
}

export default ApplicationsListCouncellor