import React, { useState } from 'react'
import UnivLeftSidebar from '../../components/UnivLeftSidebar';
import { SiChatbot } from "react-icons/si";
import { CgInsights } from "react-icons/cg";
import { RiQuestionAnswerFill } from "react-icons/ri";
import ChatAI from './ChatAI';
import Insights from './Insights';
import Actions from './Actions';

function ChatBot() {
    const [activeTab, setActiveTab] = useState('Chatbot')

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    return (

        <div className='col-md-10 ms-auto pe-0 ps-0 bg-white' style={{ height: '42rem' }}>
            <nav className="navbar navbar-light bg-white p-0 border d-flex justify-content-center" >
                <span className="navbar-brand ps-3 branding" ><b >AI Module</b> </span>
                {/* <i className="bi bi-info-circle"></i> */}
            </nav>

            <div className="d-flex justify-content-between">
                <button
                    onClick={() => handleTabClick('Chatbot')}
                    className={`btn border w-100 text-center m-3 p-3 fw-500  ${activeTab === 'Chatbot' ? 'branding-boxShadow' : 'none'}`}
                    style={{
                        fontWeight: activeTab === 'Chatbot' ? 'bold' : 'normal',
                        // boxShadow: activeTab === 'Chatbot' ? '0 0 2px 2px skyblue' : 'none',
                    }}
                ><SiChatbot size={30} /> Chatbot</button>
                <button
                    onClick={() => handleTabClick('Insights')}
                    className={`btn border w-100 text-center m-3 p-3 fw-500 ${activeTab === 'Insights' ? 'branding-boxShadow' : 'none'}`}
                    style={{
                        fontWeight: activeTab === 'Insights' ? 'bold' : 'normal',
                        // boxShadow: activeTab === 'Insights' ? '0 0 2px 2px skyblue' : 'none',
                    }}
                ><CgInsights size={30} /> Insights</button>
                <button
                    onClick={() => handleTabClick('Action')}
                    className={`btn border w-100 text-center m-3 p-3 fw-500 ${activeTab === 'Action' ? 'branding-boxShadow' : 'none'}`}
                    style={{
                        fontWeight: activeTab === 'Action' ? 'bold' : 'normal',
                        // boxShadow: activeTab === 'Action' ? '0 0 2px 2px skyblue' : 'none',
                    }}
                ><RiQuestionAnswerFill size={25} /> Action</button>
            </div>

            {/* BRAND */}
            {activeTab === 'Chatbot' && (
                <ChatAI />
            )}



            {/* THEME */}
            {activeTab === 'Insights' && (
                <Insights />
            )}

            {/* PORTAL */}
            {activeTab === 'Action' && (
                <Actions />
            )}

        </div>
    )
}

export default ChatBot