import React from 'react'
import MobMenuHeader from '../MobMenuHeader'
import UnivLeftSidebar from '../UnivLeftSidebar'
import Footer from '../Footer'
import Application1Modal from '../Modals/Application1Modal'
import ToastContainer from '../ToastContainer'
import ViewApplicationForm from './ViewApplicationForm'
import { Link } from 'react-router-dom'
import Breadcrump from '../Structures/Breadcrump/Breadcrump'

function ViewApplicationDetailsForm() {
    const breadcrumps = [
        { text: 'Home', path: '/dashboard' },
        { text: 'All Applications', path: '/applications' }
    ]
    return (
        <>
            <div className="col-md-12 ms-sm-auto col-lg-12 col-xl-10 col-xxl-10">
                <div className="course-grid">
                    <div className="desktop">
                        <div className="fixed-header sticky-top w-100 clearfix">
                            <Breadcrump items={breadcrumps} />

                            {/* <Breadcrumb data={{ main: "Home", subdivision: "All Applications", backurl: "/dashboard" }} /> */}
                            <div className="row align-items-center clearfix">
                                <div className="col-9 col-lg-4">
                                    <h5 className="course-title">Your Applications</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center mobile mb-3 clearfix">
                        <div className="col-8">
                            <h5 className="course-title-mobile">Your Applications</h5>
                        </div>

                    </div>
                    <div className="box-white applicationScroll">
                        <section className="py-0">
                            <div className="container">
                                <ViewApplicationForm />
                            </div>
                        </section>
                    </div>
                </div>
                <ul className="bottom-menu">
                    <li>
                        <Link to="/dashboard">
                            <i className="bi bi-house-fill" />{" "}
                            Home
                        </Link>
                    </li>
                    <li>
                        <Link to="#" id="search-icon">
                            <i className="bi bi-search" />{" "}
                            Search
                        </Link>
                    </li>
                    <li>
                        <Link to="/profile">
                            <i className="bi bi-person-fill" />{" "}
                            Profile
                        </Link>
                    </li>
                    <li>
                        <Link
                            data-bs-toggle="offcanvas"
                            to="#mobileMenu"
                            role="button"
                            aria-controls="mobileMenuExample"
                        >
                            <i className="bi bi-list" />
                            Menu
                        </Link>
                    </li>
                </ul>

                <div id="search-menu">
                    <div className="wrapper">
                        <form id="form" action="#" method="">
                            <div className="input-group">
                                <input
                                    type="text"
                                    name="search"
                                    id="popup-search"
                                    className="form-control"
                                    placeholder="Search here"
                                />
                                <button
                                    type="submit"
                                    className="btn btn-purple"
                                    id="popup-search-button"
                                >
                                    <i className="bi bi-search" />
                                </button>
                            </div>

                        </form>
                    </div>
                </div>
                {/* <Footer /> */}
            </div>

            {/* Modal */}
            <Application1Modal />
            <ToastContainer />
        </>
    )
}

export default ViewApplicationDetailsForm