import React from 'react'
import { useSelector } from 'react-redux'
import StudentAndAgentProfile from '../../components/StudentAndAgentProfile'
import CounsellorProfile from '../../components/CounsellorProfile'

function Profile() {
    const userRole = useSelector((state) => (state?.config?.profile_id))
    return (
        <>
            {userRole === "student" && (
                <StudentAndAgentProfile />
            )}
            {userRole === "agent" && (
                <StudentAndAgentProfile />
            )}
            {userRole === "counsellor" && (
                <CounsellorProfile />
            )}
        </>
    )
}

export default Profile