import React from 'react'

function AcademicDetailsForm() {
    return (
        <>
            <div
                className="tab-pane fade show active"
                id="pills-academic"
                role="tabpanel"
                aria-labelledby="pills-academic-tab"
            >
                Academic
            </div>
        </>
    )
}

export default AcademicDetailsForm