import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    generateLetter: "",
    error: false,
    // customMergeTags: []
    // status: null
    offerLetter: ''
}

export const generateLetterSlice = createSlice({
    name: 'generate_offer_letter',
    initialState,
    reducers: {
        createLetterStart: (state) => {
            return { ...state, generateLetter: "Creation of offer letter started ...", error: false, status: null, offerLetter: "" };
        },
        createLetterSuccess: (state, action) => {
            console.log("Status I am getting as ", action.payload)
            return { ...state, generateLetter: "Offer letter created successfully", error: false, offerLetter: action.payload };
        },
        releaseOfferLetter: (state) => {
            return { ...state }
        }
        // createCustomTagsStart: (state) => {
        //     return { ...state, customTagsMessage: "Creation of custom tags started ...", error: false, };
        // },
        // createCustomTagsSuccess: (state, action) => {
        //     return { ...state, customTagsMessage: "All custom tags created successfully", error: false, isTags: true, };
        // },
        // deleteCustomTag: (state, action) => {
        //     const updatedTags = state.customMergeTags.filter(tag => tag.field_name !== action.payload.field_name);
        //     return { ...state, customMergeTags: updatedTags };
        // }

    }
})

export const { createLetterStart, createLetterSuccess, releaseOfferLetter } = generateLetterSlice.actions;



// export default {
//     getTagsCustomReducer: getTagsCustomSlice.reducer,
//     createTagsCustomReducer: createTagsCustomSlice.reducer
// };

export default generateLetterSlice.reducer;
