import axios from "axios";
// import { applicationDetailsStart, applicationDetailsSuccess } from "../../actions/applications/GetApplicationDetails";
// import { getAgentStudentDetailsStart, getAgentStudentDetailsSuccess } from "../../actions/agents/GetAgentStudentDetails";
import { updateAgentStudentDetailsStart, updateAgentStudentDetailsSuccess } from "../../actions/agents/UpdateAgentStudentDetails";
import { closeLoader, openLoader } from "../../actions/config";
import apiUrl from "../../ApiUrl";

export const updateAgentStudentDetails = (body) => async (dispatch, getState) => {
    // alert(id)
    const baseUrl = getState().config.baseUrl
    dispatch(updateAgentStudentDetailsStart());
    dispatch(openLoader());
    try {
        const response = await axios.put(
            `${apiUrl}/api/agent/student/details/${body.id}`, body.formData
        );
        if (response) {
            // alert("Profile updated successfully")
        }

        dispatch(updateAgentStudentDetailsSuccess(response.data))
    } catch (error) {
        console.log(error)
    }
    finally {
        dispatch(closeLoader())
    }
};



