import React from 'react'
import { Link } from 'react-router-dom'
import MobMenuHeader from '../../components/MobMenuHeader'
import LeftSidebar from '../../components/LeftSidebar'
import Details from '../../students.json'
import Footer from '../../components/Footer'
import UnivLeftSidebar from '../../components/UnivLeftSidebar'

function Counsellors() {
    return (
        <>

            <MobMenuHeader />
            <div className="container-fluid">
                <div className="row clearfix">
                    <UnivLeftSidebar />
                    <div className="col-sm-12 col-md-9 ms-sm-auto col-lg-12 col-xl-10 col-xxl-10 ps-5 ps-lg-2">
                        <div className="dashboard-right">
                            <div className="desktop">
                                <div className="row top-header sticky-top clearfix">
                                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-5 col-xxl-5">
                                        <h2>Counsellors</h2>
                                    </div>
                                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-7 col-xxl-7">
                                        <div className="search-bar">
                                            <input
                                                type="text"
                                                name="search"
                                                id="search"
                                                className="search-area"
                                                placeholder="Search for Students"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mobile clearfix">
                                <div className="col-12">
                                    <h2>Hello, Nilesh</h2>
                                    <p className="subtext p-0">Welcome back!</p>
                                </div>
                            </div>
                            <div className="row clearfix">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                                    <div className="student-table-box overflow-auto" style={{ height: '75vh' }}>
                                        <table
                                            className="table table-borderless student-data"
                                            id="studentList"
                                        >
                                            <thead>
                                                <tr>
                                                    <th>
                                                        <input
                                                            type="checkbox"
                                                            name="selectAll"
                                                            id="selectAll"
                                                            className="form-check-input"
                                                        />
                                                    </th>
                                                    <th>Name</th>
                                                    <th>Email</th>
                                                    <th>Phone</th>
                                                    <th>Profile</th>
                                                    <th>Application</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {Details.map((data, index) => (
                                                    <tr>
                                                        <td>
                                                            <input
                                                                type="checkbox"
                                                                name="selection"
                                                                id="selection"
                                                                className="form-check-input"
                                                            />
                                                        </td>
                                                        <td data-title="Name" className=''>
                                                            <img
                                                                src="images/girl-avatar.png"
                                                                alt="Image goes here"
                                                            />
                                                            &nbsp;{data.name}
                                                        </td>
                                                        <td data-title="Email">{data.email}</td>
                                                        <td data-title="Phone">{data.phone}</td>
                                                        <td data-title="Profile">{data.profile}</td>
                                                        <td data-title="Application">{data.status}</td>
                                                        <td>
                                                            <Link to="#">
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width={24}
                                                                    height={24}
                                                                    viewBox="0 0 24 24"
                                                                    fill="none"
                                                                >
                                                                    <path
                                                                        fillRule="evenodd"
                                                                        clipRule="evenodd"
                                                                        d="M20.594 7.32165L6.91562 21H3V17.0844L16.6784 3.40603C17.2197 2.86466 18.0962 2.86466 18.6362 3.40603L20.594 5.36384C21.1353 5.90521 21.1353 6.78166 20.594 7.32165Z"
                                                                        stroke="#110F33"
                                                                        strokeWidth="1.5"
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                    />
                                                                    <path
                                                                        d="M14.2012 5.89412L18.1057 9.79866"
                                                                        stroke="#110F33"
                                                                        strokeWidth="1.5"
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                    />
                                                                </svg>
                                                            </Link>
                                                            <Link to="#">
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width={24}
                                                                    height={24}
                                                                    viewBox="0 0 24 24"
                                                                    fill="none"
                                                                >
                                                                    <path
                                                                        d="M18.3999 5.78369V19.5675C18.3999 20.9113 17.3044 21.9999 15.9796 21.9999H7.97959C6.65372 21.9999 5.59985 20.9113 5.59985 19.5675V5.78369"
                                                                        stroke="#110F33"
                                                                        strokeWidth="1.5"
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                    />
                                                                    <path
                                                                        d="M20 5.78378H4"
                                                                        stroke="#110F33"
                                                                        strokeWidth="1.5"
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                    />
                                                                    <path
                                                                        d="M9.86646 2.54054H14.1331"
                                                                        stroke="#110F33"
                                                                        strokeWidth="1.5"
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                    />
                                                                    <path
                                                                        d="M14.1332 10.1082V17.6757"
                                                                        stroke="#110F33"
                                                                        strokeWidth="1.5"
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                    />
                                                                    <path
                                                                        d="M9.86659 17.6757V10.1082"
                                                                        stroke="#110F33"
                                                                        strokeWidth="1.5"
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                    />
                                                                </svg>
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                ))}


                                            </tbody>
                                        </table>
                                        <nav className="my-5" aria-label="Page navigation example">
                                            <ul className="pagination justify-content-center">
                                                <li className="page-item">
                                                    <Link className="page-link">
                                                        <i className="bi bi-chevron-left" />
                                                    </Link>
                                                </li>
                                                <li className="page-item">
                                                    <Link className="page-link active" to="#">
                                                        1
                                                    </Link>
                                                </li>
                                                <li className="page-item">
                                                    <Link className="page-link" to="#">
                                                        2
                                                    </Link>
                                                </li>
                                                <li className="page-item">
                                                    <Link className="page-link" to="#">
                                                        3
                                                    </Link>
                                                </li>
                                                <li className="page-item">
                                                    <Link className="page-link" to="#">
                                                        <i className="bi bi-chevron-right" />
                                                    </Link>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <Footer /> */}
                    </div>
                </div>
            </div>
        </>

    )
}

export default Counsellors