import axios from "axios";
import { getAgentDetailsSuccess, getCounAgentDetailsStart, getCounAgentDetailsSuccess, getCounAgentListStart, getCounAgentListSuccess } from "../../../actions/counsellor/agentDetails";
import apiUrl from "../../../ApiUrl";

export const agentDetails = (Id) => async (dispatch, getState) => {
    const baseUrl = getState().config.baseUrl
    dispatch(getCounAgentDetailsStart());
    try {
        const response = await axios.get(
            `${apiUrl}/api/counsellor/agent/details/${Id}`
        );
        dispatch(getCounAgentDetailsSuccess(response.data?.data))
    } catch (error) {
        console.log(error)
    }
};

export const counsAgentsList = () => async (dispatch, getState) => {
    const baseUrl = getState().config.baseUrl
    dispatch(getCounAgentListStart());
    try {
        const response = await axios.get(
            `${apiUrl}/api/counsellor/Agent-list`
        );
        dispatch(getCounAgentListSuccess(response.data?.data))
    } catch (error) {
        console.log(error)
    }
}



