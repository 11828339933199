import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    course_id: null,
    selectedCourse: {},
    createApplicationFormData: {}

}
export const createNewApplicationSlice = createSlice({
    name: 'applicationCreated',
    initialState,
    reducers: {
        setCourseId: (state, action) => {
            return {
                ...state,
                course_id: action.payload
            };
        },
        setSelectedCourse: (state, action) => {
            return {
                ...state,
                selectedCourse: action.payload
            }
        },
        setFormDataFirstPart: (state, action) => {
            return {
                ...state,
                createApplicationFormData: action.payload,

            }
        },
        applicationStart: (state) => {
            return { ...state, error: false, createApplicationFormData: {}, selectedCourse: {}, }
        }

    },
});
export const { setCourseId, setFormDataFirstPart, applicationStart, setSelectedCourse } = createNewApplicationSlice.actions;
export default createNewApplicationSlice.reducer;