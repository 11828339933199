import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isUpload: false,
    uploadMessage: "",
    error: false,
    dataExtracted: {}

}

export const uploadDocsSlice = createSlice({
    name: 'uploadDocs',
    initialState,
    reducers: {
        uploadDocStart: (state) => {
            return { ...state, uploadMessage: "uploading files started ...", error: false, };
        },
        uploadDocSuccess: (state, action) => {
            return { ...state, uploadMessage: "uploading files success", error: false, isUpload: true, dataExtracted: action.payload };
        },
        resetDocsExt: (state) => {
            return { ...state, dataExtracted: [] }
        }

    }
})

export const { uploadDocStart, uploadDocSuccess, resetDocsExt } = uploadDocsSlice.actions;

export default uploadDocsSlice.reducer;