import axios from 'axios';
import React, { useEffect, useState } from 'react'

function DocumentsDetailsForm() {


    return (
        <>

            {/* {
                userRole === "demo" && ( */}
            <div className='text-center'>
                <h2 className='text-success'>Your Application is submitted successfully!</h2>
            </div>


            {/* )
            } */}

            {/* {
                userRole === "student" && (
                    <div
                        className="tab-pane fade show active "
                        id="pills-address"
                        role="tabpanel"
                        aria-labelledby="pills-address-tab"
                    >
                        <form >

                            <div className="form-area">
                                <div className="form-head">
                                    Your Document Details
                                    <span>We will use your document details while processing your applications</span>
                                </div>
                                <div className="form-subhead">
                                    <hr />
                                </div>
                                <div className="row clearfix">
                                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                        <label className='form-check-label'>Course name</label>
                                        <input
                                            type="text"
                                            id='courseName'
                                            name="courseName"
                                            className="form-control"
                                            placeholder="Course name"
                                        />
                                    </div>
                                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                        <label className='form-check-label'>Application date</label>
                                        <input type="date" name="applicationDate" id="applicationDate" class="form-control" placeholder="date" required />
                                    </div>
                                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                        <label className='form-check-label'>Student id</label>
                                        <input type="text" name="studentId" id="studentId" class="form-control" placeholder="Student id" />
                                    </div>
                                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                        <label className='form-check-label' htmlFor='courseStatus'>Choose course status</label>
                                        <select class="form-select" name="courseStatus" id="courseStatus">
                                            <option value="--None--">--None--</option>
                                            <option value="Full Time">Full Time</option>

                                        </select>
                                    </div>

                                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                        <label className='form-check-label'>Enter level of study</label>
                                        <input
                                            type="text"
                                            id='levelOfStudy'
                                            name="levelOfStudy"
                                            className="form-control"
                                            placeholder="Enter level of study"
                                            readOnly
                                        />
                                    </div>

                                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                        <label className='form-check-label'>Course length</label>
                                        <input
                                            type='text'
                                            name='courseLength'
                                            id='courseLength'
                                            className='form-control'
                                            placeholder='Enter course length'
                                            readOnly
                                        />
                                    </div>

                                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                        <label className='form-check-label' htmlFor='courseStartDate'>Course start date</label>
                                        <input type="date" name="courseStartDate" id="courseStartDate" class="form-control" placeholder="date" required />
                                    </div>

                                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                        <label className='form-check-label' htmlFor='courseEndDate'>Course end date</label>
                                        <input type="date" name="courseEndDate" id="courseEndDate" class="form-control" placeholder="date" required />
                                    </div>

                                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                        <label className='form-check-label' htmlFor='courseCampus'>Enter course campus</label>
                                        <select class="form-select" name="courseCampus" id="courseCampus">
                                            <option value="--None--">--None--</option>
                                            <option value="New York">New York</option>

                                        </select>
                                    </div>

                                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                        <label className='form-check-label' htmlFor='courseCampus'>Choose university type</label>
                                        <select class="form-select" name="courseCampus" id="courseCampus">
                                            <option value="--None--">--None--</option>
                                            <option value="Private">Private</option>
                                        </select>
                                    </div>

                                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                        <label className='form-check-label' htmlFor='tuitionCurrency'>Choose tuition currency</label>
                                        <select class="form-select" name="tuitionCurrency" id="tuitionCurrency">
                                            <option value="--None--">--None--</option>
                                            <option value="CAD">CAD</option>
                                            <option value="USD">USD</option>
                                        </select>
                                    </div>

                                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                        <label className='form-check-label' htmlFor='tuitionAmount'>Choose tuition amount</label>
                                        <select class="form-select" name="tuitionAmount" id="tuitionAmount">
                                            <option value="--None--">--None--</option>
                                            <option value="$10,000">$10,000</option>
                                        </select>
                                    </div>

                                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                        <label className='form-check-label' htmlFor='ifTutionPaid'>Choose tuition amount paid</label>
                                        <select class="form-select" name="ifTutionPaid" id="ifTutionPaid">
                                            <option value="--None--">--None--</option>
                                            <option value="Yes">Yes</option>
                                        </select>
                                    </div>

                                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                        <label className='form-check-label' htmlFor='conditions'>Choose conditions</label>
                                        <select class="form-select" name="conditions" id="conditions">
                                            <option value="--None--">--None--</option>
                                            <option value="No conditions">No conditions</option>
                                        </select>
                                    </div>

                                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                        <label className='form-check-label' htmlFor='onlineOrientationSessionDate'>Choose online orientation session date</label>
                                        <select class="form-select" name="onlineOrientationSessionDate" id="onlineOrientationSessionDate">
                                            <option value="--None--">--None--</option>
                                            <option value="November 16-17, 2023">November 16-17, 2023</option>
                                        </select>
                                    </div>

                                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                        <label className='form-check-label' htmlFor='classesBeginDate'>Choose classes begin date</label>
                                        <select class="form-select" name="classesBeginDate" id="classesBeginDate">
                                            <option value="--None--">--None--</option>
                                            <option value="November 20, 2023">November 20, 2023</option>
                                        </select>
                                    </div>
                                </div>

                                <div className='container'>
                                    <div className='row justify-content-end clearfix'>
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 text-end p-4" style={{ backgroundColor: '#F6F2FF' }}>
                                            <button className="btn btn-theme me-1" >Previous</button>
                                            <button className="btn btn-theme me-1" >Update profile</button>
                                            <button className="btn btn-theme" >Generate offer letter</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                )
            } */}




        </>



    )
}

export default DocumentsDetailsForm