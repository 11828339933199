import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    created_course_res: {},
    get_course_details: {},
    error: false,
}

export const courseSlice = createSlice({
    name: 'create_course',
    initialState,
    reducers: {
        created_course: (state, action) => {
            return { ...state, created_course_res: action.payload };
        },
        course_details: (state, action) => {
            return { ...state, get_course_details: action.payload }
        }

    }
})

export const { created_course, course_details } = courseSlice.actions;

export default courseSlice.reducer;
