import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isStudent: false,
    detailsMessage: "",
    error: false,
    data: {}
}

export const getStudentDetailsSlice = createSlice({
    name: 'studentDetails',
    initialState,
    reducers: {
        getDetailsStart: (state) => {
            return { ...state, detailsMessage: "Fetching details started ...", error: false, isStudent: null, };
        },
        getDetailsSuccess: (state, action) => {
            return { ...state, courseMessage: "Whole details fetched successfully", error: false, isStudent: true, data: action.payload[0] };
        },
        resetStudentDetails: (state) => {
            return { ...state, data: {} }
        }

    }
})

export const { getDetailsStart, getDetailsSuccess, resetStudentDetails } = getStudentDetailsSlice.actions;

export default getStudentDetailsSlice.reducer;