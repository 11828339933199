import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    isResend: false,
    resendOtpMessage: "",
    error: false,
    reply: ""
}

export const resendOTPSlice = createSlice({
    name: 'resendOTP',
    initialState,
    reducers: {
        resendOTPStart: (state) => {
            return { ...state, resendOtpMessage: "Resending otp started ...", error: false };
        },
        resendOTPSuccess: (state, action) => {
            return { ...state, resendOtpMessage: "Resending otp success", error: false, isResend: true, reply: action.payload };
        }

    }
})

export const { resendOTPStart, resendOTPSuccess } = resendOTPSlice.actions;

export default resendOTPSlice.reducer;