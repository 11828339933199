import { combineReducers, configureStore } from '@reduxjs/toolkit'
import courseReducer from './referencesDetailsForm'
import loginSlice from './loginAPI'
import signupSlice from './registerAPI'
import verifyOTPSlice from './actions/VerifyOTP'
import resendOTPSlice from './actions/ResendOTP'
import getCourseSlice from './actions/GetCourses'
import getAgentsSlice from './actions/agents/GetAgents'
import studentListSlice from './actions/GetStudentsList'
import universityListSlice from './actions/GetUniversities'
import getApplicationsSlice from './actions/applications/GetApplications'
import createNewApplicationSlice from './actions/applications/CreateApplication'
import getStudentDetailsSlice from './actions/students/getDetails'
import updateStudentSlice from './actions/students/updateStudent'
import getMetaFieldsSlice from './actions/applications/MetaData'
import uploadFilesSlice from './actions/uploadFiles/uploads'
import applicationDetailsSlice from './actions/applications/GetApplicationDetails'
import getAgentStudentDetailsSlice from './actions/agents/GetAgentStudentDetails'
import updateAgentStudentDetailsSlice from './actions/agents/UpdateAgentStudentDetails'
import agentNewApplicationSlice from './actions/agents/agentApplication/agentCreateApplication'
import agentAddStudentSlice from './actions/agents/addStudent'
import getCounAgentSlice from './actions/counsellor/agentDetails'
import getAgentStudentsSlice from './actions/agents/GetAgentStudents'
import uploadDocsSlice from './actions/uploadDocuments'
import getMergeTagsDefaultSlice from './actions/template/GetMergeTags'
import customTagsSlice from './actions/template/customMergeTags'
import TemplateContentSlice from './actions/template/templateContent'
import getAllMergeTagsSlice from './actions/template/GetAllMergeTags'
import generateLetterSlice from './actions/Generate_offer_letter'
import getCounStudentsSlice from './actions/counsellor/student/studentList'
import configSlice from './actions/config'
import fetchDNSSlice from './actions/fetch_dns'
import customisationSlice from './actions/customisation'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage';
import logger from 'redux-logger'
import universitySlice from './actions/university'
import courseSlice from './actions/course'
import chatNotifySlice from './actions/chatNotify'


const rootReducer = combineReducers({
    config: configSlice,
    course: courseReducer,
    login: loginSlice,
    signup: signupSlice,
    verifyOTP: verifyOTPSlice,
    resendOTP: resendOTPSlice,
    courseList: getCourseSlice,
    agents: getAgentsSlice,
    studentList: studentListSlice,
    universityList: universityListSlice,
    applicationList: getApplicationsSlice,
    createNewApplication: createNewApplicationSlice,
    studentsDetails: getStudentDetailsSlice,
    updateStudent: updateStudentSlice,
    metaFields: getMetaFieldsSlice,
    uploads: uploadFilesSlice,
    applicationDetails: applicationDetailsSlice,
    agentStudentDetails: getAgentStudentDetailsSlice,
    updateAgentStudentDetails: updateAgentStudentDetailsSlice,
    agentNewApplication: agentNewApplicationSlice,
    agentAddStudent: agentAddStudentSlice,
    // agentDetails: getAgentDetailsSlice,
    agentStudents: getAgentStudentsSlice,
    counStudents: getCounStudentsSlice,
    uploadDocuments: uploadDocsSlice,
    getMergeTagsDefaults: getMergeTagsDefaultSlice,
    customTags: customTagsSlice,
    templateContent: TemplateContentSlice,
    allMergeTags: getAllMergeTagsSlice,
    offer_letter: generateLetterSlice,
    counAgent: getCounAgentSlice,
    fetch_dns: fetchDNSSlice,
    customisation: customisationSlice,
    university: universitySlice,
    courseSlice: courseSlice,
    chatNotify: chatNotifySlice
})

const persistConfig = {
    key: 'root',
    storage,

}

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
})