import React, { useEffect, useState } from 'react'
import 'react-loading-skeleton/dist/skeleton.css'
import { BrowserRouter, Routes, Route, useNavigate, Navigate } from "react-router-dom";
import StudentList from './pages/StudentList';
import Dashboard from './pages/Dashboard';
import AboutUs from './pages/AboutUs';
import Agents from './pages/Agents';
import Applications from './pages/Applications';
import Blogs from './pages/Blogs';
import Contact from './pages/Contact';
import Courses from './pages/Courses';
import Edpay from './pages/Edpay';
import Learn from './pages/Learn';
import Profile from './pages/Profile';
import Settings from './pages/Settings';
import CreateApplications from './pages/CreateApplications';
import Universities from './pages/Universities';
import Counsellors from './pages/Counsellors';
import HomePage from './pages/HomePage'
import Prepare from './pages/Prepare'
import PersonalDetailsForm from './components/Structures/PersonalDetailsForm';
import AddressDetailsForm from './components/Structures/AddressDetailsForm';
import ReferenceDetailsForm from './components/Structures/ReferenceDetailsForm';
import EmploymentDetailsForm from './components/Structures/EmploymentDetailsForm';
import DocumentsDetailsForm from './components/Structures/DocumentsDetailsForm';
import ApiCall from './@redux/ApiCall';
import ViewApplicationDetailsForm from './components/ViewApplicationDetailsForm';
import axios from 'axios';
import StudentProfile from './pages/StudentProfile';
import AgentCreateApplication from './pages/AgentCreateApplication';
import AgentAddStudent from './pages/AgentAddStudent';
import ViewAgentApplicationDetails from './components/ViewAgentApplicationDetails';
import { useDispatch, useSelector } from 'react-redux';
import ViewStudentProfileCounsellor from './components/ViewStudentProfileCounsellor';
import ViewAgentDetailsCounsellor from './components/ViewAgentDetailsCounsellor';
import ViewApplicationDetailsCounsellor from './components/ViewApplicationDetailsCounsellor';
import Loader from './components/Loader';
import CourseOfferLetter from './pages/CourseOfferLetter';
import "./App.css"
import ChatBot from './pages/ChatBot';
import Layout from './components/Structures/Layout';
import { fetch_DNS } from './@redux/dispatch/fetch_dns_dispatch';
import Privacy from './pages/Privacy';
import AgentHomePage from './pages/AgentHomePage';
import CounsellorHomePage from './pages/CounsellorHomePage';
import { getUploadLogo } from './@redux/dispatch/customisation_dispatch';
import { setActiveTab } from './@redux/actions/config';
import StudentHomePage from './pages/StudentHomePage';
import NewsletterModal from './components/Modals/NewsletterModal';
// import { Worker } from '@react-pdf-viewer/core';
// import '@react-pdf-viewer/core/lib/styles/index.css'

function App() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = (e) => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    // openModal()
  }, [])

  axios.defaults.withCredentials = true
  const dispatch = useDispatch();
  const logoUrl = useSelector(state => state.fetch_dns?.fetch_dns_record?.logoUrl)
  console.log('logo url in app page is here', logoUrl)

  useEffect(() => {
    console.log("My hostname is :- ", window.location.host)
    console.log("My protocol name is :- ", window.location.protocol)

    // dispatch(fetch_DNS({ domain: "react-app.edbucket.com" }))
    const domain_name = window.location.protocol + window.location.host
    dispatch(fetch_DNS({ domain: window.location.host }))

    dispatch(getUploadLogo('logo', logoUrl))

  }, [])




  return (
    <>
      {/* <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js"> */}

      <BrowserRouter>
        <Loader />
        <Routes>
          <Route path='/' element={<Navigate to="/students" />} />
          <Route path='/students' element={<StudentHomePage />} />
          {/* <Route path='/recruiters' element={<AgentHomePage />} /> */}
          <Route path='/counsellors' element={<AgentHomePage />} />

          {/* <Route path='/councellors' element={<CounsellorHomePage />} /> */}
          <Route path='/institutions' element={<CounsellorHomePage />} />

          {/* <Route path='/about-us' element={<HomePage />} /> */}
          <Route path='/dashboard' element={<Layout children={<Dashboard />} />} />
          <Route path='/student-list' element={<Layout children={<StudentList />} />} />
          <Route path='/about-us' element={<AboutUs />} />
          <Route path='/privacy' element={<Privacy />} />
          <Route path='/agents' element={<Layout children={<Agents />} />} />
          <Route path='/agents/view-agent' element={<Layout children={<ViewAgentDetailsCounsellor />} />} />
          <Route path='/applications' element={<Layout children={<Applications />} />} />
          <Route path='/blogs' element={<Blogs />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/courses' element={<Layout children={<Courses />} />} />
          <Route path='/edpay' element={<Edpay />} />
          {/* <Route path='/faq' element={<Faq />} /> */}
          {/* <Route path='/counsellors' element={<Counsellors />} /> */}
          <Route path='/learn' element={<Learn />} />
          <Route path='/prepare' element={<Prepare />} />
          <Route path='/profile' element={<Layout children={<Profile />} />} />
          <Route path='/branding' element={<Layout children={<Settings />} />} />
          <Route path='/universities' element={<Layout children={<Universities />} />} />
          <Route path='/create-application' element={<Layout children={<CreateApplications />} />} />
          {/* <Route path='/create-application/personal-details' element={<PersonalDetailsForm />} />
          <Route path='/create-application/address-details' element={<AddressDetailsForm />} />
          <Route path='/create-application/references-details' element={<ReferenceDetailsForm />} />
          <Route path='/create-application/employment-details' element={<EmploymentDetailsForm />} />
          <Route path='/create-application/documents-details' element={<DocumentsDetailsForm />} /> */}
          {/* CourseOfferLetter */}
          <Route path='/apiCall' element={<ApiCall />} />
          <Route path='/view-application' element={<Layout children={<ViewApplicationDetailsForm />} />} />
          <Route path='/student-list/student-profile' element={<Layout children={<StudentProfile />} />} />
          <Route path='/applications/new-application' element={<Layout children={<AgentCreateApplication />} />} />
          <Route path='/student-list/add-student' element={<Layout children={<AgentAddStudent />} />} />
          <Route path='/student-list/view-student' element={<Layout children={<ViewStudentProfileCounsellor />} />} />
          <Route path='/applications/view' element={<Layout children={<ViewAgentApplicationDetails />} />} />
          <Route path='/applications/view-student-application' element={<Layout children={<ViewApplicationDetailsCounsellor />} />} />
          <Route path='/courseOfferLetter' element={<Layout children={<CourseOfferLetter />} />} />
          <Route path='/chatbot' element={<Layout children={<ChatBot />} />} />





        </Routes>
      </BrowserRouter>
      {/* </Worker> */}
      {/* <HomePage /> */}

      <NewsletterModal isOpen={isModalOpen} closeModal={closeModal} />
    </>
  )
}

export default App