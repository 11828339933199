import React, { useEffect, useState } from 'react';
import StudentListAgent from '../../components/StudentListAgent';
import StudentListCounsellor from '../../components/StudentListCounsellor';
import { useSelector } from 'react-redux';

function StudentList() {
    const userRole = useSelector((state) => (state?.config?.profile_id))




    return (
        <>
            {userRole === "agent" && (
                <StudentListAgent />
            )}
            {userRole === "counsellor" && (
                <StudentListCounsellor />
            )}
        </>

    )
}

export default StudentList