import axios from "axios";
// import { applicationDetailsStart, applicationDetailsSuccess } from "../../actions/applications/GetApplicationDetails";
import { getAgentStudentDetailsStart, getAgentStudentDetailsSuccess } from "../../actions/agents/GetAgentStudentDetails";
import { closeLoader, openLoader } from "../../actions/config";
import apiUrl from "../../ApiUrl";

export const agentStudentDetailsId = (id) => async (dispatch, getState) => {
    // alert(id)
    const baseUrl = getState().config.baseUrl
    dispatch(getAgentStudentDetailsStart());
    dispatch(openLoader());
    try {
        const response = await axios.get(

            `${apiUrl}/api/student/details/${id}`
        );
        if (response) {
            // alert("Getting profile details informations");
        }
        dispatch(getAgentStudentDetailsSuccess(response.data))
    } catch (error) {
        console.log(error)
    }
    finally {
        dispatch(closeLoader())
    }
};



