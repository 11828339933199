import React from 'react'

function Footer() {
    return (


        <footer className='bg-transparent'>
            <div className="row align-items-center justify-content-center clearfix ">
                <div className="col-12">
                    <ul className="flinks align-items-center justify-content-center clearfix">
                        <li>
                            <a href="#">About Us</a>
                        </li>
                        <li>
                            <a href="#">Blogs</a>
                        </li>
                        <li>
                            <a href="#">FAQs</a>
                        </li>
                    </ul>
                    <p className="copyright">copyright © 2022-23 Edbucket Pvt Ltd.</p>
                </div>
            </div>
        </footer>



    )
}

export default Footer