import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import PersonalDetailsForm from '../Structures/PersonalDetailsForm';
import AddressDetailsForm from '../Structures/AddressDetailsForm';
import { useSelector } from 'react-redux';
import { PiStudentLight } from 'react-icons/pi';
import { IoHomeOutline } from 'react-icons/io5';

function ProfileDetails() {
    const [activeTab, setActiveTab] = useState('personal');
    const personalData = useSelector((state) => state?.studentsDetails?.data)
    const [personalDetails, setPersonalDetails] = useState({})

    console.log("Personal data for profile by session", personalData)

    const handleTabClick = (tabId) => {
        setActiveTab(tabId);
    }
    return (
        <div className="row clearfix">
            <div className="col-12">
                <div className="form-tab-container">
                    <ul className="nav nav-pills form-nav" id="form-tab" role="tablist" >
                        <li className="nav-item">
                            <Link disabled={true} className={`nav-link ${activeTab === 'personal' ? 'active' : ''}`}
                                id="pills-personal-tab" data-bs-toggle="tab" data-bs-target="#pills-personal" aria-controls="pills-personal"
                                aria-selected={activeTab === 'personal'}
                                title='Personal Details'
                            // onClick={() => handleTabClick('personal')}
                            >
                                <PiStudentLight />
                                <div>
                                    <span className="steps">Step 1/2</span>{" "}
                                    Personal
                                </div>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link disabled={true} className={`nav-link ${activeTab === 'address' ? 'active' : ''}`}
                                id="pills-address-tab" data-bs-toggle="tab" data-bs-target="#pills-address" aria-controls="pills-address"
                                aria-selected={activeTab === 'address'}
                                // onClick={() => handleTabClick('address')}
                                title='Address Details'
                            >
                                <IoHomeOutline />
                                <div>
                                    <span className="steps">Step 2/2</span>{" "}
                                    Address
                                </div>
                            </Link>
                        </li>
                    </ul>
                </div>
                <div className="tab-content" id="pills-tabContent">
                    {activeTab === 'personal' && <PersonalDetailsForm setActiveTab={setActiveTab} />}
                    {activeTab === 'address' && <AddressDetailsForm setActiveTab={setActiveTab} />}
                </div>
            </div>
        </div>
    )
}

export default ProfileDetails