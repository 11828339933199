import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { loginSlice } from "../loginAPI";

const initialState = {
    isVerify: false,
    otpMessage: "",
    error: false,
    reply: ""
}

export const verifyOTPSlice = createSlice({
    name: 'verifyOTP',
    initialState,
    reducers: {
        verifyOTPStart: (state) => {
            return { ...state, otpMessage: "Verifying otp started ...", error: false };
        },
        verifyOTPSuccess: (state, action) => {
            return { ...state, otpMessage: "Verifying success", error: false, isVerify: true, reply: action.payload };
        }

    }
})

export const { verifyOTPStart, verifyOTPSuccess } = verifyOTPSlice.actions;

export default verifyOTPSlice.reducer;