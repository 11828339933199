import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { LiaGreaterThanSolid } from 'react-icons/lia';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getAgentsApplications } from '../../@redux/dispatch/applications/GetApplicationsReducer';
import Skeleton from 'react-loading-skeleton';
import { getReleasedLetter } from '../../@redux/dispatch/Generate_offer_letter_dispatch/getReleasedOfferLetter';
import { InlineWidget } from 'react-calendly';


function LoaderSkeleton() {
    return (
        <Skeleton count={10} className='py-3 my-1' />
    )
}

function NoDataFound() {
    return (
        <div className='title'>
            <h6 className='text-primary'>Oops! It seems like no applications are submitted 😔. Apply for new courses.!</h6>
        </div>
    )
}

function ApplicationList({ searchData }) {
    const data = useSelector((state) => (state?.applicationList?.agentApplicationsList?.data))
    const dispatch = useDispatch();
    const handleViewBtn = (e, application_id) => {
        e.preventDefault()
        dispatch(getReleasedLetter({ application_id: application_id }))
    }
    return (
        <div>
            <div className="studentList overflow-auto desktop " style={{ maxHeight: '60vh' }} >
                <table
                    cellSpacing={16}
                    cellPadding={16}
                    className="col-md-12 student-table table-borderless table-condensed cf courseScroll">
                    <thead className="text-nowrap">
                        <tr>
                            <th>Student Name</th>
                            <th>Course Name</th>
                            <th>Status</th>
                            <th>Offer Letter</th>
                        </tr>
                    </thead>
                    <tbody >
                        {data?.filter((student) => {
                            return searchData.toLowerCase() === '' ?
                                student : student?.Contact_Name?.name?.toLowerCase().includes(searchData);
                        })?.map((student) => (
                            <tr key={student.id} >
                                <td data-title="Course">
                                    <div className="courseDetails text-nowrap">
                                        <img src="/images/profile-pic.png" alt="Image goes here" style={{ width: '40px', height: '30px' }} className="" />
                                        {student.Contact_Name?.name}
                                    </div>
                                </td>
                                <td data-title="Course">
                                    <div className="courseDetails text-nowrap">

                                        {student?.Select_Program?.name}
                                        <span>{student.University_Name}</span>
                                    </div>
                                </td>

                                <td data-title="Status">
                                    <div className="courseDetails">
                                        {student.Stage}
                                    </div>
                                </td>
                                <td data-title="OfferLetter">
                                    <button
                                        className={`btn btn-purple ${student.Offer_Letter_Student === null ? 'disabled' : ''}`}
                                        disabled={student.Offer_Letter_Student === null}
                                        onClick={(e) => handleViewBtn(e, student.id)}
                                    >
                                        View
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <div className="studentList overflow-auto mobile tablet" style={{ maxHeight: '60vh' }} >
                <table
                    cellSpacing={16}
                    cellPadding={16}
                    className="col-md-12 student-table table-borderless table-condensed cf courseScroll">
                    <thead className="text-nowrap">
                        <tr>
                            <th>Student Name</th>
                            <th>Course Name</th>
                            <th>Status</th>
                            <th>Offer Letter</th>
                        </tr>
                    </thead>
                    <tbody >
                        {data?.map((student) => (
                            <tr key={student.id} >
                                <td data-title="Course">
                                    <div className="courseDetails ">
                                        <img src="/images/profile-pic.png" alt="Image goes here" style={{ width: '40px', height: '30px' }} className="" />
                                        {student.Contact_Name?.name}
                                    </div>
                                </td>
                                <td data-title="Course">
                                    <div className="courseDetails ">

                                        {student?.Select_Program?.name}
                                        <span>{student.University_Name}</span>
                                    </div>
                                </td>

                                <td data-title="Status">
                                    <div className="courseDetails">
                                        {student.Stage}
                                    </div>
                                </td>
                                <td data-title="OfferLetter">
                                    <button
                                        className={`btn btn-purple ${student.Offer_Letter_Student === null ? 'disabled' : ''}`}
                                        disabled={student.Offer_Letter_Student === null}
                                        onClick={(e) => handleViewBtn(e, student.id)}
                                    >
                                        View
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

function DashboardAgent() {
    const userName = useSelector((state) => (state.config?.userData?.Vendor_Name))
    const dispatch = useDispatch();
    const data = useSelector((state) => (state?.applicationList?.agentApplicationsList?.data))
    const noList = useSelector((state) => (state?.applicationList?.agentApplicationsList))
    const loading = useSelector((state) => (state?.applicationList?.loading))
    const [startDate, setStartDate] = useState(new Date());
    const [search, setSearch] = useState('')

    useEffect(() => {
        dispatch(getAgentsApplications());
    }, []);
    return (
        <div className="dashboard-right px-3">
            <div className="desktop  ">
                <div className="row top-header sticky-top clearfix bg-transparent">
                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-5 col-xxl-5">
                        <h2>Hello,{userName}</h2>
                    </div>

                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-7 col-xxl-7">
                        <div className="search-bar">
                            <input
                                type="text"
                                name="search"
                                id="search"
                                className="search-area"
                                placeholder="Search by student name"
                                onChange={(e) => setSearch(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                        <p className="subtext desktop">Welcome back!</p>
                    </div>
                </div>
            </div>
            {/* {data !== undefined ?
                data.map((student) => ( */}
            <div className="mobile tablet" >
                <div className="row clearfix">
                    <div className="col-12">

                        <h2>Hello,{userName} </h2>
                        <p className="subtext my-2">Welcome back!</p>

                    </div>
                </div>
            </div>
            {/* )) : null} */}
            <div className="row clearfix">
                <div className="col-12 col-lg-7 col-xl-8 col-xxl-8 p-0">
                    <div className="your-application-box">
                        <h3 className="title">
                            Your Applications {data?.length} results

                            <div className="circle-link desktop bg-color-200">
                                <Link to="#">
                                    <LiaGreaterThanSolid className='color-950' />
                                </Link>
                            </div>
                        </h3>
                        {loading && <LoaderSkeleton />}
                        {/* {(!loading && noList?.length === undefined) && <NoDataFound />} */}
                        {
                            (!loading && data?.length > 0) ?
                                <ApplicationList searchData={search} />
                                :
                                <NoDataFound />
                        }
                        {/* {(!loading && data?.length > 0) && <ApplicationList searchData={search} />} */}
                    </div>
                </div>
                <div className="col-12 col-lg-5 col-xl-4 col-xxl-4">
                    <div className="box-white clearfix">
                        <h4 className="title">
                            Upcoming Events
                            <div className="circle-link desktop bg-color-200">
                                <Link to="#">
                                    <LiaGreaterThanSolid className='color-950' />
                                </Link>
                            </div>

                        </h4>
                        {/* <div className="calendar" /> */}
                        {/* <Calendar onChange={setStartDate} value={startDate} />
                        <hr />
                        <h4 className="title">
                            Activity
                            <div className="circle-link desktop bg-color-200">
                                <Link to="#">
                                    <LiaGreaterThanSolid className='color-950' />
                                </Link>
                            </div>
                        </h4>
                        <ul className="activity-box">
                            <li>
                                <img src="images/girl-avatar.png" alt="Image goes here" />
                                <div className="text-area">
                                    <h5>Profile updated</h5>
                                    <p>Apurva updated her profile</p>
                                </div>
                            </li>
                            <li>
                                <img src="images/girl-avatar.png" alt="Image goes here" />
                                <div className="text-area">
                                    <h5>Application Submitted</h5>
                                    <p>Apurva submitted a new Application</p>
                                </div>
                            </li>
                            <li>
                                <img src="images/profile-pic.png" alt="Image goes here" />
                                <div className="text-area">
                                    <h5>Application Accepted</h5>
                                    <p>Supporting descriptive body text goes here</p>
                                </div>
                            </li>
                            <li>
                                <img src="images/profile-pic.png" alt="Image goes here" />
                                <div className="text-area">
                                    <h5>Application Accepted</h5>
                                    <p>Supporting descriptive body text goes here</p>
                                </div>
                            </li>
                        </ul>

                        <Link to="#" className="text-center mobile mt-4">
                            See all
                        </Link> */}
                        {/* <div className='d-flex justify-content-center'>
                            <img src='images/dashboard_activity.png' width={300} height={400} />
                        </div>

                        <h4 className='title pb-4 pb-lg-0'>
                            Coming soon...
                        </h4> */}

                        <div>
                            {/* <InlineWidget url="https://calendly.com/anamika-stylopay" /> */}

                            <InlineWidget url="https://calendly.com/anamika-stylopay/queries-regarding-applications" />

                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default DashboardAgent