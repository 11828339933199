import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { updateAgentStudentDetails } from '../../../@redux/dispatch/agents/UpdateAgentStudentDetailsReducer';
import Loader from '../../Loader';
import AgentStudentUploadDoc from './AgentStudentUploadDoc';

function AgentPersonalDetails({ setActiveTab }) {
    const location = useLocation();
    const dispatch = useDispatch();
    const queryParams = new URLSearchParams(location.search)
    const profile_id = queryParams.get('profile_id');
    const studentPersonalData = useSelector((state) => state?.agentStudentDetails?.studentDetails?.data?.[0])
    const [personalFormData, setPersonalFormData] = useState({})

    useEffect(() => {
        console.log("agent's student related information", studentPersonalData)
        setPersonalFormData(studentPersonalData)
    }, [studentPersonalData])

    const handleInputChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        setPersonalFormData({
            ...personalFormData,
            [name]: value
        })
    }

    const handleUpdate = (event) => {
        event.preventDefault();
        dispatch(updateAgentStudentDetails({ id: profile_id, formData: personalFormData }))
    }


    return (
        <>
            {/* <Loader /> */}
            <div className="tab-pane fade show active"
                id="pills-personal"
                role="tabpanel"
                aria-labelledby="pills-personal-tab">
                <form
                    onSubmit={handleUpdate}
                >
                    {/* Removed temporary

                  <AgentStudentUploadDoc />
                    <div className="form-separator" />
                 */}

                    <div className="form-area">
                        <div className="form-head">
                            Your Personal Details{" "}
                            <span>We will use your personal details while processing your applications</span>
                        </div>
                        <div className="form-subhead">

                        </div>
                        <div className="row clearfix">
                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                <label className='form-check-label mb-2'>First name</label>
                                <input
                                    type="text"
                                    id='firstName'
                                    name="firstName"
                                    className="form-control"
                                    placeholder="First name"
                                    value={personalFormData?.First_Name}
                                    onChange={handleInputChange}
                                // value={personalData?.First_Name}
                                // disabled
                                />
                            </div>
                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                <label className='form-check-label mb-2'>Last name</label>
                                <input
                                    type="text"
                                    id='lastName'
                                    name="lastName"
                                    className="form-control"
                                    placeholder="Last name"
                                    value={personalFormData?.Last_Name}
                                    onChange={handleInputChange}
                                // value={personalData?.Last_Name}
                                // disabled
                                />
                            </div>
                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                <label className='form-check-label mb-2' htmlFor='dateBirth'>Date of birth</label>
                                {/* <CustomDatePicker onChange={(val) => alert(val)} value={dayjs(personalDetails?.Date_of_Birth)} className="w-100 form-control" name="dateBirth" id="dateBirth" placeholder="date of birth" required /> */}
                                {/* <CustomDatePicker onChange={(val) => setPersonalDetails({ ...personalDetails, Date_of_Birth: dayjs.utc(val).tz('Asia/Kolkata') })} value={dayjs(personalDetails?.Date_of_Birth)} className="w-100 form-control" name="dateBirth" id="dateBirth" placeholder="date of birth" required /> */}
                                <input
                                    type="date"
                                    id='Date_of_Birth'
                                    name="Date_of_Birth"
                                    className="form-control"
                                    placeholder="Date_of_Birth"
                                    value={personalFormData?.Date_of_Birth}
                                    onChange={handleInputChange}
                                // value={personalDetails?.Date_of_Birth}
                                // onChange={handleInputChange}
                                />
                            </div>
                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                <label className='form-check-label mb-2'>Nationality</label>
                                <input
                                    type="text"
                                    id='Nationality'
                                    name="Nationality"
                                    className="form-control"
                                    placeholder="Nationality"
                                    value={personalFormData?.Nationality}
                                    onChange={handleInputChange}
                                // value={personalDetails?.Nationality}
                                // onChange={handleInputChange}
                                />
                            </div>

                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                <label className='form-check-label mb-2'>Email</label>
                                <input
                                    type="email"
                                    id='email'
                                    name="email"
                                    className="form-control"
                                    placeholder="Enter your email"
                                    value={personalFormData?.Email}
                                    onChange={handleInputChange}
                                // value={personalData?.Email}
                                // disabled
                                />
                            </div>

                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                <label className='form-check-label mb-2'>Mobile number</label>
                                <div className="field-phone">
                                    <span className="country-code">
                                        <img
                                            src="/images/icons/indian-flag.svg"
                                            className="img-fluid mCS_img_loaded"
                                            alt="indian flag goes here"
                                        />
                                        <p className='m-auto'>{personalFormData?.Phone?.slice(0, (personalFormData?.Phone?.length || 0) - 10)}</p>

                                        {/* <select
                                            name="ccode"
                                            id="ccode"
                                            defaultValue={personalFormData?.Phone?.slice(0, (personalFormData?.Phone?.length || 0) - 10)}
                                        >
                                            <option selected
                                                value={personalFormData?.Phone?.slice(0, (personalFormData?.Phone?.length || 0) - 10)}
                                            >
                                                {personalFormData?.Phone?.slice(0, (personalFormData?.Phone?.length || 0) - 10)}
                                            </option>
                                        </select> */}
                                    </span>
                                    <input
                                        type="tel"
                                        name="phone"
                                        id="phone"
                                        className="form-control"
                                        placeholder="Mobile Number"
                                        // value={personalData?.Phone?.slice((personalData?.Phone?.length || 0) - 10)}
                                        // disabled
                                        value={personalFormData?.Phone?.slice((personalFormData?.Phone?.length || 0) - 10)}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='container mt-5'>
                            <div className='row justify-content-end clearfix'>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 text-end p-4 form-bottom" >
                                    <button className="btn btn-theme me-1" onClick={() => { setActiveTab("address") }}>Next</button>
                                    <button className="btn btn-theme" type='submit' >Update profile</button>
                                </div>
                            </div>
                        </div>


                    </div>

                </form>

            </div>
        </>


    )
}

export default AgentPersonalDetails