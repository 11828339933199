import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isUpload: false,
    uploadMessage: "",
    error: false,
    Please_upload_photo_identification_of_yourself: "",
    Academic_Transcript: ""
}

export const uploadFilesSlice = createSlice({
    name: 'uploadFiles',
    initialState,
    reducers: {
        uploadFilesStart: (state) => {
            return { ...state, uploadMessage: "uploading files started ...", error: false, Please_upload_photo_identification_of_yourself: "", Academic_Transcript: "" };
        },
        uploadFilesSuccess: (state, action) => {
            // const { type, fileId } = action.payload;
            const updatedState = { ...state, uploadMessage: "uploading files success", error: false, isUpload: true, [action.payload.type]: action.payload.fileId };
            console.log('Updated states inside actions', updatedState);
            return updatedState;
        }

    }
})

export const { uploadFilesStart, uploadFilesSuccess } = uploadFilesSlice.actions;

export default uploadFilesSlice.reducer;