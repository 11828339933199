import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Header from '../../components/Header'
import LeftSidebar from '../../components/LeftSidebar'
import { Link } from 'react-router-dom'
import Footer from '../../components/Footer'
import ToastContainer from '../../components/ToastContainer'
// import Filters from '../components/Filters'
import CourseDetails from '../../course.json'
// import { SlArrowDown } from "react-icons/sl";
import UnivLeftSidebar from '../../components/UnivLeftSidebar'
import MobMenuHeader from '../../components/MobMenuHeader'
import Sort from '../../components/Structures/SortBy/Sort'
import Filters from '../../components/Structures/Filters/Filters'
import ExampleModal from '../../components/Modals/ExampleModal'
import ExampleModalButton from '../../components/Modals/ExampleModalButton'
import { LuArrowUpDown } from "react-icons/lu";
import { FaRegShareFromSquare } from "react-icons/fa6";
import { AiOutlineHeart } from "react-icons/ai";
import CourseListStudent from '../../components/CourseListStudent'
import CourseListAgent from '../../components/CourseListAgent'
import CourseListCounsellor from '../../components/CourseListCounsellor'
import Breadcrump from '../../components/Structures/Breadcrump/Breadcrump'
import Loader from '../../components/Loader'
import { useDispatch, useSelector } from 'react-redux';
import AddCoursesModal from '../../components/Modals/AddCoursesModal'
import { getUniversity } from '../../@redux/dispatch/GetUniversitiesReducer'
import { getUniversityList } from '../../@redux/dispatch/university'
import CourseFilter from '../../components/Structures/Filters/CourseFilter'

function Courses() {
    const [showContent, setShowContent] = useState(false);
    const [search, setSearch] = useState('')
    const [isModalVisible, setModalVisible] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const dispatch = useDispatch()
    const count = useSelector((state) => (state?.courseList?.courses?.data?.length))
    const countC = useSelector((state) => (state?.courseList?.counsellorCourses?.data?.length))
    const userRole = useSelector((state) => (state?.config?.profile_id))
    const [details, setdetails] = useState([]);
    const breadcrumps = [
        { text: 'Home', path: '/dashboard' },
        { text: 'All Courses', path: '/courses' }
    ]

    console.log('searched values ', search)

    const openModal = (e) => {
        e.preventDefault();
        dispatch(getUniversity())
        setIsModalOpen(true);
    };
    const closeModal = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        if (userRole === 'counsellor')
            dispatch(getUniversityList())
    }, [])



    return (
        <div>
            <div className="col-md-9 ms-sm-auto col-lg-12 col-xl-10 col-xxl-10">
                <div className="course-grid">
                    <div className="desktop">
                        <div className="fixed-header sticky-top w-100">
                            <Breadcrump items={breadcrumps} />
                            {/* <Breadcrumb data={{ main: "Home", subdivision: "All Courses", backurl: "/dashboard" }} /> */}
                            <div className="row clearfix">
                                <div className="col-12 col-lg-12 col-xl-2 ">
                                    <h5 className="course-title-filter">Courses</h5>
                                    {(userRole === 'students' || userRole === 'agents') ?
                                        count > 0 && <div className="filter-results-filter">{count} Results</div>
                                        :
                                        countC > 0 && <div className="filter-results-filter">{countC} Results</div>
                                    }

                                </div>
                                <div className="col-12 col-lg-12 col-xl-10 col-xxl-10">
                                    <div className="row align-items-center clearfix">
                                        <div className="col-6 text-center">

                                            {/* <button>Add Courses</button> */}
                                            {userRole === "counsellor" && (
                                                <>
                                                    <button
                                                        className="btn btn-purple"

                                                        onClick={openModal}
                                                    >
                                                        Add Courses
                                                    </button>
                                                    <AddCoursesModal isOpen={isModalOpen} closeModal={closeModal} />
                                                </>
                                            )}

                                        </div>
                                        <div className="col-6">
                                            <div className="search-bar">
                                                <input type="text" name="search" id="search" onChange={(e) => setSearch(e.target.value)}
                                                    className="search-area" placeholder="Search by course name " />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tablet mobile">
                        <div className="row align-items-center clearfix">
                            <div className="col-8">
                                <h5 className="course-title-mobile">Courses</h5>
                            </div>
                            <div className="col-4 text-end">
                                <div className="filter-results">25 Results</div>
                            </div>
                        </div>
                    </div>
                    <div className="row gx-2 clearfix">
                        <CourseFilter userRole={userRole} />
                        <div className="col-12 col-lg-12 col-xl-10 col-xxl-10">
                            {userRole === "student" && (
                                <CourseListStudent searchData={search} />
                            )}
                            {userRole === "agent" && (
                                <CourseListAgent searchData={search} />
                            )}
                            {userRole === "counsellor" && (
                                <CourseListCounsellor searchData={search} />
                            )}

                        </div>
                    </div>
                </div>
                <ul className="filter-box-sm">
                    <li>
                        <div className="sort-trigger">
                            <LuArrowUpDown /> Sort
                        </div>
                    </li>
                    <li>
                        <div id="filter" >
                            <svg width={15} height={18} viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <ellipse cx="10.8334" cy="4.83333" rx="3.33333" ry="3.33333" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <ellipse cx="4.16674" cy="13.1667" rx="3.33333" ry="3.33333" transform="rotate(180 4.16674 13.1667)" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M0.833328 4.83325L7.5 4.83325" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M14.1668 13.1667L7.50016 13.1667" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            Filters
                        </div>
                    </li>
                </ul>
                <ul
                    className="filter-box-sm filter-apply"
                    style={{ display: "none" }}
                >
                    <li>
                        <input
                            type="reset"
                            name="reset"
                            id="reset"
                            defaultValue="Clear All"
                        />
                    </li>
                    <li>
                        <input
                            type="submit"
                            name="submit"
                            id="submit"
                            defaultValue="Apply"
                        />
                    </li>
                </ul>
                <div className="sort-filter-sm close">
                    <div className="d-flex justify-content-space-between">
                        <h6>Sort By</h6>
                        {/* <Link to="javascript:void(0)" className="close"> */}
                        <Link to="#" className="close">
                            <i className="bi bi-times" />
                        </Link>
                    </div>
                    <ul className="sort-options">
                        <li>Relevance</li>
                        <li>Popular Score</li>
                        <li>Ielts Score</li>
                    </ul>
                </div>
                <ul className="bottom-menu">
                    <li>
                        <Link to="/dashboard">
                            <i className="bi bi-house-fill" />
                            Home
                        </Link>
                    </li>
                    <li>
                        <Link to="#" id="search-icon">
                            <i className="bi bi-search" />
                            Search
                        </Link>
                    </li>
                    <li>
                        <Link to="/profile">
                            <i className="bi bi-person-fill" />
                            Profile
                        </Link>
                    </li>
                    <li>
                        <Link
                            data-bs-toggle="offcanvas"
                            to="#mobileMenu"
                            role="button"
                            aria-controls="mobileMenuExample"
                        >
                            <i className="bi bi-list" />
                            Menu
                        </Link>
                    </li>
                </ul>

                <div id="search-menu">
                    <div className="wrapper">
                        <div className="input-group">
                            <input
                                type="text"
                                name="search"
                                id="popup-search"
                                className="form-control"
                                placeholder="Search here"
                            />
                            <button
                                type="submit"
                                className="btn btn-purple"
                                id="popup-search-button"
                            >
                                <i className="bi bi-search" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
            {/* Modal */}
            <div
                className="modal fade"
                id="mobileFilters"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex={-1}
                aria-labelledby="staticBackdropLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="staticBackdropLabel">
                                &nbsp;
                            </h1>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            />
                        </div>
                        <div className="modal-body">
                            <div className="row mb-3 clearfix">
                                <label htmlFor="sorting" className="col-4 col-sm-5 col-form-label">
                                    Sort by
                                </label>
                                <div className="col-8 col-sm-7">
                                    <select className="form-select" name="sorting" id="sorting">
                                        <option value="relevance">Relevance</option>
                                    </select>
                                </div>
                            </div>
                            <h6 className="filter-title">Destination</h6>
                            <div className="checkboxes">
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        defaultValue=""
                                        name="destination"
                                        id="unitedkingdom"
                                    />
                                    <label className="form-check-label" htmlFor="unitedkingdom">
                                        United Kingdom
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        defaultValue=""
                                        name="destination"
                                        id="australia"
                                    />
                                    <label className="form-check-label" htmlFor="australia">
                                        Australia
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        defaultValue=""
                                        name="destination"
                                        id="usa"
                                    />
                                    <label className="form-check-label" htmlFor="usa">
                                        USA
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        defaultValue=""
                                        name="destination"
                                        id="canada"
                                    />
                                    <label className="form-check-label" htmlFor="canada">
                                        Canada
                                    </label>
                                </div>
                            </div>
                            <h6 className="filter-title">Subjects</h6>
                            <div className="checkboxes">
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        defaultValue=""
                                        name="subjects"
                                        id="machinelearning"
                                    />
                                    <label className="form-check-label" htmlFor="machinelearning">
                                        Machine Learning
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        defaultValue=""
                                        name="subjects"
                                        id="health"
                                    />
                                    <label className="form-check-label" htmlFor="health">
                                        Health
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        defaultValue=""
                                        name="subjects"
                                        id="biotech"
                                    />
                                    <label className="form-check-label" htmlFor="biotech">
                                        Biotech
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        defaultValue=""
                                        name="subjects"
                                        id="chemistry"
                                    />
                                    <label className="form-check-label" htmlFor="chemistry">
                                        Chemistry
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        defaultValue=""
                                        name="subjects"
                                        id="physics"
                                    />
                                    <label className="form-check-label" htmlFor="physics">
                                        Physics
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        defaultValue=""
                                        name="subjects"
                                        id="computerscience"
                                    />
                                    <label className="form-check-label" htmlFor="computerscience">
                                        Computer Science
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        defaultValue=""
                                        name="subjects"
                                        id="maths"
                                    />
                                    <label className="form-check-label" htmlFor="maths">
                                        Maths
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        defaultValue=""
                                        name="subjects"
                                        id="english"
                                    />
                                    <label className="form-check-label" htmlFor="english">
                                        English
                                    </label>
                                </div>
                            </div>
                            <h6 className="filter-title">Course Level</h6>
                            <div className="checkboxes">
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        defaultValue=""
                                        name="courselevel"
                                        id="undergraduate"
                                    />
                                    <label className="form-check-label" htmlFor="undergraduate">
                                        Undergraduate
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        defaultValue=""
                                        name="courselevel"
                                        id="postgraduate"
                                    />
                                    <label className="form-check-label" htmlFor="postgraduate">
                                        Postgraduate
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-bs-dismiss="modal"
                            >
                                Close
                            </button>
                            <button type="button" className="btn btn-primary">
                                Understood
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>






    )
}

export default Courses