import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import apiUrl from "../ApiUrl";

const initialState = {
    isLogin: false,
    username: "",
    loginMessage: "",
    error: false
}

export const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        loginStart: (state) => {
            return { ...state, loginMessage: "Logging in...", error: false };
        },
        loginSuccess: (state, action) => {
            return { ...state, loginMessage: "Logging in success", error: false, isLogin: true, username: action.payload };
        }
    }
})

export const { loginStart, loginSuccess } = loginSlice.actions;


export const login = (body) => async (dispatch, getState) => {
    const baseUrl = getState().config.baseUrl
    dispatch(loginStart())
    try {
        const response = await axios.post(

            `${apiUrl}/api/login`,
            body
        );
        dispatch(loginSuccess(response.data?.data[0]?.Full_Name))
        console.log(response.data)
    } catch (error) {
        console.log(error)
    }
};

export default loginSlice.reducer;