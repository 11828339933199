import React from 'react'
import MobHomeHeader from '../../components/MobHomeHeader'
import HomeNavbar from '../../components/HomeNavbar'
import HomeMainSection from '../../components/HomeMainSection'
import HomeFooter from '../../components/HomeFooter'
import VerifyModal from '../../components/Modals/VerifyModal'
import TestimonialModal from '../../components/Modals/TestimonialModal'
import AboutUsMainSection from '../../components/AboutUsMainSection'



function AboutUs() {
    return (
        <div>
            <MobHomeHeader />
            <HomeNavbar />
            <AboutUsMainSection />
            <HomeFooter />
        </div>
    )
}

export default AboutUs