import { createSlice } from "@reduxjs/toolkit";
import { act } from "react-dom/test-utils";

const initialState = {
    generate_ssl_record: {},
    error: false,
    insert_data_record: {},
    get_data: {},
    get_status: {},
    update_congnito: {},
    attach_alb: {},
    get_address: {},
    logoUrlRes: {},
    // datas: {
    //     brandingName: "Edbucket",
    //     domainName: "",
    //     logo: "",
    //     hueVal: ""
    // }
}

export const customisationSlice = createSlice({
    name: 'customisation',
    initialState,
    reducers: {
        generate_ssl_success: (state, action) => {
            return { ...state, generate_ssl_record: action.payload };
        },
        insert_data: (state, action) => {
            return { ...state, insert_data_record: action.payload };
        },
        get_data: (state, action) => {
            return { ...state, get_data: action.payload };
        },
        get_status: (state, action) => {
            return { ...state, get_status: action.payload };
        },
        update_congnito: (state, action) => {

        },
        attach_alb: (state, action) => {
            return { ...state, attach_alb: action.payload }
        },
        get_address: (state, action) => {
            return { ...state, get_address: action.payload }
        },
        get_logo_url_res: (state, action) => {
            console.log("value that will go inside logoUrlRes in here in customisation action", action.payload);
            return { ...state, logoUrlRes: { ...state.logoUrlRes, ...action.payload } }
        },
        reset_customisation_data: (state) => {
            return { ...state, generate_ssl_record: {}, insert_data_record: {}, get_data: {}, get_status: {}, attach_alb: {}, get_address: {} }
        },


    }
})

export const { generate_ssl_success, insert_data, get_data, get_status, reset_customisation_data, attach_alb, get_address, get_logo_url_res } = customisationSlice.actions;

export default customisationSlice.reducer;
