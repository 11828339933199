import axios from "axios";
import { getChatNotifyStart, getChatNotifySuccess } from "../../actions/chatNotify";
import apiUrl from "../../ApiUrl";
import { closeLoader, openLoader } from "../../actions/config";
import toastr from "toastr";


export const postChatNotify = (body, Id) => async (dispatch, getState) => {
    dispatch(openLoader())
    dispatch(getChatNotifyStart());
    const baseUrl = getState().config.baseUrl
    try {
        const response = await axios.post(`${apiUrl}/api/chat/message/${Id}`, body);
        console.log("response of post chat notify is ", response.data)
        dispatch(getChatNotifySuccess(response.data))
        toastr.success("Notes send");

    } catch (error) {
        console.log(error)
    }
    finally {
        dispatch(closeLoader())
    }
};

export const getChatNotify = (Id) => async (dispatch, getState) => {
    dispatch(openLoader())
    dispatch(getChatNotifyStart());
    try {
        const response = await axios.get(`${apiUrl}/api/chat/message/${Id}`);
        console.log("response of get chat notify is ", response.data)
        dispatch(getChatNotifySuccess(response.data))

    } catch (error) {
        console.log(error)
    }
    finally {
        dispatch(closeLoader())
    }
};