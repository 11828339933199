import React, { useState } from 'react'
import axios from "axios";
import ModalSchema from './ModalSchema';
import Select from 'react-select'
import mob_list from './../../mob_list';
import { useDispatch, useSelector } from 'react-redux';
import { register, resendOTP, verifyOTP } from '../../@redux/dispatch/config';
import apiUrl from '../../@redux/ApiUrl';

function GetStartedModal({ userProfile }) {
    console.log("registerProfile :", userProfile)
    const profile_id = useSelector((state) => (state?.config?.profile_id))
    console.log("profile_id inside register modal is :", profile_id)
    const dispatch = useDispatch();
    const [signupmessage, setSignupMessage] = useState("");
    // const [veryfiyMessage, setVeryfiyMessage] = useState("");
    const [verifyMessage, setVerifyMessage] = useState("");

    const [formData, setFormData] = useState({ email: '', password: '', fullName: '', phone: '', countryCode: '', profile: profile_id });
    const [verifyFormData, setVerifyFormData] = useState({ otp: '' });
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isVerifyModalOpen, setIsVerifyModalOpen] = useState(false);

    console.log("mob_list", mob_list)

    const takeSelect = (name, value) => {
        setFormData(prevState => ({ ...prevState, [name]: value }))
    }


    const isEmailValid = (email) => {
        // Regular expression for email validation
        const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        return emailPattern.test(email);
    };

    const isPhoneValid = (phone) => {
        // Regular expression for phone validation (10 to 12 digits)
        const phonePattern = /^[0-9]{10,12}$/;
        return phonePattern.test(phone);
    };

    const isPasswordValid = (password) => {
        // Regular expression for password validation (one uppercase, one lowercase, and @ symbol)
        const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[@]).{8,}$/;
        return passwordPattern.test(password);
    };

    const handleInputChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
            profile: profile_id,
        });
    };

    const handleCountryCodeChange = (event) => {
        event.preventDefault();
        const { value } = event.target;
        setFormData({
            ...formData,
            countryCode: value,
        });
    };

    console.log("formData", formData)

    const handleSubmit = async (event) => {
        event.preventDefault();
        const emailValid = isEmailValid(formData.email);
        const phoneValid = isPhoneValid(formData.phone);
        const passwordValid = isPasswordValid(formData.password);

        if (!formData.fullName) {
            setSignupMessage('Please enter your name.');
        } else if (!emailValid) {
            setSignupMessage('Please enter a valid email.');
        } else if (!phoneValid) {
            setSignupMessage('Phone number must be 10 to 12 digits and contain only numbers.');
        } else if (!passwordValid) {
            setSignupMessage('Password must contain one uppercase letter, one lowercase letter, and the @ symbol.');
        } else {
            dispatch(register(formData, setSignupMessage, setIsModalOpen, setIsVerifyModalOpen))
        }
    };


    const varifyHandleInputChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        setVerifyFormData({
            ...verifyFormData,
            [name]: value,
        });
    };

    const verifyhandleSubmit = async (event) => {
        event.preventDefault();
        if (!verifyFormData.otp) {
            setVerifyMessage('please enter the OTP code');
        } else {
            try {

                const response = await axios.post(`${apiUrl}/api/otp`, verifyFormData);
                console.log('API Response:', response.data);
                console.log("status", response.status);
                if (!response.data.errorCode) {
                    setIsVerifyModalOpen(false);
                    console.log("OTP Confirmed!");
                    setVerifyMessage('OTP Confirmed!');
                }
                else {
                    // Display the error message if there's an error
                    setVerifyMessage(response.data.msg.split(":")[1]);
                }
            } catch (error) {
                console.error('API Error:', error);
                setVerifyMessage('An error occurred while logging in. Please try again.');
            }

            // verifyOTP(verifyFormData, setIsVerifyModalOpen, setVerifyMessage)
        }

    };

    const handleResendOtp = (event) => {
        event.preventDefault();
        dispatch(resendOTP(setVerifyMessage))

    }

    const content = <div>
        <button
            type="button"
            className="btn-close"
            onClick={() => setIsModalOpen(false)}
        />
        <h1 className='text-center'>Get Started</h1>
        {signupmessage && (
            <div className={`alert ${signupmessage.startsWith('Signup Successful!') ? 'alert-success' : 'alert-danger'}`}>
                {signupmessage}
            </div>
        )}
        <form onSubmit={handleSubmit} method="post" id="registerform" action="">
            <div className="form-group mb-3">
                <input
                    type="text"
                    name="fullName"
                    id="fullName"
                    className="form-control"
                    placeholder="Full Name"
                    required=""
                    value={formData.fullName}
                    onChange={handleInputChange}
                />
            </div>
            <div className="form-group mb-3">
                <input
                    type="email"
                    name="email"
                    id="email"
                    className="form-control"
                    placeholder="Email ID"
                    required=""
                    value={formData.email}
                    onChange={handleInputChange}
                />
            </div>
            <div className="form-group position-relative mb-3 field-phone d-flex">

                {/* <span className="country-code" style={{ width: "30%" }}> */}
                {/* <img src="images/icons/indian-flag.svg" alt='flags' />
            <select name="countryCode" value={formData.countryCode} onChange={handleCountryCodeChange}>
              <option value={"+91"}>+91</option>
              <option value={"+93"}>+93</option>
            </select> */}

                <div className="form-control p-0 w-50 me-2 px-3 d-flex">
                    <Select placeholder="Code" className=" my-auto w-100"
                        onChange={(item) => takeSelect("countryCode", item.value)}
                        options={mob_list?.map(item => ({
                            ...item,
                            label: <div className='d-flex'>
                                <img alt='countryCode' src={`/flags/${item.flag.toLowerCase()}.svg`} width={20} />
                                <p className='d-inline-block m-0 ms-2'>{item.label}</p>
                            </div>
                        }))}

                        styles={{
                            indicatorSeparator: () => ({ display: 'none' }),
                            control: (provided) => ({
                                ...provided,
                                border: 'none',
                                boxShadow: 'none',
                                borderRadius: "8px",
                                background: "transparent"
                            }),
                            dropdownIndicator: (provided) => ({
                                ...provided,
                                // display: 'none',
                                padding: 0,
                                margin: -8
                            })
                        }} />
                </div>


                {/* </span> */}
                <input
                    type="tel"
                    name="phone"
                    id="phone"
                    className="form-control p-0 ps-3"
                    placeholder="Phone"
                    required=""
                    value={formData.phone}
                    onChange={handleInputChange}
                />
                {/* <input
            type="tel"
            name="phone"
            id="phone"
            className="form-control p-0 ps-3"
            placeholder="Phone"
            required=""
            value={formData.phone}
            onChange={handleInputChange}
          />  */}
            </div>
            <div className="form-group mb-3">
                <input
                    type="password"
                    name="password"
                    id="password"
                    className="form-control"
                    placeholder="Password"
                    required=""
                    value={formData.password}
                    onChange={handleInputChange}
                />
            </div>
            <div className='text-center '>
                <button
                    type="submit"
                    className="btn btn-gradient mt-32 w-50"
                >
                    Register
                </button>
            </div>

        </form>
        <div className="separator">
            <span>or continue with</span>
        </div>
        <ul className="social-login">
            <li>
                <a href="#" className="temp">
                    <img src="images/google.png" />
                </a>
            </li>
            <li>
                <a href="#" className="temp">
                    <img src="images/facebook.png" />
                </a>
            </li>
            <li>
                <a href="#" className="temp">
                    <img src="images/twitter.png" />
                </a>
            </li>
        </ul>
    </div>

    const verifyContent = <div>
        <button
            type="button"
            className="btn-close"
            onClick={() => setIsVerifyModalOpen(false)}

        />
        <h1 className='text-center'>Get Started</h1>
        <p>A  OTP has been sent to </p>
        {verifyMessage && (
            <div className={`alert ${verifyMessage.startsWith('OTP Confirmed!') || verifyMessage.startsWith('Successfully Received Otp') ? 'alert-success' : 'alert-danger'}`}>
                {verifyMessage}
            </div>
        )}

        <form method="post" id="verifyForm" onSubmit={verifyhandleSubmit} action="" role="form">
            <div className="form-group mb-3">
                <input
                    type="text"
                    name="otp"
                    id="otp"
                    className="form-control"
                    placeholder="OTP"
                    required=""
                    value={verifyFormData.otp}
                    onChange={varifyHandleInputChange}
                />
                <div className="row">
                    <small className="text-end px-3">
                        <a href="#" onClick={handleResendOtp}>resend otp</a>
                    </small>
                </div>
            </div>
            <div className='text-center'>
                <input
                    type="submit"
                    id="otp"
                    name="otp"
                    defaultValue="Email - OTP"
                    className="btn btn-purple mt-32 w-50"
                />
            </div>

        </form>
    </div>


    return (
        <>
            {/* <Loader /> */}
            <ModalSchema content={content} isOpen={isModalOpen} setIsOpen={setIsModalOpen} />
            <ModalSchema content={verifyContent} isOpen={isVerifyModalOpen} setIsOpen={setIsVerifyModalOpen} />
            <button
                className="btn btn-purple"
                href="#"
                onClick={() => setIsModalOpen(!isModalOpen)}
            >
                Get Started
            </button>
        </>
    )

}
export default GetStartedModal;