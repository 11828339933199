import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    // isAgents: false,
    agentStudentMessage: "",
    error: false,
    studentDetails: []
}

export const getAgentStudentDetailsSlice = createSlice({
    name: 'getAgentStudentDetails',
    initialState,
    reducers: {
        getAgentStudentDetailsStart: (state) => {
            return { ...state, agentStudentMessage: "Getting agent's student details started ...", error: false, studentDetails: [] };
        },
        getAgentStudentDetailsSuccess: (state, action) => {
            return { ...state, agentStudentMessage: "Agent's student details fetched successfully", error: false, studentDetails: action.payload };
        }

    }
})

export const { getAgentStudentDetailsStart, getAgentStudentDetailsSuccess } = getAgentStudentDetailsSlice.actions;

export default getAgentStudentDetailsSlice.reducer;