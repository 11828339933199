import React, { useRef, useState } from 'react'
import { uploadDocs, uploadDocsExt } from '../../../@redux/dispatch/uploadDocuments';
import { useDispatch } from 'react-redux';

function AgentStudentUploadDoc() {
    const [showFileUpload, setShowFileUpload] = useState(false);
    const [selectedDocumentType, setSelectedDocumentType] = useState('')
    const [selectedFile, setSelectedFile] = useState(false);
    const dispatch = useDispatch();

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
    }
    const handleGetStartedClick = (value) => {
        setShowFileUpload(value);
    }

    const onUploadBtn = (event) => {
        let documentDescription = '';
        if (selectedDocumentType === 'Passport or Identity Proof') {
            documentDescription = 'This is a passport document';
        } else if (selectedDocumentType === 'Address Proof') {
            documentDescription = 'This is an address document';
        }
        event.preventDefault();
        console.log("selected file is ", selectedFile)
        // dispatch(uploadDocs("passport", "student", selectedFile))
        dispatch(uploadDocsExt(documentDescription, selectedFile))
    }
    return (
        <>
            {/* <Loader /> */}
            <div className="form-container">
                {showFileUpload ?
                    (
                        <>
                            <div className="col-12 p-2 ">
                                <label className='form-check-label mb-4' htmlFor={selectedDocumentType}>Please upload <b>{selectedDocumentType} </b>  document(s)</label>
                                <div className="field-icon text-center "  >
                                    <input type="file" name={selectedDocumentType} id={selectedDocumentType} className="form-control border border-dark" placeholder="Upload File"
                                        // onChange={(e) => handleFileInputChange(e, 'Academic_Transcript')} 
                                        onChange={handleFileChange}
                                        required />
                                </div>
                            </div>
                            <div className='text-end mt-4'>
                                <button className='btn btn-gradient me-3' onClick={() => handleGetStartedClick(false)}>Previous</button>
                                <button className='btn btn-gradient' onClick={onUploadBtn}>Upload</button>

                            </div>
                        </>) :
                    (
                        <div className="row gx-5 clearfix">
                            <div className="col-12 col-sm-6 col-md-7 col-lg-8 col-xl-9 col-xxl-9">
                                <h3 className="formTitle">
                                    Quick start application with document upload
                                </h3>
                                <p className="formText">
                                    We will read details from the document and
                                    auto-fill your application form
                                </p>
                                <div className="form-group formLabel">
                                    <label htmlFor="documentType">
                                        Choose Document Type
                                    </label>
                                    <div className='row'>
                                        <div className='col-md-8'>
                                            <select
                                                name="documentType"
                                                id="documentType"
                                                className="form-select "
                                                onChange={(e) => setSelectedDocumentType(e.target.value === "Identity" ? 'Passport or Identity Proof' : e.target.value === "Address" ? ' Address Proof' : "")}

                                            >
                                                <option value="">Select</option>
                                                <option value="Identity" selected={"Passport or Identity Proof" === selectedDocumentType}>Passport or Identity Proof</option>
                                                <option value="Address" selected={"Address Proof" === selectedDocumentType}>Address Proof</option>
                                                {/* <option value="application" selected={"10th/12th Mark sheet or IELTS PTE Score or CV" == selectedDocumentType}>10th/12th Mark sheet or IELTS PTE Score or CV </option> */}

                                            </select>
                                        </div>
                                        <div className='col-md-4'>
                                            <button className='btn btn-theme m-3' disabled={!selectedDocumentType} onClick={() => handleGetStartedClick(true)}>Get started</button>
                                        </div>


                                    </div>

                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-md-5 col-lg-4 col-xl-3 col-xxl-3">
                                <img
                                    src="/images/form-img.png"
                                    className="img-fluid"
                                    alt="Image goes here"
                                />
                            </div>
                        </div>
                    )
                }
            </div>
        </>
    )
}

export default AgentStudentUploadDoc