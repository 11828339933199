import axios from "axios";
import { attach_alb, generate_ssl_success, get_address, get_data, get_logo_url_res, get_status, insert_data } from "../../actions/customisation";
import { closeLoader, openLoader } from "../../actions/config";
import apiUrl from "../../ApiUrl";

export const generateSSL = (body, setActiveTab) => async (dispatch, getState) => {
    const baseUrl = getState().config.baseUrl
    console.log("data passing in generate ssl", body)
    // return;
    try {
        dispatch(openLoader())
        const { domainName } = body
        const response = await axios.post(`${apiUrl}/api/brading/create/certificate`, { domainName });
        if (response) {
            console.log("response i am getting for generate ssl is here", response.data)
            // dispatch(fetch_dns_success(response.data))
            dispatch(generate_ssl_success(response.data))
            // dispatch(insertData(body, setActiveTab))
            dispatch(uploadLogo(body, setActiveTab))

        } else {
            throw new Error('No data received from the server');
        }
    }
    catch (error) {
        console.log("error is here:- ", error);
    }
    finally {
        dispatch(closeLoader())
    }

};

export const uploadLogo = (body, setActiveTab) => async (dispatch, getState) => {
    const baseUrl = getState().config.baseUrl
    try {
        dispatch(openLoader())
        const { logo } = body
        const formData = new FormData();
        formData.append('file', logo);
        const response = await axios.post(`${apiUrl}/api/brading/upload/image`, formData);
        if (response) {
            console.log("response i am getting for upload image is", response.data?.["key"])
            const logo_url = response.data?.["key"]
            const { domainName, brandName, hueVal } = body;
            dispatch(insertData({ domainName, brandName, hueVal, "logoUrl": logo_url }, setActiveTab))

            // dispatch(insert_data(response.data))


        } else {
            throw new Error('No data received from the server');
        }
    }
    catch (error) {
        console.log("error is here:- ", error);
    }
    finally {
        dispatch(closeLoader())
    }
}

export const insertData = (body, setActiveTab) => async (dispatch, getState) => {
    const baseUrl = getState().config.baseUrl
    console.log("body inside insertData is", body)
    // return;

    try {
        dispatch(openLoader())
        const { domainName } = body
        const response = await axios.post(`${apiUrl}/api/brading/insert/data`, body);
        if (response) {
            console.log("response i am getting for insert data is", response.data)
            dispatch(insert_data(response.data))


            if (response.data?.status === "success") {
                console.log("domain name for get data is", domainName)
                dispatch(getData({ domainName }))
                dispatch(updateCognito({ domainName }, setActiveTab))
            }

        } else {
            throw new Error('No data received from the server');
        }
    }
    catch (error) {
        console.log("error is here:- ", error);
    }
    finally {
        dispatch(closeLoader())
    }
};


export const updateData = (body, setActiveTab) => async (dispatch, getState) => {
    const baseUrl = getState().config.baseUrl
    try {
        dispatch(openLoader())
        const { domainName } = body
        const response = await axios.post(`${apiUrl}/api/brading/insert/data`, body);
        if (response) {
            console.log("response i am getting for insert data is", response.data)
            dispatch(insert_data(response.data))
            setActiveTab('theme')

            if (response.data?.status === "success") {
                dispatch(getData({ domainName }))
                setActiveTab('portal')
            }

        } else {
            throw new Error('No data received from the server');
        }
    }
    catch (error) {
        console.log("error is here:- ", error);
    }
    finally {
        dispatch(closeLoader())
    }
};

export const getData = (body) => async (dispatch, getState) => {
    const baseUrl = getState().config.baseUrl
    try {
        dispatch(openLoader())
        const response = await axios.post(`${apiUrl}/api/brading/get/data`, body);
        if (response) {
            console.log("response i am getting for get data is", response.data)
            dispatch(get_data(response.data))

        } else {
            throw new Error('No data received from the server');
        }
    }
    catch (error) {
        console.log("error is here:- ", error);
    }
    finally {
        dispatch(closeLoader())
    }
};

export const getStatus = (body) => async (dispatch, getState) => {
    const baseUrl = getState().config.baseUrl
    try {
        dispatch(openLoader())
        const response = await axios.post(`${apiUrl}/api/brading/certificate/status`, body);
        if (response) {
            console.log("response i am getting for get data is", response.data)
            // dispatch(get_data(response.data))
            dispatch(get_status(response.data))
            const ssl_status = response.data["Certificate_Status"];
            if (ssl_status === 'ISSUED') {
                dispatch(getAddress(body, response.data["CertificateArn"]))

                // dispatch(attachALB({ "certificate_arn": response.data["CertificateArn"] }))
            }

        } else {
            throw new Error('No data received from the server');
        }
    }
    catch (error) {
        console.log("error is here:- ", error);
    }
    finally {
        dispatch(closeLoader())
    }
};

export const updateCognito = (body, setActiveTab) => async (dispatch, getState) => {
    const baseUrl = getState().config.baseUrl
    try {
        dispatch(openLoader())
        const response = await axios.post(`${apiUrl}/api/cognito/update/attribute`, body);
        if (response) {
            console.log("response i am getting for update cognito data is", response.data)
            // dispatch(get_data(response.data))
            // dispatch(get_status(response.data))
            setActiveTab('theme')

        } else {
            throw new Error('No data received from the server');
        }
    }
    catch (error) {
        console.log("error is here:- ", error);
    }
    finally {
        dispatch(closeLoader())
    }
};

export const attachALB = (body) => async (dispatch, getState) => {
    const baseUrl = getState().config.baseUrl
    try {
        dispatch(openLoader())
        const response = await axios.post(`${apiUrl}/api/brading/ssl/attach_to_alb`, body);
        if (response) {
            console.log("response i am getting for attach to arl is", response.data)
            dispatch(attach_alb(response.data))

        } else {
            throw new Error('No data received from the server');
        }
    }
    catch (error) {
        console.log("error is here:- ", error);
    }
    finally {
        dispatch(closeLoader())
    }
}

export const getAddress = (body, crnMsg) => async (dispatch, getState) => {
    const baseUrl = getState().config.baseUrl
    try {
        dispatch(openLoader())
        const response = await axios.post(`${apiUrl}/api/brading/get/address`, body);
        if (response) {
            dispatch(get_address(response.data))
            console.log("response i am getting for get address is", response.data)
            const domainInResponse = response.data["domainName"]
            if (domainInResponse.length !== 0) {
                dispatch(attachALB({ "certificate_arn": crnMsg }))
            }
            // dispatch(attach_alb(response.data))

        } else {
            throw new Error('No data received from the server');
        }
    }
    catch (error) {
        console.log("error is here:- ", error);
    }
    finally {
        dispatch(closeLoader())
    }
}

export const getUploadLogo = (key, value) => async (dispatch, getState) => {
    // const baseUrl = getState().config.baseUrl
    try {
        const imgUrl = `${apiUrl}/api/brading/fetch/image/${value}`;
        console.log("key inside customisation dispatch is here ", key);
        console.log("value inside customisation dispatch is here ", value);
        console.log("image url inside cystomisation dispatch is here ", imgUrl)
        dispatch(get_logo_url_res({ [key]: imgUrl }))
    }
    catch (error) {
        console.log("error is here:- ", error);
    }

};
