import axios from "axios";
import { getCounStudentsStart, getCounStudentsSuccess } from "../../../actions/counsellor/student/studentList";
import apiUrl from "../../../ApiUrl";

export const getCounsellorStudents = () => async (dispatch, getState) => {
    const baseUrl = getState().config.baseUrl
    dispatch(getCounStudentsStart());
    try {
        const response = await axios.get(
            `${apiUrl}/api/counsellor/student-list`
        );
        dispatch(getCounStudentsSuccess(response.data.data))
    } catch (error) {
        console.log(error)
    }

};




