import React from 'react'
import { useSelector } from 'react-redux'

function AgentDocumentsDetails({ setActiveTab }) {
    const userRole = useSelector((state) => (state?.config?.profile_id))
    console.log("Username is here", userRole)
    return (
        <>
            {
                userRole === "agent" && (
                    <>
                        <div className='text-center'>
                            <h2 className='text-success'>Your Application is submitted successfully!</h2>
                        </div>
                        {/* <div className='text-end mt-5'>
                            <button className="btn btn-theme me-1 mt-5" onClick={() => { setActiveTab("employment") }}>Previous</button>
                        </div> */}

                    </>
                )
            }
        </>
    )
}

export default AgentDocumentsDetails