import axios from "axios";
import { applicationDetailsStart, applicationDetailsSuccess } from "../../actions/applications/GetApplicationDetails";
import apiUrl from "../../ApiUrl";

export const applicationDetails = (id) => async (dispatch, getState) => {
    dispatch(applicationDetailsStart());
    const baseUrl = getState().config.baseUrl
    try {
        const response = await axios.get(

            `${apiUrl}/api/application/details/${id}`
        );
        dispatch(applicationDetailsSuccess(response.data))
    } catch (error) {
        console.log(error)
    }
};



