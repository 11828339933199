import React from 'react'

function Header() {
    return (
        <>
            <header className="mobile sticky-top">
                <div className="container-fluid">
                    <div className="row align-items-center clearfix">
                        <div className="col-6 text-start">
                            <a href="/">
                                <img
                                    src="images/logo.svg"
                                    className="img-fluid my-3"
                                    alt="Image goes here"
                                />
                            </a>
                        </div>
                        <div className="col-6 d-none">
                            <ul className="toolbar">
                                <li>
                                    <a href="#" className="bell">
                                        <svg
                                            width={22}
                                            height={22}
                                            viewBox="0 0 22 22"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M19.6675 10.3338V10.3338C19.6675 15.4888 15.4888 19.6675 10.3338 19.6675V19.6675C5.17875 19.6675 1 15.4888 1 10.3338V10.3338C1 5.17875 5.17875 1 10.3338 1V1C15.4888 1 19.6675 5.17875 19.6675 10.3338Z"
                                                stroke="#110F33"
                                                strokeWidth="1.5"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                            <path
                                                d="M21 21L16.9375 16.9375"
                                                stroke="#110F33"
                                                strokeWidth="1.5"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}

export default Header