import React from 'react'
import { useSelector } from 'react-redux'

function ViewStudentPersonalDetails({ setActiveTab }) {

    const personalData = useSelector((state) => state.agentStudentDetails?.studentDetails?.data?.[0])
    console.log("view personal data", personalData)
    return (
        <div className="tab-pane fade show active"
            id="pills-studentpersonal"
            role="tabpanel"
            aria-labelledby="pills-studentpersonal-tab">
            <form
            // onSubmit={handleUpdate}
            >

                <div className="form-area">
                    <div className="form-head">
                        Your Personal Details{" "}
                        <span>We will use your personal details while processing your applications</span>
                    </div>
                    <div className="form-subhead">

                    </div>
                    <div className="row clearfix">
                        <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                            <label className='form-check-label mb-2'>First name</label>
                            <input
                                type="text"
                                id='First_Name'
                                name="First_Name"
                                className="form-control"
                                placeholder="First name"
                                value={personalData?.First_Name}
                                // onChange={handleInputChange}
                                disabled
                            />
                        </div>
                        <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                            <label className='form-check-label mb-2'>Last name</label>
                            <input
                                type="text"
                                id='Last_Name'
                                name="Last_Name"
                                className="form-control"
                                placeholder="Last name"
                                value={personalData?.Last_Name}
                                // onChange={handleInputChange}
                                disabled
                            />
                        </div>
                        <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                            <label className='form-check-label mb-2' htmlFor='dateBirth'>Date of birth</label>
                            <input
                                type="date"
                                id='Date_of_Birth'
                                name="Date_of_Birth"
                                className="form-control"
                                placeholder="Date_of_Birth"
                                value={personalData?.Date_of_Birth}
                                // onChange={handleInputChange}
                                // onChange={handleInputChange}
                                disabled
                            />
                        </div>
                        <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                            <label className='form-check-label mb-2'>Nationality</label>
                            <input
                                type="text"
                                id='Nationality'
                                name="Nationality"
                                className="form-control"
                                placeholder="Nationality"
                                value={personalData?.Nationality}
                                disabled
                            // onChange={handleInputChange}
                            // onChange={handleInputChange}
                            />
                        </div>

                        <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                            <label className='form-check-label mb-2'>Email</label>
                            <input
                                type="email"
                                id='Email'
                                name="Email"
                                className="form-control"
                                placeholder="Enter your email"
                                value={personalData?.Email}
                                // onChange={handleInputChange}
                                disabled
                            />
                        </div>

                        <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                            <label className='form-check-label mb-2'>Mobile number</label>
                            <div className="field-phone">
                                <span className="country-code">
                                    <img
                                        src="/images/icons/indian-flag.svg"
                                        className="img-fluid mCS_img_loaded"
                                        alt="indian flag goes here"
                                    />
                                    <select
                                        name="ccode"
                                        id="ccode"
                                        defaultValue={personalData?.Phone?.slice(0, (personalData?.Phone?.length || 0) - 10)}
                                    >
                                        <option selected
                                            value={personalData?.Phone?.slice(0, (personalData?.Phone?.length || 0) - 10)}
                                        >
                                            {personalData?.Phone?.slice(0, (personalData?.Phone?.length || 0) - 10)}
                                        </option>
                                    </select>
                                </span>
                                <input
                                    type="tel"
                                    name="Phone"
                                    id="Phone"
                                    className="form-control"
                                    placeholder="Mobile Number"
                                    value={personalData?.Phone?.slice((personalData?.Phone?.length || 0) - 10)}
                                    // onChange={handleInputChange}
                                    disabled
                                />
                            </div>
                        </div>
                    </div>
                    <div className='container mt-5'>
                        <div className='row justify-content-end clearfix'>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 text-end p-4 form-bottom" >
                                <button className="btn btn-theme me-1" onClick={() => { setActiveTab("studentaddress") }}>Next</button>
                            </div>
                        </div>
                    </div>


                </div>

            </form>

        </div>
    )
}

export default ViewStudentPersonalDetails