import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isApplication: false,
    applicationDetailsMessage: "",
    error: false,
    applicationDetails: {}
}

export const applicationDetailsSlice = createSlice({
    name: 'applicationDetails',
    initialState,
    reducers: {
        applicationDetailsStart: (state) => {
            return { ...state, applicationDetailsMessage: "Fetching applications details started ...", error: false, applicationDetails: {} };
        },
        applicationDetailsSuccess: (state, action) => {
            return { ...state, applicationDetailsMessage: "All applications fetched successfully", error: false, isApplication: true, applicationDetails: action.payload };
        }

    }
})

export const { applicationDetailsStart, applicationDetailsSuccess } = applicationDetailsSlice.actions;

export default applicationDetailsSlice.reducer;