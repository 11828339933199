import axios from "axios";
import { useDispatch } from "react-redux";
import { getAgentsStart } from "../../actions/agents/GetAgents";
import { getAgentStudentDetailsStart } from "../../actions/agents/GetAgentStudentDetails";
import { applicationStart } from "../../actions/applications/CreateApplication";
import { applicationDetailsStart } from "../../actions/applications/GetApplicationDetails";
import { resetApplications } from "../../actions/applications/GetApplications";
import { getFieldsStart } from "../../actions/applications/MetaData";
import { getDetailsStart, resetStudentDetails } from "../../actions/students/getDetails";
import { uploadFilesStart } from "../../actions/uploadFiles/uploads";
import { resetCourses } from "../../actions/GetCourses";
import { studentListStart } from "../../actions/GetStudentsList";
import { resetUniversities } from "../../actions/GetUniversities";
import { useNavigate } from "react-router-dom";
import { resetAgentStudents } from "../../actions/agents/GetAgentStudents";
import { resetCounStudents } from "../../actions/counsellor/student/studentList";
import { resetCounAgent } from "../../actions/counsellor/agentDetails";
import { reset_customisation_data } from "../../actions/customisation";
import { resetConfig } from "../../actions/config";
import { getResetDocs } from "../../actions/uploadDocuments/getUploadedDocs";
import { resetDocsExt } from "../../actions/uploadDocuments";

export const sessionLogout = (navigate) => async (dispatch) => {
    try {
        dispatch(getAgentsStart());
        dispatch(getAgentStudentDetailsStart());
        dispatch(applicationDetailsStart());
        dispatch(getFieldsStart());
        dispatch(getDetailsStart());
        dispatch(uploadFilesStart());
        dispatch(resetCourses());
        dispatch(studentListStart());
        dispatch(resetUniversities());
        dispatch(resetApplications());
        dispatch(resetAgentStudents());
        dispatch(resetCounStudents());
        dispatch(resetCounAgent());
        dispatch(resetStudentDetails());
        dispatch(reset_customisation_data());
        dispatch(resetConfig())
        dispatch(getResetDocs())
        dispatch(resetDocsExt())

    } catch (error) {
        console.log(error)
    }
};



