import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    course_id: null,
    selectedCourse: {},
    agentNewApplicationFormData: {}

}
export const agentNewApplicationSlice = createSlice({
    name: 'applicationCreated',
    initialState,
    reducers: {
        setCourseId: (state, action) => {
            return {
                ...state,
                course_id: action.payload
            };
        },
        setSelectedCourse: (state, action) => {
            return {
                ...state,
                selectedCourse: action.payload
            }
        },
        setFormDataFirstPart: (state, action) => {
            return {
                ...state,
                agentNewApplicationFormData: action.payload,

            }
        },
        agentApplicationStart: (state) => {
            return { ...state, error: false, agentNewApplicationFormData: {}, selectedCourse: {}, }
        }

    },
});
export const { setCourseId, setFormDataFirstPart, agentApplicationStart, setSelectedCourse } = agentNewApplicationSlice.actions;
export default agentNewApplicationSlice.reducer;