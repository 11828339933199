import React from 'react'
import MobHomeHeader from '../../components/MobHomeHeader'
import HomeNavbar from '../../components/HomeNavbar'
import HomeMainSection from '../../components/HomeMainSection'
import HomeFooter from '../../components/HomeFooter'
import ToastContainer from '../../components/ToastContainer'
import LoginModal from '../../components/Modals/LoginModal'
import RegisterModal from '../../components/Modals/RegisterModal'
import VerifyModal from '../../components/Modals/VerifyModal'
import TestimonialModal from '../../components/Modals/TestimonialModal'

function CounsellorHomePage() {
    return (
        <>
            <MobHomeHeader />
            <HomeNavbar />
            <HomeMainSection
                title="Guide Students Toward Success in Education"
                subtitle="Assist students smoothly through their applications process and provide invaluable support every step of the way"
            />
            <HomeFooter />
            <VerifyModal />
            <TestimonialModal />
        </>
    )
}

export default CounsellorHomePage