import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isFieldsAvailable: false,
    fieldsMessage: "",
    error: false,
    fieldsList: []
}

export const getMetaFieldsSlice = createSlice({
    name: 'getMetaFields',
    initialState,
    reducers: {
        getFieldsStart: (state) => {
            return { ...state, fieldsMessage: "Fetching meta fields started ...", error: false, getApplicationsStart: [] };
        },
        getFieldsSuccess: (state, action) => {
            return { ...state, fieldsMessage: "All meta fields fetched successfully", error: false, isFieldsAvailable: true, fieldsList: action.payload };
        }


    }
})

export const { getFieldsStart, getFieldsSuccess } = getMetaFieldsSlice.actions;

export default getMetaFieldsSlice.reducer;