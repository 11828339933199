import axios from "axios";
import { getCounsellorCourseStart, getCounsellorCourseSuccess, getCourseStart, getCourseSuccess } from "../actions/GetCourses";
import apiUrl from "../ApiUrl";

export const getCourses = () => async (dispatch, getState) => {
    dispatch(getCourseStart());
    const baseUrl = getState().config.baseUrl
    try {
        const response = await axios.get(`${apiUrl}/api/course`);
        dispatch(getCourseSuccess(response.data))
    } catch (error) {
        console.log(error)
    }
};

export const getCounsellorCourses = () => async (dispatch, getState) => {
    dispatch(getCounsellorCourseStart());
    const baseUrl = getState().config.baseUrl
    try {
        const response = await axios.get(`${apiUrl}/api/counsellor/course-list`);
        dispatch(getCounsellorCourseSuccess(response.data))
    } catch (error) {
        console.log(error)
    }
};



