import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    releasedLetter: "",
    error: false,
    offerLetter: ''
}

export const releasedLetterSlice = createSlice({
    name: 'releasedLetter',
    initialState,
    reducers: {
        releasedLetterStart: (state) => {
            return { ...state, releasedLetter: "Offer letter released ...", error: false, status: null, offerLetter: "" };
        },
        releasedLetterSuccess: (state, action) => {
            console.log("Status I am getting as ", action.payload)
            return { ...state, releasedLetter: "Offer letter released successfully", error: false, offerLetter: action.payload };
        },


    }
})

export const { releasedLetterStart, releasedLetterSuccess } = releasedLetterSlice.actions;

export default releasedLetterSlice.reducer;
