import React, { useEffect, useState } from 'react'
import { FiUpload } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom'
import { agentCreateNewApplication } from '../../../@redux/dispatch/agents/agentApplication/agentCreateApplicationReducer';
import { upload } from '../../../@redux/dispatch/uploadFiles/uploads';
import Loader from '../../Loader';

function AgentEmploymentDetails({ setActiveTab }) {
    const dispatch = useDispatch();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search)
    const profile_id = queryParams.get('profile_id');
    const courseId = useSelector((state) => state.createNewApplication.course_id)
    const metaFields = useSelector(state => state.metaFields?.fieldsList?.fields)
    const firstPartDatas = useSelector(state => state.agentNewApplication)
    const [formData2, setFormData2] = useState({ Disability_or_Impairment: '', How_did_you_hear_about_TEMC: '', Electronic_signature: '', Date: '', })
    const [identificationFile, setIdentificationFile] = useState(null);
    const [transcriptFile, setTranscriptFile] = useState(null);
    const photoUploadFile = useSelector((state) => state.uploads.Please_upload_photo_identification_of_yourself)
    const academicFile = useSelector((state) => state.uploads.Academic_Transcript)
    console.log("photoUploadFile Id will be:- ", photoUploadFile)
    console.log("academicFile Id will be:- ", academicFile)
    const handleInputChange = (e, isBool = false) => {
        const { name, value } = e.target;
        if (isBool)
            setFormData2({
                ...formData2,
                [name]: value === "true",
            })
        else
            setFormData2({
                ...formData2,
                [name]: value,
            })
    }
    const handleFileInputChange = (e, fileName) => {
        const file = e.target.files[0]
        // console.log(`File uploaded for ${fileName}`, file);

        if (fileName === 'Please_upload_photo_identification_of_yourself') {
            setIdentificationFile(file);
        } else if (fileName === 'Academic_Transcript') {
            setTranscriptFile(file);
        }
    }

    useEffect(() => {
        console.log("Updated photoUploadFile:", photoUploadFile);
        console.log("Updated academicFile:", academicFile);
    }, [photoUploadFile, academicFile]);

    const handleUploadBtn = (e) => {
        e.preventDefault();
        if (identificationFile) {
            dispatch(upload("Please_upload_photo_identification_of_yourself", identificationFile))
            // console.log("this is my file Id", uploadedFileId)
        }
        if (transcriptFile) {
            dispatch(upload("Academic_Transcript", transcriptFile))
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("employment form Data", formData2);

        // if (!photoUploadFile && !academicFile) {
        //     alert("Please click on upload button for all files")
        //     return;
        // }
        let appDatas = {
            "course_id": courseId,
            ...firstPartDatas["createApplicationFormData"],
            ...formData2,
            /*
                Removed temporary

            "Please_upload_photo_identification_of_yourself": photoUploadFile,
            "Academic_Transcript": academicFile
            */
        }

        dispatch(agentCreateNewApplication({ id: profile_id, formData: appDatas }, setActiveTab))
    }


    console.log("Course Id for employment form by agent", courseId)
    console.log("first part datas for employment form by agent", firstPartDatas)

    return (
        <>
            {/* <Loader /> */}
            <div
                className="tab-pane fade show active"
                id="pills-employment"
                role="tabpanel"
                aria-labelledby="pills-employment-tab"
            >
                <form
                    onSubmit={handleSubmit}
                >
                    <div className="form-area">
                        <div className="form-head">
                            Your Employment Details{" "}
                            <span>We will use your employment details while processing your applications</span>
                        </div>
                        <div className="form-subhead">
                            <hr />
                        </div>
                        <div className="row clearfix">
                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                <label className='form-check-label' htmlFor='Disability_or_Impairment'>Any disability or impairment ?</label>
                                <select className="form-select" name="Disability_or_Impairment" id="Disability_or_Impairment"
                                    onChange={handleInputChange} value={formData2?.Disability_or_Impairment}
                                    required>
                                    <option key={"-1"} value='' disabled>-select-</option>
                                    <option value="false">No</option>
                                    <option value="true">Yes</option>
                                </select>
                            </div>
                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                <label className='form-check-label' htmlFor='How_did_you_hear_about_TEMC'>How do you heard about TEMC</label>
                                <select className="form-select" name="How_did_you_hear_about_TEMC" id="How_did_you_hear_about_TEMC"
                                    onChange={handleInputChange} value={formData2?.How_did_you_hear_about_TEMC}
                                    required>
                                    <option key={"-1"} value='' disabled>-select-</option>
                                    {metaFields?.[22]?.pick_list_values.map((meta, index) => (
                                        <option key={index} value={meta.display_value}>
                                            {meta.display_value}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            {/* 
                            Removed Temporary

                            <div className="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 col-xxl-10">
                                <label className='form-check-label' htmlFor='Please_upload_photo_identification_of_yourself'>Please upload photo(s) of identification</label>
                                <div className="field-icon" >
                                    <input type="file" name="Please_upload_photo_identification_of_yourself" id="Please_upload_photo_identification_of_yourself" className="form-control" placeholder="Upload File"
                                        onChange={(e) => handleFileInputChange(e, 'Please_upload_photo_identification_of_yourself')}
                                        required />
                                </div>
                            </div>
                            {
                                identificationFile && (
                                    <div className="col-2 m-auto">
                                        <button className='btn btn-primary '
                                            onClick={handleUploadBtn}
                                            required><FiUpload /> Upload </button>
                                    </div>
                                )
                            }

                            <div className="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 col-xxl-10">
                                <label className='form-check-label' htmlFor='Academic_Transcript'>Please upload academic transcript(s)</label>
                                <div className="field-icon" >
                                    <input type="file" name="Academic_Transcript" id="Academic_Transcript" className="form-control" placeholder="Upload File"
                                        onChange={(e) => handleFileInputChange(e, 'Academic_Transcript')}
                                        required />
                                </div>
                            </div>
                            {
                                transcriptFile && (
                                    <div className="col-2 m-auto">
                                        <button className='btn btn-primary ' onClick={handleUploadBtn} required><FiUpload /> Upload</button>
                                    </div>
                                )
                            } */}

                            {/*
                            Removed Temporary

                             <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                <label className='form-check-label' htmlFor='Electronic_signature'>Electronic signature</label>
                                <select className="form-select" name="Electronic_signature" id="Electronic_signature"
                                    value={formData2?.Electronic_signature} onChange={(e) => handleInputChange(e, true)}
                                    required>
                                    <option value='' disabled>-select-</option>
                                    <option value={true}>Yes</option>
                                    <option value={false}>No</option>
                                </select>
                            </div> */}
                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                <label className='form-check-label' htmlFor='Date'>Date </label>
                                <input type="date" name="Date" id="Date" className="form-control" placeholder="date"
                                    value={formData2?.Date} onChange={handleInputChange}
                                    required />
                            </div>
                            {/*
                            Removed Temporary
                            
                             <div className='col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 col-xxl-10'>
                                <input type='checkbox' id='acknowledge' name='acknowledge' value='true' className='me-2' required />
                                <label htmlFor='acknowledge' className='form-check-label'>I acknowledge the mentioned details are correct as per my knowledge</label>
                            </div>
                            <div className='col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 col-xxl-10'>
                                <input type='checkbox' id='Terms_and_conditions' name='Terms_and_conditions' value='true' className='me-2' required />
                                <label htmlFor='Terms_and_conditions' className='form-check-label'>I have read and agreed to <Link to="#">terms & condition</Link></label>
                            </div>
                            <div className='col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 col-xxl-10'>
                                <input type='checkbox' id='Privacy_Policy' name='Privacy_Policy' value='true' className='me-2' required />
                                <label htmlFor='Privacy_Policy' className='form-check-label'>I have read and agreed to <Link to="#">privacy policy </Link></label>
                            </div> */}
                        </div>

                        <div className='container mt-3'>
                            <div className='row justify-content-end clearfix'>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 text-end p-4 form-bottom" >
                                    <button className="btn btn-theme me-1" onClick={() => { setActiveTab("references") }}>Previous</button>
                                    {/* <button className="btn btn-theme me-1"
                                        onClick={() => { setActiveTab("documents") }}
                                    // type='button'
                                    >Next</button> */}
                                    <button className="btn btn-theme " type='submit'
                                    /*
                                    Removed Temporary
                                    // disabled={!photoUploadFile || !academicFile}
                                    */

                                    >Save Application</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </form>
            </div>
        </>


    )
}

export default AgentEmploymentDetails