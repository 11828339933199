import axios from "axios";
import { getMergeTagsStart, getMergeTagsSuccess } from "../../actions/template/GetMergeTags";
import apiUrl from "../../ApiUrl";

export const getMergeTagsFieldsDefaults = () => async (dispatch, getState) => {
    const baseUrl = getState().config.baseUrl
    dispatch(getMergeTagsStart());
    try {
        const response = await axios.get(`${apiUrl}/api/template/all/fields/details/default/true`);
        dispatch(getMergeTagsSuccess(response.data))
    } catch (error) {
        console.log(error)
    }
};