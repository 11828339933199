import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isTemplateContent: false,
    contentMessage: "",
    error: false,
    templateId: '',
    templateContent: [],
    isEditorReady: false
}

export const TemplateContentSlice = createSlice({
    name: 'TemplateContent',
    initialState,
    reducers: {
        getTemplateContentStart: (state) => {
            return { ...state, contentMessage: "Fetching content started ...", error: false, };
        },
        getTemplateContentSuccess: (state, action) => {
            return { ...state, contentMessage: "Content fetched successfully", error: false, isTemplateContent: true, templateContent: action.payload };
        },
        createTemplateStart: (state) => {
            return { ...state, contentMessage: "Creation of template started ...", error: false, };
        },
        createTemplateSuccess: (state, action) => {
            return { ...state, contentMessage: "Template created successfully", error: false, isTemplateContent: true, };
        },
        updateTemplateStart: (state) => {
            return { ...state, contentMessage: "Updating of template started ...", error: false, };
        },
        updateTemplateSuccess: (state, action) => {
            return { ...state, contentMessage: "Template updated successfully", error: false, isTemplateContent: true, };
        },
        setEditorReady: (state, action) => {
            state.isEditorReady = action.payload
        }

    }
})

export const { setEditorReady, getTemplateContentStart, getTemplateContentSuccess, createTemplateStart, createTemplateSuccess, updateTemplateStart, updateTemplateSuccess } = TemplateContentSlice.actions;


// export const createTemplateSlice = createSlice({
//     name: 'createTemplate',
//     initialState,
//     reducers: {


//     }
// })

// export const { createTemplateStart, createTemplateSuccess } = createTemplateSlice.actions;


// export const updateTemplateSlice = createSlice({
//     name: 'updateTemplate',
//     initialState,
//     reducers: {


//     }
// })

// export const { updateTemplateStart, updateTemplateSuccess } = updateTemplateSlice.actions;

// export default {
//     getTemplateContent: getTemplateContentSlice.reducer,
//     createTemplateSlice: createTemplateSlice.reducer,
//     updateTemplateSlice: updateTemplateSlice.reducer

// };

export default TemplateContentSlice.reducer;
