import axios from "axios";
import { createLetterStart, createLetterSuccess } from "../../actions/Generate_offer_letter";
import { releasedLetterStart, releasedLetterSuccess } from "../../actions/Generate_offer_letter/getReleasedOfferLetter";
import { closeLoader, openLoader } from "../../actions/config";
import apiUrl from "../../ApiUrl";


export const getReleasedLetter = (body) => async (dispatch, getState) => {
    const baseUrl = getState().config.baseUrl
    dispatch(releasedLetterStart());
    dispatch(openLoader());
    try {
        const response = await axios.post(`${apiUrl}/api/offerletter/release/get`, { ...body, env: "sandbox" }, { responseType: "arraybuffer" });
        const blob = new Blob([response.data], {
            type: "application/pdf"
        })
        const url = URL.createObjectURL(blob)
        dispatch(releasedLetterSuccess(url))
        if (response.status === 200) {
            // alert('Offer letter generated succesfully')
            window.open(url, '_blank');
        }
    }
    catch (error) {
        console.log(error)
    }
    finally {
        dispatch(closeLoader());
    }
}


