export default function setBodyColor(hueVal) {
    document.documentElement.style.setProperty("--colors-900", `hsl(${hueVal}, ${90}%, ${90}%)`)
    document.documentElement.style.setProperty("--colors-800", `hsl(${hueVal}, ${100}%, ${30}%)`)
    document.documentElement.style.setProperty("--colors-700", `hsl(${hueVal}, ${100}%, ${20}%)`)
    document.documentElement.style.setProperty("--colors-600", `hsl(${hueVal}, ${60}%, ${60}%)`)
    document.documentElement.style.setProperty("--colors-500", `hsl(${hueVal}, ${50}%, ${50}%)`)
    document.documentElement.style.setProperty("--body-color", `hsl(${hueVal},15%, 95%)`)
    // document.documentElement.style.setProperty("--body-color", `hsl(${hueVal},45%, 90%)`)
    document.documentElement.style.setProperty("--scrollbar", `hsl(${hueVal}, 100%, 75%)`)
    document.documentElement.style.setProperty("--text-color", `hsl(${hueVal}, 100%, 63%)`)
    document.documentElement.style.setProperty("--btn-primary", `hsl(${hueVal}, 100%, 45%)`)
    document.documentElement.style.setProperty("--btn-primary-hover", `hsl(${hueVal}, 100%, 50%)`)
    document.documentElement.style.setProperty("--sidebar-bg-active", `hsl(${hueVal}, 100%, 95%)`)
    document.documentElement.style.setProperty("--sidebar", `hsl(${hueVal},  67%, 18%)`)
    document.documentElement.style.setProperty("--sidebar-bg-hover", `hsl(${hueVal},100%, 98%)`)
    document.documentElement.style.setProperty("--form-tab", `hsl(${hueVal},100%, 99%)`)
    document.documentElement.style.setProperty("--form-bottom", `hsl(${hueVal},100%, 97%)`);
    document.documentElement.style.setProperty("--circle-icon", `hsl(${hueVal},100%, 97%)`);
    document.documentElement.style.setProperty("--circle-icon-hover", `hsl(${hueVal},100%, 95%)`);
    document.documentElement.style.setProperty("--circle-icon-active", `hsl(${hueVal},100%, 87%)`);
    document.documentElement.style.setProperty("--table-row", `hsl(${hueVal},45%, 98%)`);
    document.documentElement.style.setProperty("--box-shadow", `hsl(${hueVal}, 99%, 75%)`);
    document.documentElement.style.setProperty("--nav-link", `hsl(${hueVal}, 100%, 94%)`);


}


// export default function setBodyColor(hueVal) {
//     const root = document.documentElement;
//     const parseHSL = (colorVar) => {
//         const value = root.style.getPropertyValue(colorVar);
//         const matches = value.match(/hsl\((\d+),\s*(\d+)%/);
//         return matches ? parseInt(matches[1]) : null;
//     };

//     const existingHue600 = parseHSL('--colors-600');
//     const existingHue700 = parseHSL('--colors-700');
//     const existingHue800 = parseHSL('--colors-800');
//     const existingHue900 = parseHSL('--colors-900');
//     const existingHueBodyColor = parseHSL('--body-color');
//     const existingHueScrollbar = parseHSL('--scrollbar');
//     const existingHueBtnPrimary = parseHSL('--btn-primary');
//     const existingHueBtnPrimaryHover = parseHSL('--btn-primary-hover');
//     const existingHueSidebarBgActive = parseHSL('--sidebar-bg-active');
//     const existingHueSidebar = parseHSL('--sidebar');
//     const existingHueSidebarBgHover = parseHSL('--sidebar-bg-hover');

//     if (existingHue600 !== null && existingHue700 !== null && existingHue800 !== null &&
//         existingHue900 !== null && existingHueBodyColor !== null && existingHueScrollbar !== null &&
//         existingHueBtnPrimary !== null && existingHueBtnPrimaryHover !== null &&
//         existingHueSidebarBgActive !== null && existingHueSidebar !== null &&
//         existingHueSidebarBgHover !== null) {
//         root.style.setProperty("--colors-600", `hsl(${existingHue600 + hueVal}, 79%, 70%)`);
//         root.style.setProperty("--colors-700", `hsl(${existingHue700 + hueVal}, 81%, 52%)`);
//         root.style.setProperty("--colors-800", `hsl(${existingHue800 + hueVal}, 97%, 45%)`);
//         root.style.setProperty("--colors-900", `hsl(${existingHue900 + hueVal}, 93%, 50%)`);
//         root.style.setProperty("--body-color", `hsl(${existingHueBodyColor + hueVal}, 0%, 96%)`);
//         root.style.setProperty("--scrollbar", `hsl(${existingHueScrollbar + hueVal}, 100%, 75%)`);
//         root.style.setProperty("--btn-primary", `hsl(${existingHueBtnPrimary + hueVal}, 100%, 63%)`);
//         root.style.setProperty("--btn-primary-hover", `hsl(${existingHueBtnPrimaryHover + hueVal}, 100%, 74%)`);
//         root.style.setProperty("--sidebar-bg-active", `hsl(${existingHueSidebarBgActive + hueVal}, 100%, 95%)`);
//         root.style.setProperty("--sidebar", `hsl(${existingHueSidebar + hueVal}, 67%, 18%)`);
//         root.style.setProperty("--sidebar-bg-hover", `hsl(${existingHueSidebarBgHover + hueVal}, 100%, 98%)`);
//     }
// }
