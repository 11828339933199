import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isCourses: false,
    courseMessage: "",
    error: false,
    courses: [],
    counsellorCourses: [],
    loading: true
}

export const getCourseSlice = createSlice({
    name: 'getCourse',
    initialState,
    reducers: {
        getCourseStart: (state) => {
            return { ...state, loading: true };
        },
        getCourseSuccess: (state, action) => {
            return { ...state, courseMessage: "All courses fetched successfully", error: false, isCourses: true, courses: action.payload, loading: false };
        },
        getCounsellorCourseStart: (state) => {
            return { ...state, courseMessage: "All course fetching started", error: false, isCourses: false, }
        },
        getCounsellorCourseSuccess: (state, action) => {
            return { ...state, courseMessage: "All courses fetched successfully", error: false, isCourses: true, loading: false, counsellorCourses: action.payload }
        },
        resetCourses: (state) => {
            return { ...state, courses: [], counsellorCourses: [], loading: true }
        }

    }
})

export const { getCourseStart, getCourseSuccess, getCounsellorCourseStart, getCounsellorCourseSuccess, resetCourses } = getCourseSlice.actions;

export default getCourseSlice.reducer;