import axios from "axios";
import { getCourseStart, getCourseSuccess } from "../actions/GetCourses";
import { counUniversityListSuccess, resetUniversities, universityListStart, universityListSuccess } from "../actions/GetUniversities";
import apiUrl from "../ApiUrl";

export const getUniversity = () => async (dispatch, getState) => {
    const baseUrl = getState().config.baseUrl
    dispatch(universityListStart());
    try {
        const response = await axios.get(
            `${apiUrl}/api/student/University/details`
        );
        dispatch(universityListSuccess(response.data))
    } catch (error) {
        console.log(error)
    }
};

export const getCounUniversity = () => async (dispatch, getState) => {
    const baseUrl = getState().config.baseUrl
    dispatch(universityListStart());
    dispatch(resetUniversities())
    try {
        const response = await axios.get(
            `${apiUrl}/api/counsellor/University/details`
        );
        dispatch(counUniversityListSuccess(response.data))
    } catch (error) {
        console.log(error)
    }
};




