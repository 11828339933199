import React from 'react'
import { useSelector } from 'react-redux'
import CounsellorDocExt from './CounsellorDocExt'

function CounsellorPersonalDetails() {
    const personalData = useSelector((state) => state.studentsDetails.data)

    return (

        <div className="tab-pane fade show active"
            id="pills-personal"
            role="tabpanel"
            aria-labelledby="pills-personal-tab">
            <form
            //  onSubmit={handleUpdate}
            >
                <CounsellorDocExt />
                <div className="form-separator" />
                <div className="form-area">
                    <div className="form-head">
                        Your Personal Details{" "}
                        <span>We will use your personal details while processing your applications</span>
                    </div>
                    <div className="form-subhead">

                    </div>
                    <div className="row clearfix">
                        <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                            <label className='form-check-label mb-2'>First name</label>
                            <input
                                type="text"
                                id='firstName'
                                name="firstName"
                                className="form-control"
                                placeholder="First name"
                                value={personalData?.First_Name}
                                disabled
                            />
                        </div>
                        <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                            <label className='form-check-label mb-2'>Last name</label>
                            <input
                                type="text"
                                id='lastName'
                                name="lastName"
                                className="form-control"
                                placeholder="Last name"
                                value={personalData?.Last_Name}
                                disabled
                            />
                        </div>
                        <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                            <label className='form-check-label mb-2'>Email</label>
                            <input
                                type="email"
                                id='email'
                                name="email"
                                className="form-control"
                                placeholder="Enter your email"
                                value={personalData?.Email}
                                disabled
                            />
                        </div>

                        <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                            <label className='form-check-label mb-2'>Mobile number</label>
                            <div className="field-phone">
                                <span className="country-code">
                                    <img
                                        src="images/icons/indian-flag.svg"
                                        className="img-fluid mCS_img_loaded"
                                        alt="indian flag goes here"
                                    />
                                    <p className='m-auto'>{personalData?.Phone?.slice(0, (personalData?.Phone?.length || 0) - 10)}</p>

                                    {/* <select
                                        name="ccode"
                                        id="ccode"
                                    >
                                        <option selected
                                            value={personalData?.Phone?.slice(0, (personalData?.Phone?.length || 0) - 10)}
                                        >
                                            {personalData?.Phone?.slice(0, (personalData?.Phone?.length || 0) - 10)}
                                        </option>
                                    </select> */}
                                </span>
                                <input
                                    type="tel"
                                    name="phone"
                                    id="phone"
                                    className="form-control"
                                    placeholder="Mobile Number"
                                    value={personalData?.Phone?.slice((personalData?.Phone?.length || 0) - 10)}
                                    disabled
                                />
                            </div>
                        </div>
                    </div>



                </div>

            </form>

        </div>

    )
}

export default CounsellorPersonalDetails