import axios from "axios";
import { uploadFilesStart, uploadFilesSuccess } from "../../actions/uploadFiles/uploads";
import { closeLoader, openLoader } from "../../actions/config";
import apiUrl from "../../ApiUrl";

export const upload = (type, file) => async (dispatch, getState) => {
    const baseUrl = getState().config.baseUrl
    console.log("Here is the type", type);
    console.log("Here is the file", file)
    dispatch(uploadFilesStart());
    dispatch(openLoader());
    try {
        const formData = new FormData();
        formData.append('file', file);
        const response = await axios.post(
            `${apiUrl}/api/upload`,
            formData
        );
        // const fileId = response?.data?.data.length > 0 && response?.data?.data[0]?.details?.id
        const info = response?.data?.data;
        if (info.length > 0) {
            const fileId = info[0]?.details?.id
            console.log("uploaded file Id is", fileId)
            // alert("File uploaded successfully")
            dispatch(uploadFilesSuccess({ type, fileId }))
        }

        // if (type === "identificationFile") {

        // }
    } catch (error) {
        console.log(error)
    }
    finally {
        dispatch(closeLoader());
    }

};



