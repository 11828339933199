import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { updateStudent } from '../../../@redux/dispatch/students/updateStudent';
import { updateAgentStudentDetails } from '../../../@redux/dispatch/agents/UpdateAgentStudentDetailsReducer';

function StudentAddressDetails({ setActiveTab }) {
    const location = useLocation();
    const dispatch = useDispatch();
    const queryParams = new URLSearchParams(location.search);
    const profile_id = queryParams.get('profile_id');
    const studentAddressData = useSelector((state) => state?.agentStudentDetails?.studentDetails?.data?.[0])

    const [addressFormData, setAddressFormData] = useState({})

    console.log("student profile id for address page", profile_id)

    const handleInputChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        setAddressFormData({
            ...addressFormData,
            [name]: value
        })
    }

    const handleUpdate = (event) => {
        event.preventDefault();
        dispatch(updateAgentStudentDetails({ id: profile_id, formData: addressFormData }))
    }

    useEffect(() => {
        console.log("agent's student related information", studentAddressData)
        setAddressFormData(studentAddressData)
    }, [studentAddressData])


    return (
        <div
            className="tab-pane fade show active"
            id="pills-studentaddress"
            role="tabpanel"
            aria-labelledby="pills-studentaddress-tab"
        >
            <form
                onSubmit={handleUpdate}
            >

                <div className="form-area">
                    <div className="form-head">
                        Your Address Details{" "}
                        <span>We will use your address details while processing your applications</span>
                    </div>
                    <div className="form-subhead">
                        <hr />
                    </div>
                    <div className="row clearfix">
                        <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                            <label className='form-check-label'>Street Address Line 1</label>
                            <input
                                type="text"
                                id='Street_Address'
                                name="Street_Address"
                                className="form-control"
                                placeholder="Street Address Line 1"
                                value={addressFormData?.Street_Address}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                            <label className='form-check-label'>Street Address Line 2</label>
                            <input
                                type="text"
                                id='Street_Address_Line_2'
                                name="Street_Address_Line_2"
                                className="form-control"
                                placeholder="Street Address Line 2"
                                value={addressFormData?.Street_Address_Line_2}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                            <label className='form-check-label'>City</label>
                            <input
                                type="text"
                                name="City"
                                id="City"
                                className="form-control"
                                placeholder="City"
                                value={addressFormData?.City}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                            <label className='form-check-label'>State/Province</label>
                            <input
                                type="text"
                                id='Mailing_State'
                                name="Mailing_State"
                                className="form-control"
                                placeholder="State"
                                value={addressFormData?.Mailing_State}
                                onChange={handleInputChange}
                            />
                        </div>

                        <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                            <label className='form-check-label'>Postal/Zip Code</label>
                            <input
                                type="Number"
                                id='Mailing_Zip'
                                name="Mailing_Zip"
                                className="form-control"
                                placeholder="Postal Code"
                                value={addressFormData?.Mailing_Zip}
                                onChange={handleInputChange}
                            />
                        </div>

                        <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                            <label className='form-check-label'>Country</label>
                            <input
                                type='text'
                                name='Country'
                                id='Country'
                                className='form-control'
                                placeholder='Country'
                                value={addressFormData?.Country}
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>

                    <div className='container'>
                        <div className='row justify-content-end clearfix'>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 text-end p-4 form-bottom" >
                                <button className="btn btn-theme me-1" onClick={() => { setActiveTab("studentpersonal") }}>Previous</button>
                                {/* <button className="btn btn-theme me-1" onClick={() => { setActiveTab("references") }}>Next</button> */}
                                <button className="btn btn-theme" type='submit'>Update profile</button>
                            </div>
                        </div>
                    </div>
                </div>


            </form>
        </div>
    )
}

export default StudentAddressDetails