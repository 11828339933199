// import React,{useState} from 'react'
// import ModalSchema from './ModalSchema';

function VerifyModal() {
    // const [isModalOpen, setIsModalOpen] = useState(false);
    
    return (
        <>
        {/* <ModalSchema content={content} isOpen={isModalOpen} setIsOpen={setIsModalOpen} />

        <button
        type="submit"
        // data-bs-toggle='modal'
        // data-bs-dismiss='modal'
        // data-bs-target='#verify'
        onClick={()=>{
            setIsModalOpen(!isModalOpen)
        }}
        className="btn btn-gradient mt-32 w-50"
      >
        Register
      </button> */}
        </>
    )
}

export default VerifyModal