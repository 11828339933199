import axios from "axios";
import apiUrl from "../../ApiUrl";
import toastr from "toastr";
import { universityDetails, universityList } from "../../actions/university";
import { getCounUniversity } from "../GetUniversitiesReducer";
import { closeLoader, openLoader } from "../../actions/config";

export const getUniversityList = () => async (dispatch, getState) => {
    try {

        const response = await axios.get(`${apiUrl}/api/crm/list/university`);
        if (response && response.data && !response.data.error) {
            console.log("response i am getting for getting university list in dispatch", response.data)
            dispatch(universityList(response.data))

        }
        else if (response && response.data && !response.data.error) {
            toastr.error(response.data.error)
        }
        else {
            throw new Error('No data received from the server');
        }
    }
    catch (error) {
        console.log("error is here:- ", error);
    }

};

export const createUniversity = (body, closeModal) => async (dispatch) => {
    dispatch(openLoader())
    try {

        const response = await axios.post(`${apiUrl}/api/crm/create/university`, body);
        if (response && response.data && !response.data.error) {
            console.log("response i am getting for creating university list in dispatch", response.data)
            // dispatch(universityList(response.data))
            dispatch(getCounUniversity())
            closeModal();

        }
        else if (response && response.data && !response.data.error) {
            toastr.error(response.data.error)
        }
        else {
            throw new Error('No data received from the server');
        }
    }
    catch (error) {
        console.log("error is here:- ", error);
    }
    finally {
        dispatch(closeLoader())
    }
}

export const getUniversityDetailsId = (Id) => async (dispatch) => {
    dispatch(openLoader())
    try {

        const response = await axios.get(`${apiUrl}/api/university/details?universityId=${Id}`);
        if (response && response.data && !response.data.error) {
            console.log("response i am getting for creating university list in dispatch", response.data)
            dispatch(universityDetails(response.data))

        }
        else if (response && response.data && !response.data.error) {
            toastr.error(response.data.error)
        }
        else {
            throw new Error('No data received from the server');
        }
    }
    catch (error) {
        console.log("error is here:- ", error);
    }
    finally {
        dispatch(closeLoader())
    }
}

export const updateUniversity = (body, closeModal) => async (dispatch) => {
    dispatch(openLoader())
    try {

        const response = await axios.put(`${apiUrl}/api/crm/update/university`, body);
        if (response && response.data && !response.data.error) {
            console.log("response i am getting for creating university list in dispatch", response.data)
            // dispatch(universityList(response.data))
            dispatch(getCounUniversity())
            closeModal();

        }
        else if (response && response.data && !response.data.error) {
            toastr.error(response.data.error)
        }
        else {
            throw new Error('No data received from the server');
        }
    }
    catch (error) {
        console.log("error is here:- ", error);
    }
    finally {
        dispatch(closeLoader())
    }
}