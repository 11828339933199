import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    isAgents: false,
    agentMessage: "",
    error: false,
    agentsList: []
}

export const getAgentsSlice = createSlice({
    name: 'getAgents',
    initialState,
    reducers: {
        getAgentsStart: (state) => {
            return { ...state, agentMessage: "Getting agents list started ...", error: false };
        },
        getAgentsSuccess: (state, action) => {
            return { ...state, agentMessage: "All agents fetched successfully", error: false, isAgents: true, agentsList: action.payload };
        }

    }
})

export const { getAgentsStart, getAgentsSuccess } = getAgentsSlice.actions;

export default getAgentsSlice.reducer;