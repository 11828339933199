import axios from "axios";
import { createLetterStart, createLetterSuccess } from "../../actions/Generate_offer_letter";
import { closeLoader, openLoader } from "../../actions/config";
import { agentStudentDetailsId } from "../agents/GetAgentStudentDetailsReducer";
import { applicationDetails } from "../applications/GetApplicationDetailsReducer";
import apiUrl from "../../ApiUrl";

const arrayBufferToBase64 = (buffer) => {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return btoa(binary);
};

export const generateOfferLetter = (body) => async (dispatch, getState) => {
    const baseUrl = getState().config.baseUrl;
    const { application_id } = body;
    console.log("body of generate offer letter is", application_id)

    dispatch(createLetterStart());
    dispatch(openLoader());
    try {
        const response = await axios.post(`${apiUrl}/api/offerletter/generate/create`, { ...body, env: "sandbox" }, { responseType: "arraybuffer" });


        const blob = new Blob([response.data], {
            type: "application/pdf"
        })

        const url = URL.createObjectURL(blob)

        dispatch(applicationDetails(application_id))
        dispatch(createLetterSuccess(url))

        // const response = await axios.post(`https://api-sandbox.edbucket.com:5000/api//api/template/create/fields/batch/`, body);
        // console.log("Response I am getting as", response.blob());

        // const reader = new FileReader();
        // reader.onload = () => {
        // const buffer = reader.result;
        // const base64 = btoa(encodeURIComponent(response.data));
        // console.log("pdf response", response)
        // };
        // reader.readAsArrayBuffer(blob)

        if (response.status === 200) {
            // alert('Offer letter generated succesfully')
            // Assuming pdfData is the raw PDF data received from the API
            // Open the PDF in a new tab
            window.open(url, '_blank');
        }
        // const reader = new FileReader()
        // reader.onload = () => {
        //     const base64 = reader.result.split(',')[1];
        //     dispatch(createLetterSuccess(base64))
        //     // setBase64String(base64);
        // };
        // reader.readAsArrayBuffer(response.data)


    }
    catch (error) {
        console.log(error)
    }
    finally {
        dispatch(closeLoader());
    }
}

export const releaseOfferLetter = (body) => async (dispatch, getState) => {
    const baseUrl = getState().config.baseUrl
    dispatch(openLoader());
    try {
        const response = await axios.post(`${apiUrl}/api/offerletter/release/create`, { ...body, env: "sandbox" });
        if (response.status === 200) {
            // alert("offer letter release successfully")
        }
    }
    catch (error) {
        console.log(error)
    }
    finally {
        dispatch(closeLoader());
    }
}
