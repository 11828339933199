import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { createCustomTags, getCustomTags } from './dispatch/template/customMergeTags';
import { createTemplate, getTemplate } from './dispatch/template/templateContent';
import { forgotPassword } from './dispatch/config';

function ApiCall() {
    const dispatch = useDispatch();
    const [selectedFile, setSelectedFile] = useState(null);
    const data = useSelector((state) => state.templateContent)
    console.log("datas are:-".data)
    const onClick = () => {
        // dispatch(getCustomTags({ id: "123" }))
        // dispatch(createCustomTags({
        //     id: 123,
        //     "fields": [
        //         {
        //             "field_key": "Full_Name",
        //             // optional: required if custom field
        //             "value_type": "text",
        //             "value": null
        //         },
        //         {
        //             "field_key": "Alias",
        //             // optional: required if custom field
        //             "value_type": "text",
        //             "value": null
        //         },
        //         {
        //             "field_key": "course_start",
        //             // optional: required if custom field
        //             "value_type": "text",
        //             "value": null
        //         }
        //     ]
        // }))
        // dispatch(getTemplate('123'))
        // dispatch(createTemplate({
        //     course_id: 1234,
        //     content: "<div>Hi<div>",

        // }))
        dispatch(forgotPassword({ email: "vagij@yopmail.com" }))
    }

    useEffect(() => {
        console.log('getting profile id from session', data)
    }, [data])
    return (
        <div>
            <button onClick={onClick}>API Call</button>
        </div>

    )
}

export default ApiCall