import { TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { FiCornerRightDown } from "react-icons/fi";
import { RiEdit2Fill } from "react-icons/ri";
import { useDispatch, useSelector } from 'react-redux';
import { getStatus, getData, attachALB } from '../../../@redux/dispatch/customisation_dispatch';
import { IoMdCheckmarkCircle } from "react-icons/io";
import { Warning } from '@mui/icons-material';
function Portal() {
    // const records = useSelector((state) => (state.fetch_dns?.fetch_dns_record))
    const dnsRecord = useSelector((state) => (state?.customisation?.get_data?.dnsRecord))
    const getDataRecord = useSelector((state) => (state?.customisation?.get_data))
    // const CertificateArn = useSelector((state) => (state?.customisation?.get_status?.CertificateArn))
    const getStatusRecord = useSelector((state) => (state?.customisation?.get_status))
    const albMessage = useSelector((state) => (state?.customisation?.attach_alb["message"]))
    const current_status = useSelector((state) => (state?.customisation?.get_status?.Certificate_Status))
    const domainName = useSelector((state) => (state?.customisation?.get_data?.domainName))
    const addressRecord = useSelector((state) => (state?.customisation?.get_address?.["domainName"]?.[0]))
    const dispatch = useDispatch();
    console.log("get data response on portal page :- ", getDataRecord)
    // console.log("get certificate arn on portal page :- ", CertificateArn)
    console.log("attach alb value on portal page :-", albMessage)
    console.log("get status response on portal page :- ", getStatusRecord)
    console.log("current status on portal page :- ", current_status)
    console.log("my dns records from get data on portal page :-", dnsRecord)
    console.log("address record on portal page is:- ", addressRecord)
    const [isActive, setIsActive] = useState('true')
    const [record_type, set_record_type] = useState("abc12ff")
    const [record_name, set_record_name] = useState("def22aa");
    const [record_value, set_record_value] = useState("vbhugj566");
    // const [domainName, setDomainName] = useState('');
    const [copyButtonText, setCopyButtonText] = useState({
        CNAME_name: 'Copy',
        CVALUE_value: 'Copy',
        domain_name: 'Copy',
        dnsRecord: 'Copy'
    });

    const copyToClipboard = async (value, field) => {
        try {
            await navigator.clipboard.writeText(value);
            setCopyButtonText(prevState => ({
                ...prevState,
                [field]: "Copied!"
            }));
            // setCopyButtonText("Copied!");
            setTimeout(() => {
                setCopyButtonText(prevState => ({
                    ...prevState,
                    [field]: "Copy"
                }));
                // setTimeout(() => {
                //     setCopyButtonText("Copy");
                // }, 3000);
            }, 1000);
        } catch (err) {
            console.error(
                "Unable to copy to clipboard.", err
            );
        }
    }


    useEffect(() => {
        dispatch(getStatus({ "domainName": domainName }))
    }, [])
    return (
        <div className='overflow-auto' style={{ height: '76vh' }}>

            <div className='d-flex justify-content-around '>
                {!isActive && <div className="mt-5 col-6  p-0 ps-3  shadow-sm border rounded-3 bg-white shadow-lg align-self-baseline mx-auto">
                    <form className=' p-3 m-3 '>
                        <div className='mx-auto d-flex flex-column'>
                            <h6 style={{ color: '#495057' }}>Write your domain name below <FiCornerRightDown className='mt-1' /></h6>
                            <div className="d-flex ">
                                {/* <TextField variant='standard' className='w-100 py-3 me-2' value={domainName} onChange={(e) => setDomainName(e.target.value)} /> */}
                            </div>

                        </div>
                        <div className='text-end'>
                            <button type='button' className='mt-2 btn btn-purple' onClick={() => setIsActive(true)}> Generate SSL</button>
                        </div>
                    </form>
                </div>}
                {isActive && <div className='mt-5 m-5'>
                    <div className='card  p-2   shadow-lg bg-white p-3'>
                        <div className='card-body'>
                            <div className='mb-3'>
                                <h6 className='mb-4 branding' >In your domain registrar, add below records</h6>
                                <table className="table ">
                                    <thead>
                                        <tr>
                                            <th scope="col">Type</th>
                                            <th scope="col">Name</th>
                                            <th scope="col">Value</th>
                                            <th scope="col"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><div className='mt-3'><span >CNAME</span></div></td>
                                            <td> <div className='d-flex justify-content-between mx-2 align-items-center '><span className='text-secondary text-nowrap' >{getStatusRecord?.CNAME_name?.length < 20 ? getStatusRecord?.CNAME_name : getStatusRecord?.CNAME_name?.slice(0, 20) + "..."}{" "}</span>
                                                <button className='ms-1 btn border border-2 mb-1' onClick={() => copyToClipboard(getStatusRecord?.CNAME_name, 'CNAME_name')}>{copyButtonText.CNAME_name}</button>
                                            </div></td>
                                            <td> <div className='d-flex justify-content-between mx-2 align-items-center '><span className='text-secondary text-nowrap' >{getStatusRecord?.CVALUE_value?.length < 20 ? getStatusRecord?.CVALUE_value : getStatusRecord?.CVALUE_value?.slice(0, 20) + "..."}{" "}</span>
                                                <button className='ms-1 btn border border-2 mb-1' onClick={() => copyToClipboard(getStatusRecord?.CVALUE_value, 'CVALUE_value')}>{copyButtonText.CVALUE_value}</button>
                                            </div></td>
                                            <td>
                                                <div className='d-flex justify-content-between mx-2 mt-2 align-items-center'>
                                                    <span style={{ color: current_status === 'ISSUED' ? 'green' : current_status === 'PENDING_VALIDATION' ? 'orange' : 'blue' }}>
                                                        <small>{(current_status === 'ISSUED' ? <><IoMdCheckmarkCircle /> VALIDATED</> : (<><IoMdCheckmarkCircle /> YET TO BE VALIDATE</> || 'NA'))}</small>
                                                    </span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><div className='mt-3'><span >CNAME</span></div></td>
                                            <td> <div className='d-flex justify-content-between mx-2 align-items-center '><span className='text-secondary text-nowrap' >{domainName?.length < 25 ? domainName : domainName?.slice(0, 20) + "..."}{" "}</span>
                                                <button className='ms-1 btn border border-2 mb-1' onClick={() => copyToClipboard(domainName, 'domain_name')}>{copyButtonText.domain_name}</button>
                                            </div></td>
                                            <td> <div className='d-flex justify-content-between mx-2 align-items-center '><span className='text-secondary text-nowrap' >{dnsRecord?.length < 20 ? dnsRecord : dnsRecord?.slice(0, 20) + "..."}{" "}</span>
                                                <button className='ms-1 btn border border-2 mb-1' onClick={() => copyToClipboard(dnsRecord, 'dnsRecord')}>{copyButtonText.dnsRecord}</button>
                                            </div></td>
                                            {
                                                (dnsRecord === addressRecord) ?
                                                    <td>
                                                        <div className='d-flex justify-content-between mx-2 mt-2 align-items-center'>
                                                            <span style={{ color: 'green' }}>
                                                                <small><IoMdCheckmarkCircle /> VALIDATED</small>
                                                            </span>
                                                        </div>
                                                    </td>
                                                    :
                                                    <td>
                                                        <div className='d-flex justify-content-between mx-2 mt-2 align-items-center'>
                                                            <span style={{ color: 'orange' }}>
                                                                <small><IoMdCheckmarkCircle /> YET TO BE VALIDATE</small>
                                                            </span>
                                                        </div>
                                                    </td>

                                            }
                                            <td>
                                                <div className='d-flex justify-content-between mx-2 mt-2 align-items-center'>
                                                    <span >
                                                        <small></small>
                                                    </span>
                                                </div>
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>


                            </div>
                            <div className='mt-5 '>
                                <h5 className='mb-4 branding' >DNS Validation Instructions:</h5>
                                <ol style={{ color: '#495057' }}>
                                    <li>Sign in to your domain registrar or DNS hosting provider's dashboard.</li>
                                    <li>Locate the DNS management section for your domain.</li>
                                    <li>Create a CNAME record by specifying the provided record name (often referred to as the "Alias" or "Host") and value (commonly known as the "Points to" or "Target").</li>
                                    <li>Confirm the changes and save them within the DNS settings.</li>
                                    <li>Use the "Redirect" button to verify your custom portal.</li>

                                </ol>
                            </div>

                            {
                                albMessage === 'Certificate added successfully to ALB' && (
                                    <div className='text-end mt-5 '>
                                        <a href={domainName?.startsWith('localhost') ? `http://${domainName}` : `https://${domainName}`} target='_blank' type='button' className=' p-2 px-5 rounded-4  mt-3 btn btn-purple'>Redirect</a>
                                    </div>
                                )
                            }


                        </div>
                    </div>

                </div>
                }
            </div>
        </div>
    )
}

export default Portal