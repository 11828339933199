import React, { useState } from 'react'
import ViewPersonalDetails from '../ViewPersonalDetails'
import ViewAddressDetails from '../ViewAddressDetails'
import ViewReferenceDetails from '../ViewReferenceDetails'
import ViewEmploymentDetails from '../ViewEmploymentDetails'
import ViewDocumentsDetails from '../ViewDocumentsDetails'
import addressDetailsImg from '../../../assets/images/createApplicationTab/AddressDetailsImg.svg'
import referencesDetailsImg from '../../../assets/images/createApplicationTab/ReferencesDetailsImg.svg'
import employmentDetailsImg from '../../../assets/images/createApplicationTab/EmploymentDetailsImg.svg'
import documentDetailsImg from '../../../assets/images/createApplicationTab/DocumentDetailsImg.svg'
import { Link } from 'react-router-dom'
import { PiStudentLight } from 'react-icons/pi'
import { IoHomeOutline } from 'react-icons/io5'

function ViewApplicationForm() {
    const [activeTab, setActiveTab] = useState('viewpersonal');
    const handleTabClick = (tabId) => {
        setActiveTab(tabId);

    }
    return (

        <div className="row clearfix">
            <div className="col-12">
                <div className="form-tab-container">
                    <ul className="nav nav-pills form-nav" id="viewform-tab" role="tablist" >
                        <li className="nav-item">
                            <Link className={`nav-link ${activeTab === 'viewpersonal' ? 'active' : ''}`}
                                id="pills-viewpersonal-tab" data-bs-toggle="tab" data-bs-target="#pills-viewpersonal" aria-controls="pills-viewpersonal"
                                aria-selected={activeTab === 'viewpersonal'}
                                onClick={() => handleTabClick('viewpersonal')}
                                title='Personal Details'
                            >
                                <PiStudentLight />
                                {/* <img src={personalDetailsImg} width={24} height={24} /> */}
                                {/* <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M12 3C14.762 3 17 5.238 17 8C17 10.762 14.762 13 12 13C9.238 13 7 10.762 7 8C7 5.238 9.238 3 12 3" stroke="#7342FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /> <path d="M3 21C3.33333 19.6667 5 17 8 17H16C19 17 20.6667 19.6667 21 21" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /> </svg> */}
                                <div>
                                    <span className="steps">Step 1/5</span>{" "}
                                    Personal
                                </div>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className={`nav-link ${activeTab === 'viewaddress' ? 'active' : ''}`}
                                id="pills-viewaddress-tab" data-bs-toggle="tab" data-bs-target="#pills-viewaddress" aria-controls="pills-viewaddress"
                                aria-selected={activeTab === 'viewaddress'}
                                onClick={() => handleTabClick('viewaddress')}
                                title='Address Details'
                            >
                                <IoHomeOutline />
                                {/* <img src={addressDetailsImg} width={24} height={24} /> */}
                                <div>
                                    <span className="steps">Step 2/5</span>{" "}
                                    Address
                                </div>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link
                                className={`nav-link ${activeTab === 'viewreferences' ? 'active' : ''}`}
                                id="pills-viewreferences-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#pills-viewreferences"
                                aria-controls="pills-viewreferences"
                                aria-selected={activeTab === 'viewreferences'}
                                onClick={() => handleTabClick('viewreferences')}
                                title='Academic Details'
                            >
                                <img src={referencesDetailsImg} width={24} height={24} />
                                <div>
                                    <span className="steps">Step 3/5</span>{" "}
                                    Academic
                                </div>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link
                                className={`nav-link ${activeTab === 'viewemployment' ? 'active' : ''}`}
                                id="pills-viewemployment-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#pills-viewemployment"
                                aria-controls="pills-viewemployment"
                                aria-selected={activeTab === 'viewemployment'}
                                onClick={() => handleTabClick('viewemployment')}
                                title='Document Details'
                            >
                                {/* <img src={employmentDetailsImg} width={24} height={24} /> */}
                                <img src={documentDetailsImg} width={24} height={24} />
                                <div>
                                    <span className="steps">Step 4/5</span>{" "}
                                    Employment
                                </div>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link
                                className={`nav-link ${activeTab === 'viewdocuments' ? 'active' : ''}`}
                                id="pills-viewdocuments-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#pills-viewdocuments"
                                aria-controls="pills-viewdocuments"
                                aria-selected={activeTab === 'viewdocuments'}
                                onClick={() => handleTabClick('viewdocuments')}
                                title='Offer Letter Status'
                            >
                                {/* <img src={documentDetailsImg} width={24} height={24} /> */}
                                <img src={employmentDetailsImg} width={24} height={24} />
                                <div>
                                    <span className="steps">Step 5/5</span>{" "}
                                    Documents
                                </div>
                            </Link>
                        </li>
                    </ul>
                </div>
                {/* personal tab */}
                <div className="tab-content" id="pills-viewtabContent">
                    {activeTab === 'viewpersonal' && <ViewPersonalDetails setActiveTab={setActiveTab} />}
                    {activeTab === 'viewaddress' && <ViewAddressDetails setActiveTab={setActiveTab} />}
                    {activeTab === 'viewreferences' && <ViewReferenceDetails setActiveTab={setActiveTab} />}
                    {activeTab === 'viewemployment' && <ViewEmploymentDetails setActiveTab={setActiveTab} />}
                    {activeTab === 'viewdocuments' && <ViewDocumentsDetails setActiveTab={setActiveTab} />}
                </div>
            </div>
        </div>

    )
}

export default ViewApplicationForm