import React, { useEffect, useState } from 'react';
import setBodyColor from '../../../setBodyColor';
import { useDispatch, useSelector } from 'react-redux';
import { insertData, getData, updateData, getUploadLogo } from '../../../@redux/dispatch/customisation_dispatch';
import Preview from '../../../components/Preview';

function Themeing({ setActiveTab }) {
    const defaultHueVal = useSelector(state => state.fetch_dns?.fetch_dns_record?.hueVal)
    const userHueVal = useSelector(state => parseInt(state?.customisation?.get_data?.hueVal))

    const wholeData = useSelector((state) => (state?.customisation?.get_data))
    const brandName = useSelector((state) => (state?.customisation?.get_data?.brandName))
    const domainName = useSelector((state) => (state?.customisation?.get_data?.domainName))
    const logoUrl = useSelector((state) => (state?.customisation?.get_data?.logoUrl))

    const insertDataResponse = useSelector((state) => (state?.customisation?.insert_data_record?.status))

    // const imgUrl = useSelector(state => state.customisation?.logoUrlRes)
    const logo = useSelector((state) => (state?.customisation?.get_data?.logoUrl))
    console.log("image response for theming branding is here ", logo)

    const dispatch = useDispatch();
    console.log("get data response on theme page :- ", wholeData)
    console.log("brand name response on theme page :- ", brandName)
    console.log("domain name response on theme page :- ", domainName)
    console.log("user hue val is here ", userHueVal)

    console.log("hue value is", defaultHueVal)
    const [colors, setColors] = useState(() => {
        // const savedColors = JSON.parse(localStorage.getItem('themeColors'));
        const savedColors = ''
        return savedColors || {
            background: { hue: userHueVal || defaultHueVal || 256, saturation: 100, lightness: 50 }
        };
    });

    // useEffect(() => {
    //     dispatch(getData({ "domainName": domainName }))
    // }, []);

    useEffect(() => {
        console.log("My color I am getting is", colors);
        // localStorage.setItem('themeColors', JSON.stringify(colors));
        setBodyColor(colors.background.hue);
        // return () => {
        //     localStorage.removeItem("themeColors")
        // }


    }, [colors]);

    useEffect(() => {
        if (logo?.length !== 0) {
            dispatch(getUploadLogo('logo', logo))
        }

    }, [])


    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log("hue value on submit is", colors.background.hue.toString())
        dispatch(updateData({
            "domainName": domainName,
            "brandName": brandName,
            "hueVal": colors.background.hue.toString(),
            "logoUrl": logoUrl
        }, setActiveTab))
        // if (insertDataResponse === 'success') {
        //     await 
        // }
        // dispatch(getData({ "domainName": domainName }))
        // setActiveTab('portal')

    }

    const handleColorChange = (type, property, value) => {
        setColors(prevColors => ({
            ...prevColors,
            [type]: {
                ...prevColors[type],
                [property]: value
            }
        }));
        console.log("My color I am getting is", colors)
        // setBodyColor(colors.background.hue);
    };

    return (
        <div className='d-flex mt-5 ' style={{ height: '76vh' }}>
            <div className='col-4'>
                <div className=" p-0 ps-3 mx-auto shadow border rounded-3" >
                    <form className='p-4 m-3 ' onSubmit={handleSubmit}>
                        <div>
                            <h5 className='branding'>Background Color</h5>
                            <div style={{ lineHeight: '2rem', backgroundColor: `hsl(${colors.background.hue}, ${colors.background.saturation}%, ${colors.background.lightness}%)`, width: '100%' }}>
                                <p className='text-center' style={{ color: 'rgb(31, 45, 61)', fontSize: '0.8rem' }}>hsl({colors.background.hue})</p>
                            </div>
                            <div>
                                <div >
                                    <label className='form-check-label me-3'></label>
                                    <input onChange={(e) => handleColorChange('background', 'hue', parseInt(e.target.value))} type="range" value={colors.background.hue} min="0" max="360" className='w-100' />
                                    {/* <span className='ms-2'>({colors.background.hue})</span> */}
                                </div>

                            </div>
                            <button className='btn text-white border border-2 mt-5 w-100 saveChanges' type='submit'>Save </button>

                        </div>
                    </form>
                </div>
            </div>

            <div className='col-8  ms-2' >
                <Preview />
            </div>


        </div>

    );
}

export default Themeing;
