import React from 'react'
import { useSelector } from 'react-redux'

function ViewCounsellorStudentPersonalDetails({ setActiveTab }) {
    const personalData = useSelector((state) => state?.agentStudentDetails?.studentDetails?.data?.[0])
    console.log('View personal data from agent page are:-', personalData)
    return (
        <div className="tab-pane fade show active"
            id="pills-viewpersonal"
            role="tabpanel"
            aria-labelledby="pills-viewpersonal-tab">
            <form >

                <div className="form-area">
                    <div className="form-head">
                        Your Personal Details{" "}
                        <span>We will use your personal details while processing your applications</span>
                    </div>
                    <div className="form-subhead">

                    </div>
                    <div className="row clearfix">
                        <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                            <label className='form-check-label mb-2'>First name</label>
                            <input
                                type="text"
                                id='firstName'
                                name="firstName"
                                className="form-control"
                                placeholder="First name"
                                value={personalData?.First_Name}
                                disabled
                            />
                        </div>
                        <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                            <label className='form-check-label mb-2'>Last name</label>
                            <input
                                type="text"
                                id='lastName'
                                name="lastName"
                                className="form-control"
                                placeholder="Last name"
                                value={personalData?.Last_Name}
                                disabled
                            />
                        </div>
                        <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                            <label className='form-check-label mb-2' htmlFor='dateBirth'>Date of birth</label>
                            {/* <CustomDatePicker onChange={(val) => alert(val)} value={dayjs(personalDetails?.Date_of_Birth)} className="w-100 form-control" name="dateBirth" id="dateBirth" placeholder="date of birth" required /> */}
                            {/* <CustomDatePicker onChange={(val) => setPersonalDetails({ ...personalDetails, Date_of_Birth: dayjs.utc(val).tz('Asia/Kolkata') })} value={dayjs(personalDetails?.Date_of_Birth)} className="w-100 form-control" name="dateBirth" id="dateBirth" placeholder="date of birth" required /> */}
                            <input
                                type="date"
                                id='Date_of_Birth'
                                name="Date_of_Birth"
                                className="form-control"
                                placeholder="Date_of_Birth"
                                value={personalData?.Date_of_Birth}
                                disabled
                            // onChange={handleInputChange}
                            />
                        </div>
                        <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                            <label className='form-check-label mb-2'>Nationality</label>
                            <input
                                type="text"
                                id='Nationality'
                                name="Nationality"
                                className="form-control"
                                placeholder="Nationality"
                                value={personalData?.Nationality}
                                disabled
                            // onChange={handleInputChange}
                            />
                        </div>

                        <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                            <label className='form-check-label mb-2'>Email</label>
                            <input
                                type="email"
                                id='email'
                                name="email"
                                className="form-control"
                                placeholder="Enter your email"
                                value={personalData?.Email}
                                disabled
                            />
                        </div>

                        <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                            <label className='form-check-label mb-2'>Mobile number</label>
                            <div className="field-phone">
                                <span className="country-code">
                                    <img
                                        src="/images/icons/indian-flag.svg"
                                        className="img-fluid mCS_img_loaded"
                                        alt="Flag icon"
                                    />
                                    <p className='m-auto'>{personalData?.Phone?.slice(0, (personalData?.Phone?.length || 0) - 10)}</p>

                                    {/* <select
                                        name="ccode"
                                        id="ccode"
                                    >
                                        <option selected value={personalData?.Phone?.slice(0, (personalData?.Phone?.length || 0) - 10)}>{personalData?.Phone?.slice(0, (personalData?.Phone?.length || 0) - 10)}</option>
                                    </select> */}
                                </span>
                                <input
                                    type="tel"
                                    name="phone"
                                    id="phone"
                                    className="form-control"
                                    placeholder="Mobile Number"
                                    value={personalData?.Phone?.slice((personalData?.Phone?.length || 0) - 10)}
                                    disabled
                                />
                            </div>
                        </div>
                    </div>
                    <div className='container mt-5'>
                        <div className='row justify-content-end clearfix'>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 text-end p-4 form-bottom" >
                                <button className="btn btn-theme me-1" onClick={() => { setActiveTab("viewaddress") }}>Next</button>
                                {/* <button className="btn btn-theme" type='submit' >Update profile</button> */}
                            </div>
                        </div>
                    </div>


                </div>

            </form>

        </div>
    )
}

export default ViewCounsellorStudentPersonalDetails