import React from 'react'

function Sort() {
    return (
        <>
            <label
                htmlFor="sorting"
                className="col-form-label me-3"
            >
                Sort
            </label>
            <select
                className="select-filter"
                name="sorting"
                id="sorting"
            >
                <option value="relevance">relevance</option>
                <option value="popular-score">
                    popular score
                </option>
                <option value="ielts-score">ielts score</option>
            </select>
        </>
    )
}

export default Sort