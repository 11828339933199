import React from 'react'
import MobHomeHeader from '../../components/MobHomeHeader'
import HomeNavbar from '../../components/HomeNavbar'
import HomeMainSection from '../../components/HomeMainSection'
import HomeFooter from '../../components/HomeFooter'
import PrivacyMainSection from '../../components/PrivacyMainSection'



function Privacy() {
    return (
        <div>
            <MobHomeHeader />
            <HomeNavbar />
            <PrivacyMainSection />
            <HomeFooter />

        </div>
    )
}

export default Privacy