import React, { useEffect, useState } from 'react'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { MdDelete } from "react-icons/md";
import { useDispatch, useSelector } from 'react-redux';
import { createCustomTags, deleteCustomTags } from '../../../@redux/dispatch/template/customMergeTags';
import { useLocation } from 'react-router-dom';
import Loader from '../../Loader';


function MergeTagModal({ isOpen, closeModal, setInputOptions }) {
    const dispatch = useDispatch();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const course_id = location.state?.course_id;
    console.log("course_id is ", course_id)
    const [showInputField, setShowInputField] = useState(false)
    const defaultMergeTagsFields = useSelector((state) => state.getMergeTagsDefaults?.mergeTags?.fields)
    const customMergeTagsFields = useSelector((state) => state.customTags?.customMergeTags?.data?.fields)
    // console.log("Custom merge tags from apis for modal page are:-", customMergeTagsFields)
    console.log("Default merge tags from apis for modal page are :-", defaultMergeTagsFields)
    const [tags, setTags] = useState([]);
    const [savedTags, setSavedTags] = useState([]);

    useEffect(() => {
        console.log("Custom merge tags from apis for modal page are:-", customMergeTagsFields)
    }, [customMergeTagsFields])

    const addTag = () => {
        const newTag = { title: '', value: 'text', inputValue: null };
        console.log("adding tags are", newTag)
        setTags(prevTags => [...prevTags, newTag]);
    };

    const handleTagNameChange = (index, value) => {
        const updatedTags = [...tags];
        updatedTags[index].title = value;
        console.log("tag name changing is", updatedTags)
        setTags(updatedTags);
    };

    const handleTagTypeChange = (index, value) => {
        const updatedTags = [...tags];
        updatedTags[index].value = value;
        console.log("tag type changing is", updatedTags)
        setTags(updatedTags);
        setShowInputField(value === 'dropdown')
        if (value !== 'dropdown') {
            updatedTags[index].inputValue = null;
            setTags(updatedTags);
        }

    };

    const handleInputChange = (index, inputValue) => {
        const inputArray = inputValue.split(',').map(value => value.trim());
        console.log("Handle Input Change values are", inputArray)
        const updatedTags = [...tags];
        updatedTags[index].inputValue = inputValue;
        setTags(updatedTags);
    };

    const handleDeleteTag = (index, fieldName) => {
        const updatedTags = [...tags];
        updatedTags.splice(index, 1);
        setTags(updatedTags);
        dispatch(deleteCustomTags({ id: course_id, field_name: fieldName }))
        setShowInputField(false)
    };

    const saveTags = (e) => {

        e.preventDefault();
        console.log("only tags", tags)
        const normalizedCustomMergeTags = customMergeTagsFields.map(tag => ({
            title: tag.field_name,
            value: tag.data_type,
            inputValue: null // Assuming inputValue is always null initially for customMergeTagsFields
        }));

        // const updateingTags = tags.map(tag => ({ title: tag.value, value: tag.title, inputValue: tag.inputValue }));
        const updateingTags = tags.map(tag => ({ title: tag.title, value: tag.title, inputValue: tag.inputValue }));


        // Combine normalized arrays
        const allTags = [...normalizedCustomMergeTags, ...tags];

        // Now, create the fields array
        const fields = allTags.map(tag => ({
            "field_key": tag.title,
            "value_type": tag.value,
            "value": tag.value === 'dropdown' ? tag.inputValue : null
        }));


        console.log("Fields I am getting are", fields)
        // const requestBody = constructRequestBody();

        dispatch(createCustomTags({ id: course_id, data: { fields } }));

        // setInputOptions(tags);

        setInputOptions(updateingTags);
        console.log("custom input tags are", tags)

        // console.log("set input tags are", setInputOptions())
        closeModal();
    };

    return (
        <>
            {/* <Loader /> */}
            <Modal
                isOpen={isOpen}
                onRequestClose={closeModal}
                className="shadow-lg rounded-5 modal-xl "
                contentClassName="p-0">
                <ModalBody >
                    <div className='overflow-auto'>
                        <div>
                            <div className="modal-header">
                                <h4 className="modal-title">Manage merge tags</h4>
                                <button className="btn" onClick={closeModal}>
                                    &times;
                                </button>
                            </div>
                            <div className="modal-body" id="mergeTagDiv">
                                <div className="card">
                                    <div className="card-header">
                                        Available merge tags
                                    </div>
                                    <div className="card-body min-h-100 " id="availableTemplateMergeTags">
                                        <div className="accordion" id="mergeTagAccordion">
                                            <div className="accordion-item">
                                                <div className="accordion-header" id="headingOne">
                                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#DefaultCRMMergeTags" aria-expanded="true" aria-controls="DefaultCRMMergeTags">
                                                        Default CRM Merge Tags
                                                    </button>
                                                </div>
                                                <div id="DefaultCRMMergeTags" className="accordion-collapse collapse " aria-labelledby="headingOne" data-bs-parent="#mergeTagAccordion">
                                                    <div className="accordion-body">
                                                        <form id="defaultCRMTagForm">

                                                            {defaultMergeTagsFields?.map((defaultTags, index) => (
                                                                <div className="row" id="" key={index} >
                                                                    <div className="form-group col-sm-3">
                                                                        <input type="text" readonly className="form-control " value={defaultTags.field_name} name="Full_Name" id="Full_Name" placeholder="Full_Name" />
                                                                    </div>
                                                                    <div className="form-group col-sm-3">
                                                                        <input type="text" readonly className="form-control " value={defaultTags.data_type} name="Full_Name" id="mergeTagType_`+value.id+`" placeholder="merge_tags" />
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <div className="accordion-header" id="headingTwo">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#userDefinedMergeTags" aria-expanded="false" aria-controls="userDefinedMergeTags">
                                                        Template Merge Tags
                                                    </button>
                                                </div>
                                                <div id="userDefinedMergeTags" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#mergeTagAccordion">
                                                    <div className="accordion-body">
                                                        <form id="templateTagForm">
                                                            {customMergeTagsFields?.map((customTags, index) => (
                                                                <div className="row" id="" key={index} >
                                                                    <div className="form-group col-sm-3">
                                                                        <input type="text" disabled className="form-control " value={customTags.field_name} name="Full_Name" id="Full_Name" placeholder="Full_Name" />
                                                                    </div>
                                                                    <div className="form-group col-sm-3">
                                                                        <select class="form-control mergeTagType" source='crm' name="mergeTagType_`+value.id+`" target="dropDownValue_`+value.id+`" id="mergeTagType_`+value.id+`" >
                                                                            <option value=''>{customTags.data_type}</option>
                                                                            <option value="text">Text</option>
                                                                            <option value="dropdown">Drop down</option>
                                                                            <option value="date">Date</option>
                                                                        </select>
                                                                    </div>
                                                                    <div className="form-group col-sm-2 mt-3">
                                                                        <MdDelete size={30} onClick={() => handleDeleteTag(index, customTags.field_name)} />
                                                                    </div>
                                                                </div>
                                                            ))}
                                                            {tags.map((tag, index) => (
                                                                <div className="row" key={index}>
                                                                    <div className="form-group col-sm-3">
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            value={tag.title}
                                                                            placeholder="Tag Name"
                                                                            onChange={(e) => handleTagNameChange(index, e.target.value)}
                                                                        />
                                                                    </div>
                                                                    <div className="form-group col-sm-3">
                                                                        <select
                                                                            className="form-select mergeTagType"
                                                                            value={tag.value}
                                                                            onChange={(e) => handleTagTypeChange(index, e.target.value)}
                                                                        >
                                                                            <option value="text">Text</option>
                                                                            <option value="dropdown">Drop down</option>
                                                                            <option value="date">Date</option>
                                                                        </select>
                                                                    </div>
                                                                    <div className="form-group col-sm-4">
                                                                        {
                                                                            showInputField &&
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                placeholder="fields"
                                                                                value={tag.inputValue || ''}
                                                                                onChange={(e) => handleInputChange(index, e.target.value)}
                                                                            />
                                                                        }

                                                                    </div>
                                                                    <div className="form-group col-sm-2 mt-3">
                                                                        <MdDelete size={30} onClick={() => handleDeleteTag(index)} />
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button className="btn btn-theme text-right" id="addTagBtn" onClick={addTag}>
                                    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#fff" width="24px" height="24px"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill-rule="evenodd" clip-rule="evenodd" d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM12.75 9C12.75 8.58579 12.4142 8.25 12 8.25C11.5858 8.25 11.25 8.58579 11.25 9L11.25 11.25H9C8.58579 11.25 8.25 11.5858 8.25 12C8.25 12.4142 8.58579 12.75 9 12.75H11.25V15C11.25 15.4142 11.5858 15.75 12 15.75C12.4142 15.75 12.75 15.4142 12.75 15L12.75 12.75H15C15.4142 12.75 15.75 12.4142 15.75 12C15.75 11.5858 15.4142 11.25 15 11.25H12.75V9Z" fill="#1C274C"></path> </g></svg>
                                    Add Tag
                                </button>
                                <button className="btn btn-primary" onClick={saveTags}>
                                    Save Tags
                                </button>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </>
    )
}
export default MergeTagModal