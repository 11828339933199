import React, { useEffect, useState } from 'react';
import axios from "axios";
import ApplicationsListStudent from '../../components/ApplicationsListStudent';
import ApplicationsListAgent from '../../components/ApplicationsListAgent';
import ApplicationsListCouncellor from '../../components/ApplicationsListCouncellor';
import MobMenuHeader from '../../components/MobMenuHeader';
import UnivLeftSidebar from '../../components/UnivLeftSidebar';
import { useSelector } from 'react-redux';
// import './application.module.css'
function Applications() {

    const userRole = useSelector((state) => (state?.config?.profile_id))
    return (

        <>
            {userRole === "student" && (
                <ApplicationsListStudent />
            )}
            {userRole === "agent" && (
                <ApplicationsListAgent />
            )}
            {userRole === "counsellor" && (
                <ApplicationsListCouncellor />
            )}


        </>


    )
}

export default Applications