import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { FaRegShareFromSquare } from "react-icons/fa6";
import { AiOutlineHeart } from "react-icons/ai";
import { useDispatch, useSelector } from 'react-redux';
import { getCourses } from '../../@redux/dispatch/GetCoursesReducer';
import Skeleton from 'react-loading-skeleton';

function LoaderSkeleton() {
    return (
        <div>
            <div className='d-flex'>
                <div>
                    <Skeleton width={200} height={250} className='mb-4' />
                    <Skeleton width={200} height={70} className='mt-3' />
                </div>
                <div className='ms-4 mt-3'>
                    <Skeleton width={350} height={20} />
                    <Skeleton width={400} height={20} className='my-4 p-4' />
                    <Skeleton width={700} height={80} className='mt-5' />
                </div>
            </div>
            <div className='d-flex mt-5'>
                <div>
                    <Skeleton width={200} height={250} className='mb-4' />
                    <Skeleton width={200} height={70} className='mt-3' />
                </div>

                <div className='ms-4 mt-3'>
                    <Skeleton width={350} height={20} />
                    <Skeleton width={400} height={20} className='my-4 p-4' />
                    <Skeleton width={700} height={80} className='mt-5' />

                </div>


            </div>
        </div>
    )
}

function NoDataFound() {
    return (
        <div className='title text-center '>
            <h2 className='text-primary'>Oops! No courses are found.</h2>
        </div>
    )
}

function Courses({ searchData }) {
    const [showContent, setShowContent] = useState(false);
    const [isModalVisible, setModalVisible] = useState(false);
    const details = useSelector((state) => (state?.courseList?.courses?.data))
    const toggleContent = () => {
        setShowContent(!showContent);
    }
    const toggleModal = () => {
        setModalVisible(true);
    };
    return (
        details?.filter((data) => {
            return searchData.toLowerCase() === '' ?
                data : data?.Name.toLowerCase().includes(searchData);
        })?.map((data, val) => (
            <li key={data.id}>
                <div className="course-box clearfix">
                    <div className="row clearfix">
                        <div className="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-xxl-3">
                            <div className="course-imgholder tablet desktop">
                                <img src="images/dummy-image.png" alt="Image goes here" />
                                <div className="university-logo">
                                    <img src="images/course-university.png" alt="Image goes here" />
                                </div>
                            </div>
                            <ul className="score-box tablet desktop">
                                <li>
                                    IELTS Score <span>{data.ielts}</span>
                                </li>
                                <li>
                                    TOEFL <span>{data.toefl}</span>
                                </li>
                            </ul>
                        </div>
                        <div className="col-12 col-sm-8 col-md-8 col-lg-9 col-xl-9 col-xxl-9">
                            <div className="course-details">
                                <div className="title-area">
                                    <h2>{data.Name}</h2>
                                    <div className="desktop">
                                        <div className="action-tools">
                                            <Link to="#" className="btn-circle">
                                                <FaRegShareFromSquare size={16} />
                                            </Link>
                                            <Link to="#" className="btn-circle">
                                                <AiOutlineHeart size={16} />
                                            </Link>
                                            <Link to={"/create-application?course=" + data?.Name} className="btn btn-purple">
                                                Apply
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="tablet mobile">
                                        <div className="d-flex justify-content-start">
                                            <div className="toolbar">
                                                <a href="#" className="btn-circle">
                                                    <svg
                                                        width={14}
                                                        height={14}
                                                        viewBox="0 0 14 14"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            fillRule="evenodd"
                                                            clipRule="evenodd"
                                                            d="M7.94213 6.0297C5.64613 6.0297 3.60279 6.8257 2.61946 9.00036V8.3337C2.61946 5.0217 5.03413 3.03036 7.94213 3.03036V1.5917C7.94213 1.08836 8.53079 0.815696 8.91479 1.1417L12.4748 4.1637C12.7468 4.3937 12.7541 4.8117 12.4895 5.05103L8.92946 8.27836C8.55013 8.6217 7.94213 8.35303 7.94213 7.84103V6.0297Z"
                                                            stroke="#7342FF"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                        <path
                                                            d="M5.35026 1.00037H2.01693C1.28026 1.00037 0.683594 1.59703 0.683594 2.3337V11.667C0.683594 12.4037 1.28026 13.0004 2.01693 13.0004H11.3503C12.0869 13.0004 12.6836 12.4037 12.6836 11.667V9.00037"
                                                            stroke="#7342FF"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                    </svg>
                                                </a>
                                                <a href="#" className="btn-circle">
                                                    <svg
                                                        width={15}
                                                        height={14}
                                                        viewBox="0 0 15 14"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            fillRule="evenodd"
                                                            clipRule="evenodd"
                                                            d="M10.4243 1C12.7725 1 14.3503 3.235 14.3503 5.32C14.3503 9.5425 7.80212 13 7.6836 13C7.56509 13 1.01694 9.5425 1.01694 5.32C1.01694 3.235 2.59472 1 4.94286 1C6.29101 1 7.17249 1.6825 7.6836 2.2825C8.19472 1.6825 9.0762 1 10.4243 1Z"
                                                            stroke="#7342FF"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                    </svg>
                                                </a>
                                                <a href="form1" className="btn btn-purple">
                                                    Apply
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mobile">
                                        <ul className="score-box">
                                            <li>
                                                IELTS Score <span>{data.ielts}</span>
                                            </li>
                                            <li>
                                                TOEFL <span>{data.toefl}</span>
                                            </li>
                                        </ul>
                                    </div>
                                    {/* <p className="course-text content-desktop">
                            <span>About the Course</span>
                            {data.Course_Description}
                            <span>English Requirement</span>
                            {data.Requirement}
                        </p> */}
                                    {/* <p className={`course-text ${showContent ? 'visible' : 'hidden'}`}>
                            <span>About the Course</span>
                            {data.aboutCourse}
                            <span>English Requirement</span>
                            {data.requirement}
                        </p> */}
                                    <a
                                        href="javascript:void(0)"
                                        className="btn-more readMore d-md-none d-lg-none d-xl-none d-xxl-none"
                                        onClick={toggleContent}
                                    >
                                        {showContent ? 'Less Details' : 'More Details'}
                                        <i className="bi bi-chevron-down" />
                                    </a>
                                </div>
                                <div className="info-area">
                                    <h3>
                                        {data?.University?.name}
                                        <span>
                                            {`${data.Course_Level} | ${data.Duration}`}
                                        </span>
                                    </h3>
                                </div>
                                <div className="tablet mobile">
                                    <div className="d-flex justify-content-start">
                                        <div className="toolbar">
                                            <Link to="#" className="btn-circle">
                                                <FaRegShareFromSquare />
                                            </Link>
                                            <Link to="#" className="btn-circle">
                                                <AiOutlineHeart />
                                            </Link>
                                            <Link to="form1" className="btn btn-purple">
                                                Apply
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="mobile">
                                    <ul className="score-box">
                                        <li>
                                            IELTS Score <span>{data.ielts}</span>
                                        </li>
                                        <li>
                                            TOEFL <span>{data.toefl}</span>
                                        </li>
                                    </ul>
                                </div>
                                <p className="course-text content-desktop">
                                    <span>About the Course</span>
                                    {data.Course_Description}
                                    <span>English Requirement</span>
                                    {data.Requirement}
                                </p>
                                {/* <p className={`course-text ${showContent ? 'visible' : 'hidden'}`}>
                        <span>About the Course</span>
                        {data.aboutCourse}
                        <span>English Requirement</span>
                        {data.requirement}
                    </p> */}
                                <Link
                                    href="javascript:void(0)"
                                    className="btn-more readMore d-md-none d-lg-none d-xl-none d-xxl-none"
                                    onClick={toggleContent}
                                >
                                    {showContent ? 'Less Details' : 'More Details'}
                                    <i className="bi bi-chevron-down" />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </li>



        ))
    )
}
function CourseListStudent({ searchData }) {
    const dispatch = useDispatch();
    const loading = useSelector((state) => (state?.courseList?.loading))
    const details = useSelector((state) => (state?.courseList?.courses?.data))
    const courseList = useSelector((state) => (state?.courseList?.courses))

    console.log("My loading is ", loading)
    useEffect(() => {
        dispatch(getCourses())
    }, [])
    return (
        <ul className="iscourse-grid courseScroll" id="courseGrid">
            {(loading && courseList?.length === 0) && <LoaderSkeleton />}
            {(!loading && details?.length === undefined) && <NoDataFound />}
            {details?.length > 0 && <Courses searchData={searchData} />}
        </ul>
    )
}
export default CourseListStudent