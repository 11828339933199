import axios from "axios";
import { applicationStart, createNewApplicationFailure, createNewApplicationStart, createNewApplicationSuccess, setCourseIdForNewApplication } from "../../actions/applications/CreateApplication";
import { closeLoader, openLoader } from "../../actions/config";
import apiUrl from "../../ApiUrl";

export const createNewApplication = (body) => async (dispatch, getState) => {
    dispatch(applicationStart());
    dispatch(openLoader())
    const baseUrl = getState().config.baseUrl
    try {

        const response = await axios.post(`${apiUrl}/api/application/create`, body);
        if (response) {
            console.log(response.data, 76)
            // alert("Application submitted successfully")
        } else {
            throw new Error('No data received from the server');
        }
    }
    catch (error) {
        console.log("error is here:- ", error);
    }
    finally {
        dispatch(closeLoader())
    }
};