import React from 'react'
import { AiOutlineBank } from 'react-icons/ai'
import { CgNotes } from 'react-icons/cg'
import { MdPayments } from 'react-icons/md'
import { PiPresentationBold } from 'react-icons/pi'
import { RiUser3Line } from 'react-icons/ri'
import { RxDashboard } from 'react-icons/rx'
import { Link, NavLink } from 'react-router-dom'

function EdpaySidebar() {
    return (
        <nav id="" className="col-md-3 col-lg-2 col-xl-2 col-xxl-2 sidebar desktop tablet overflow-auto ">
            <div className="position-sticky pt-3 sidebar-sticky autoScroll">
                <div className="side-header">
                    <Link to="/">
                        <img
                            src="/images/logo.svg"
                            // src='../../../public/images/logo.svg'
                            // src={Logo}
                            className="img-fluid mx-auto"
                            alt="logo"
                        />
                    </Link>
                    <div className="avatar"
                    //  onClick={changeColor}
                    >
                        <img src="/images/profile-pic.png" alt="Image goes here" />
                        {/* {console.log("univerLeftUserdata who is here2:", userData?.data?.[0]?.profile)}
                        {console.log("Agent Name :", userData?.data?.['0']?.Vendor_Name)}
                        {((userData?.data?.length > 0) && (userData?.data['0']?.profile == "student"))
                            ?
                            <p className="username">
                                {userData?.data?.length > 0 && userData?.data['0']?.Full_Name}
                            </p>
                            :
                            (
                                (userData?.data?.length > 0 && userData?.data['0']?.profile == "counsellor")
                                    ?
                                    <p className="username">{userData?.data?.length > 0 && userData?.data['0']?.Name}</p>
                                    :
                                    <p className="username">{userData?.data?.length > 0 && userData?.data['0']?.Vendor_Name}</p>)
                        } */}
                    </div>
                </div>
                <ul className="nav flex-column">
                    <li className="nav-item">
                        <NavLink className="nav-link " to="/dashboard" activeClassName="active ">
                            <RxDashboard /> Dashboard
                        </NavLink>
                    </li>

                    <li className="nav-item">
                        <NavLink className="nav-link" to="/applications" activeClassName="active">
                            <CgNotes /> Applications
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink className="nav-link" to="/courses" activeClassName="active">
                            <PiPresentationBold /> Courses
                        </NavLink>
                    </li>
                    <li className="nav-item" >
                        <NavLink className="nav-link " to="/universities" activeClassName="active">
                            <AiOutlineBank /> Universities
                        </NavLink>
                    </li>
                    <hr />
                    <li className="nav-item">
                        <NavLink className="nav-link" to="/profile" activeClassName="active">
                            <RiUser3Line /> Profile
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink className="nav-link" to="/edpay" activeClassName="active">
                            <MdPayments /> EdPay
                        </NavLink>
                    </li>

                </ul>
            </div>

        </nav>
    )
}

export default EdpaySidebar