import React, { useEffect, useState } from 'react';
import { MdArrowOutward } from "react-icons/md";
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Application1Modal from '../Modals/Application1Modal';
import ToastContainer from '../ToastContainer';
import { applicationDetails } from '../../@redux/dispatch/applications/GetApplicationDetailsReducer';
import { getStudentDetails } from '../../@redux/dispatch/students/getDetails';
import Breadcrump from '../Structures/Breadcrump/Breadcrump';
import { getReleasedLetter } from '../../@redux/dispatch/Generate_offer_letter_dispatch/getReleasedOfferLetter';
import Skeleton from 'react-loading-skeleton'
import { getApplications } from '../../@redux/dispatch/applications/GetApplicationsReducer';

function LoaderSkeleton() {
    return (
        <Skeleton count={10} className='py-3 my-1' />
    )
}

function NoDataFound() {
    return (
        <div className='title text-center '>
            <h2 className='text-primary'>Oops! It seems like no applications are submitted . Apply using new application button ! <MdArrowOutward size={100} className='text-danger' /></h2>
        </div>
    )
}

function ApplicationList({ perPage }) {
    const data = useSelector((state) => (state?.applicationList?.applicationsList?.data))
    const [currentPage, setCurrentPage] = useState(1);
    const recordsPerPage = perPage;
    const lastIndex = currentPage * recordsPerPage;
    const firstIndex = lastIndex - recordsPerPage;
    const records = data.slice(firstIndex, lastIndex);
    const nPage = Math.ceil(data.length / recordsPerPage);

    const prevPage = () => {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1);
        }
    }

    const nextPage = () => {
        if (currentPage !== nPage) {
            setCurrentPage(currentPage + 1);
        }
    }

    const changeCPage = (Id) => {
        setCurrentPage(Id)
    }

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleViewBtn = (e, application_id) => {
        e.preventDefault()
        dispatch(getReleasedLetter({ application_id: application_id }))
    }
    const handleRowClick = (e, Id) => {
        e.preventDefault();
        dispatch(applicationDetails(Id))
        navigate("/view-application?application=" + Id)
    }
    return (
        <div className='position-relative'>
            <table className="table table-borderless course-table w-100 responsive-tables">
                <thead>
                    <tr>
                        <th>Course Name</th>
                        <th>Country</th>
                        <th>Duration</th>
                        <th>Level</th>
                        <th>Status</th>
                        <th>Offer Letter</th>
                    </tr>
                </thead>
                <tbody>
                    {records?.map((student) => (
                        <tr className='' key={data.id} >
                            <td data-title="Course" role='button' onClick={(e) => { handleRowClick(e, student?.id) }}>
                                {student?.Select_Program?.name}
                                <span>{student.University_Name}</span>
                            </td>
                            <td data-title="Country" role='button' onClick={(e) => { handleRowClick(e, student?.id) }}>
                                {student.Country}
                            </td>
                            <td data-title="Duration" role='button' onClick={(e) => { handleRowClick(e, student?.id) }}>{student.Course_Duration}</td>
                            <td data-title="Level" role='button' onClick={(e) => { handleRowClick(e, student?.id) }}>{student.Course_Level}</td>
                            <td data-title="Status" role='button' onClick={(e) => { handleRowClick(e, student?.id) }}>{student.Stage}</td>

                            <td data-title="OfferLetter" className=''>
                                {student.Offer_Letter_Student !== null ? (
                                    <button
                                        className="btn btn-purple"
                                        onClick={(e) => handleViewBtn(e, student?.id)}
                                    >
                                        View
                                    </button>
                                ) : (
                                    <button className="btn btn-purple disabled" disabled>
                                        View
                                    </button>
                                )}
                            </td>
                        </tr>
                    ))
                    }
                </tbody>
            </table>

            {
                data?.length > 4 ?
                    <nav className=" position-absolute  start-50 translate-middle-x mb-2" style={{ bottom: '-70px' }}>
                        <ul class="pagination">
                            <li class="page-item">
                                <a class="page-link" href="#" aria-label="Previous" onClick={prevPage}>
                                    <span aria-hidden="true">&laquo;</span>
                                </a>
                            </li>
                            {/* <li class="page-item"><a class="page-link" href="#" onClick={() => changeCPage(1)}>1</a></li> */}
                            {/* <li class="page-item"><a class="page-link" href="#" onClick={() => changeCPage(2)}>2</a></li> */}
                            <li class="page-item">
                                <a class="page-link" href="#" aria-label="Next" onClick={nextPage}>
                                    <span aria-hidden="true">&raquo;</span>
                                </a>
                            </li>
                        </ul>
                    </nav>

                    :
                    null
            }


        </div>


    )
}

function ApplicationsListStudent() {
    const [recordsPerPage, setRecordsPerPage] = useState(5)
    const data = useSelector((state) => (state?.applicationList?.applicationsList?.data))
    const noList = useSelector((state) => (state?.applicationList?.applicationsList))
    const loading = useSelector((state) => (state?.applicationList?.loading))
    const dispatch = useDispatch();
    const breadcrumps = [
        { text: 'Home', path: '/dashboard' },
        { text: 'All Applications', path: '/applications' }
    ]

    useEffect(() => {
        dispatch(getApplications());
        dispatch(getStudentDetails())
    }, []);

    const handleRecordsChanges = (e) => {
        setRecordsPerPage(parseInt(e.target.value, 10));
    }
    return (
        <>
            <div className="col-xl-10 col-xxl-10 col-md-9 ms-sm-auto col-lg-12">
                <div className="course-grid ">
                    <div className="desktop tablet">
                        <div className="fixed-header sticky-top w-100 clearfix">
                            <Breadcrump items={breadcrumps} />
                            <div className="row align-items-center clearfix">
                                <div className="col-9 col-lg-6">
                                    <h5 className="course-title">Your Applications {(data?.length)} </h5>
                                </div>
                                <div className="col-12 col-lg-3">
                                    <div className="desktop">
                                        <label
                                            htmlFor="records"
                                            className="col-form-label me-3"
                                        >
                                            Records Per Page
                                        </label>
                                        <select
                                            className="select-filter"
                                            name="records"
                                            id="records"
                                            onChange={handleRecordsChanges}
                                        >
                                            <option value={5}>5</option>
                                            <option value={10}>
                                                10
                                            </option>
                                            <option value={15}>15</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-5 col-lg-3 text-end">
                                    <Link to="/create-application" className="btn btn-purple" >
                                        New Application
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center mobile mb-3 clearfix">
                        <div className="col-8">
                            <h5 className="course-title-mobile">Your Applications</h5>
                        </div>
                        <div className="col-4 text-end">
                            <Link
                                to="/create-application"
                                className="btn btn-purple"
                            >
                                New
                            </Link>
                        </div>
                    </div>
                    <div className="box-white applicationScroll">
                        {loading && <LoaderSkeleton />}
                        {/* {(!loading && noList?.length === 0) && <NoDataFound />} */}
                        {(!loading && data?.length > 0) ?
                            <ApplicationList perPage={recordsPerPage} /> :
                            <NoDataFound />
                        }
                        {/* {(!loading && data?.length > 0) && <ApplicationList />} */}
                    </div>
                </div>
                <ul className="bottom-menu">
                    <li>
                        <Link to="/dashboard">
                            <i className="bi bi-house-fill" />{" "}
                            Home
                        </Link>
                    </li>
                    <li>
                        <Link to="#" id="search-icon">
                            <i className="bi bi-search" />
                            Search
                        </Link>
                    </li>
                    <li>
                        <Link to="/profile">
                            <i className="bi bi-person-fill" />
                            Profile
                        </Link>
                    </li>
                    <li>
                        <Link
                            // data-bs-toggle="offcanvas"
                            to="#mobileMenu"
                            role="button"
                        // aria-controls="mobileMenuExample"
                        >
                            <i className="bi bi-list" />
                            Menu
                        </Link>
                    </li>
                </ul>
            </div>
            <Application1Modal />
            <ToastContainer />
        </>

    )
}

export default ApplicationsListStudent