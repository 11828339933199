import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    univeristy_list: {},
    error: false,
    university_details: {}
}

export const universitySlice = createSlice({
    name: 'university',
    initialState,
    reducers: {
        universityList: (state, action) => {
            return { ...state, univeristy_list: action.payload };
        },
        universityDetails: (state, action) => {
            return { ...state, university_details: action.payload }
        }
    }
})

export const { universityList, universityDetails } = universitySlice.actions;

export default universitySlice.reducer;
