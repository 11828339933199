import React, { useEffect } from 'react'
import MobHomeHeader from '../../components/MobHomeHeader'
import HomeNavbar from '../../components/HomeNavbar'
import HomeMainSection from '../../components/HomeMainSection'
import HomeFooter from '../../components/HomeFooter'
import { useDispatch } from 'react-redux'
import { setActiveTab } from '../../@redux/actions/config'

function StudentHomePage() {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setActiveTab('student'))
    }, [])
    return (
        <>
            <MobHomeHeader />

            <HomeNavbar />
            <HomeMainSection
                title="Fulfil your dreams to study in the UK, US and Australia"
                subtitle="Explore, prepare, apply. Ed will help you at each step. We make it simple for you!"
            />
            <HomeFooter />
        </>
    )
}

export default StudentHomePage