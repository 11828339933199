import React from 'react'

function MobSortbyModal() {
    return (
        <div className='overflow-auto'>
            <div className="modal-header border border-0 ">
                <button
                    type="button"
                    className="btn-close"
                // onClick={() => setFilterModalOpen(false)}
                />
            </div>
            <div className='modal-body'>
                <div className="row mb-3 clearfix">
                    <label htmlFor="sorting" className="col-4 col-sm-5 col-form-label">
                        Sort by
                    </label>
                    <div className="col-8 col-sm-7">
                        <select className="form-select" name="sorting" id="sorting">
                            <option value="relevance">Relevance</option>
                            <option value="relevance">Relevance</option>
                            <option value="relevance">Relevance</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>


    )
}

export default MobSortbyModal