import React from 'react'
import { Link } from 'react-router-dom'

function BottomMenubar() {
    return (

        <ul className="bottom-menu">
            <li>
                <Link to="/dashboard">
                    <i className="bi bi-house-fill" />
                    Home
                </Link>
            </li>
            <li>
                <Link to="#" id="search-icon">
                    <i className="bi bi-search" />
                    Search
                </Link>
            </li>
            <li>
                <Link to="/profile">
                    <i className="bi bi-person-fill" />
                    Profile
                </Link>
            </li>
            <li>
                <a
                    data-bs-toggle="offcanvas"
                    href="#mobileMenu"
                    role="button"
                    aria-controls="mobileMenuExample"
                >
                    <i className="bi bi-list" />
                    Menu
                </a>
            </li>
        </ul>

    )
}

export default BottomMenubar