import React from 'react'
import ChatBot from 'react-simple-chatbot';
import { ThemeProvider } from 'styled-components';

const theme = {
    background: ' #e6e6e6',
    fontFamily: 'Helvetica Neue',
    headerBgColor: '#EF6C00',
    headerFontColor: '#fff',
    headerFontSize: '15px',
    botBubbleColor: '#EF6C00',
    botFontColor: '#fff',
    userBubbleColor: '#fff',
    userFontColor: '#4a4a4a',
};

function ChatAI() {

    return (
        <div className='chatbot-container' style={{ margin: '0 auto', width: '75%', }}>
            <ThemeProvider theme={theme}>
                <ChatBot
                    width="100%"
                    headerTitle="Hello!"

                    steps={[
                        {
                            id: 'Greet',
                            message: 'Welcome to the chat!',
                            trigger: 'Ask Name',
                        },
                        {
                            id: 'Ask Name',
                            message: 'How can I help you today?',
                            trigger: '3',
                        },
                        {
                            id: '3',
                            user: true,
                            trigger: '4',
                        },
                        {
                            id: '4',
                            message: 'You said "{previousValue}".',
                            end: true,
                        },
                    ]}
                />
            </ThemeProvider>

        </div>



    )
}

export default ChatAI