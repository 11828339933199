import React from 'react'
import { AiOutlineBank } from 'react-icons/ai'
import { CgNotes } from 'react-icons/cg'
import { PiPresentationBold, PiStudent } from 'react-icons/pi'
import { RiUser3Line } from 'react-icons/ri'
import { RxDashboard } from 'react-icons/rx'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

function Preview() {
    const imgUrl = useSelector(state => state.customisation.logoUrlRes)
    return (
        <div className='shadow border rounded-3 h-auto' style={{ overflowY: 'auto' }}>
            <div className=' '>
                <nav className='d-flex justify-content-between border p-3 sticky-top bg-white' style={{ zIndex: '1000' }}>
                    <div>
                        <img src={imgUrl?.logo || '/images/logo.svg'} width={151} height={24} />
                    </div>
                    <button className='btn btn-purple'>
                        Submit
                    </button>
                </nav>
                <div className='d-flex'>
                    <div className='col-3 border border-right-2 preview px-2 py-2'>
                        <ul className="nav flex-column">
                            <li className="nav-item mb-3">
                                <Link className="nav-link  lh-md fs-6 fw-bold temp  " aria-current='page' >
                                    <RxDashboard /> Dashboard
                                </Link>
                            </li>

                            <li className="nav-item mb-3">
                                <Link className="nav-link lh-md fs-6 fw-bold"  >
                                    <PiPresentationBold /> Students
                                </Link>
                            </li>
                            <li className="nav-item mb-3">
                                <Link className="nav-link lh-md fs-6 fw-bold" >
                                    <AiOutlineBank /> Applications
                                </Link>
                            </li>
                            <li className="nav-item mb-3">
                                <Link className="nav-link lh-md fs-6 fw-bold "  >
                                    <PiStudent /> Courses
                                </Link>
                            </li>
                            <li className="nav-item mb-3">
                                <Link className="nav-link lh-md fs-6 fw-bold" >
                                    <CgNotes /> Profile
                                </Link>
                            </li>

                            <li className="nav-item mb-3">
                                <Link className="nav-link lh-md fs-6 fw-bold active rounded-3"  >
                                    <PiPresentationBold /> Settings
                                </Link>
                            </li>


                            <li className="nav-item mb-3">
                                <Link className="nav-link lh-md fs-6 fw-bold"  >
                                    <RiUser3Line /> Logout
                                </Link>
                            </li>
                        </ul>

                    </div>
                    <div className='col-9'>
                        <div className='p-3'>
                            <div className='d-flex justify-content-between mb-2'>
                                <h3>LOREM IPSUM</h3>
                                <div className='toolbar m-0'>
                                    <a href="#" className="btn-circle">
                                        <svg
                                            width={14}
                                            height={14}
                                            viewBox="0 0 14 14"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M7.94213 6.0297C5.64613 6.0297 3.60279 6.8257 2.61946 9.00036V8.3337C2.61946 5.0217 5.03413 3.03036 7.94213 3.03036V1.5917C7.94213 1.08836 8.53079 0.815696 8.91479 1.1417L12.4748 4.1637C12.7468 4.3937 12.7541 4.8117 12.4895 5.05103L8.92946 8.27836C8.55013 8.6217 7.94213 8.35303 7.94213 7.84103V6.0297Z"
                                                stroke="#7342FF"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                            <path
                                                d="M5.35026 1.00037H2.01693C1.28026 1.00037 0.683594 1.59703 0.683594 2.3337V11.667C0.683594 12.4037 1.28026 13.0004 2.01693 13.0004H11.3503C12.0869 13.0004 12.6836 12.4037 12.6836 11.667V9.00037"
                                                stroke="#7342FF"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                    </a>
                                    <a href="#" className="btn-circle">
                                        <svg
                                            width={15}
                                            height={14}
                                            viewBox="0 0 15 14"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M10.4243 1C12.7725 1 14.3503 3.235 14.3503 5.32C14.3503 9.5425 7.80212 13 7.6836 13C7.56509 13 1.01694 9.5425 1.01694 5.32C1.01694 3.235 2.59472 1 4.94286 1C6.29101 1 7.17249 1.6825 7.6836 2.2825C8.19472 1.6825 9.0762 1 10.4243 1Z"
                                                stroke="#7342FF"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                    </a>
                                </div>
                            </div>

                            <p>
                                Lorem ipsum dolor sit amet. Ut voluptatem Quis id sint impedit et rerum distinctio aut eveniet voluptatem nam laudantium repellendus cum alias asperiores vel temporibus laboriosam! Sed ullam sunt est dolor porro et dolore cumque.
                            </p>
                            <span>About the Course</span>
                            orem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore
                            et dolore magna aliqua. Ut enim ad minim veniam,
                            quis nostrud exercitation ullamco laboris nisi ut
                            aliquip ex ea co.


                        </div>
                        <div className='p-3'>
                            <table className="table borderless course-data ">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Duration</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            MGM Global Data Management Science
                                            <span>Oxford University</span>
                                        </td>
                                        <td>2 Years</td>
                                        <td className="">
                                            <button className='btn btn-purple'>
                                                Apply
                                            </button>


                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>




            </div>
        </div>

    )
}

export default Preview