import axios from "axios";
import { getDetailsStart, getDetailsSuccess } from "../../actions/students/getDetails";
import apiUrl from "../../ApiUrl";

export const getStudentDetails = () => async (dispatch, getState) => {
    const baseUrl = getState().config.baseUrl
    dispatch(getDetailsStart());
    try {
        const response = await axios.get(

            `${apiUrl}/api/profile/details`
        );
        // if(response)
        dispatch(getDetailsSuccess(response.data?.data))
    } catch (error) {
        console.log(error)
    }
};



