let apiUrl;
const domainName = window.location.host;

if (domainName === 'localhost:3000') {
    apiUrl = 'https://developer.sandbox.edbucket.com'
}
else if (domainName === 'webapp-sandbox.edbucket.com') {
    apiUrl = 'https://developer.sandbox.edbucket.com'
}
else {
    apiUrl = 'https://developer.edbucket.com'
}

// else if (domainName === 'webapp-sandbox.edbucket.com') {
//     apiUrl = 'https://api-sandbox.edbucket.com'
// }
// else {
//     apiUrl = 'https://api-dev.edbucket.com'
// }

export default apiUrl;