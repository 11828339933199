import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    // isAgents: false,
    agentStudentMessage: "",
    error: false,
    // studentDetails: []
}

export const updateAgentStudentDetailsSlice = createSlice({
    name: 'updateAgentStudentDetails',
    initialState,
    reducers: {
        updateAgentStudentDetailsStart: (state) => {
            return { ...state, agentStudentMessage: "Updating agent's student details started ...", error: false };
        },
        updateAgentStudentDetailsSuccess: (state, action) => {
            return { ...state, agentStudentMessage: "Agent's student details updated successfully", error: false };
        }

    }
})

export const { updateAgentStudentDetailsStart, updateAgentStudentDetailsSuccess } = updateAgentStudentDetailsSlice.actions;

export default updateAgentStudentDetailsSlice.reducer;