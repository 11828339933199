import axios from "axios";
import { agentApplicationStart } from "../../../actions/agents/agentApplication/agentCreateApplication";
import { closeLoader, openLoader } from "../../../actions/config";
import apiUrl from "../../../ApiUrl";

export const agentCreateNewApplication = (body, setActiveTab) => async (dispatch, getState) => {
    const baseUrl = getState().config.baseUrl
    dispatch(agentApplicationStart())
    dispatch(openLoader());
    try {

        const response = await axios.post(`${apiUrl}/api/agent/create/application/${body.id}`, body.formData);
        if (response) {
            console.log(response.data, 76)
            alert("Application saved successfully")
            setActiveTab('documents')
        } else {
            throw new Error('No data received from the server');
        }
    }
    catch (error) {
        console.log("error is here:- ", error);
    }
    finally {
        dispatch(closeLoader())
    }
};