import React from 'react'

function CounsellorStudentInfo() {
    return (
        <table className='table table-bordered student-data text-center'>
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone</th>
                </tr>
            </thead>
            <tbody>
                {/* {data?.map((student, index) => (
                    <tr key={index} role='button' onClick={() => { handleRowClick(student?.id) }}>
                        <td data-title="Name" className='text-nowrap'> {student?.Full_Name}</td>
                        <td data-title="Email" className='text-nowrap'>{student?.Email}</td>
                        <td data-title="Phone" className='text-nowrap'>{student?.Phone}</td>

                    </tr>
                ))} */}

            </tbody>
        </table>
    )
}

export default CounsellorStudentInfo