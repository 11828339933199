
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import PersonalDetailsForm from '../Structures/PersonalDetailsForm';
import ReferenceDetailsForm from '../Structures/ReferenceDetailsForm';
import EmploymentDetailsForm from '../Structures/EmploymentDetailsForm';
import DocumentsDetailsForm from '../Structures/DocumentsDetailsForm';
import AddressDetailsForm from '../Structures/AddressDetailsForm';
import addressDetailsImg from '../../assets/images/createApplicationTab/AddressDetailsImg.svg'
import referencesDetailsImg from '../../assets/images/createApplicationTab/ReferencesDetailsImg.svg'
import employmentDetailsImg from '../../assets/images/createApplicationTab/EmploymentDetailsImg.svg'
import documentDetailsImg from '../../assets/images/createApplicationTab/DocumentDetailsImg.svg'
import { useDispatch, useSelector } from 'react-redux';
import { getMetaFields } from '../../@redux/dispatch/applications/MetaDataReducer';
import { applicationStart } from '../../@redux/actions/applications/CreateApplication';
import { uploadFilesStart } from '../../@redux/actions/uploadFiles/uploads';
import { PiStudentLight } from 'react-icons/pi';
import { IoHomeOutline } from 'react-icons/io5';
function ApplicationForm() {
    const [activeTab, setActiveTab] = useState('personal');
    const data = useSelector((state) => state.studentsDetails)
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(applicationStart())
        dispatch(uploadFilesStart())
        dispatch(getMetaFields())
    }, [])
    return (

        <div className="row clearfix">
            <div className="col-12">
                <div className="form-tab-container">
                    <ul className="nav nav-pills form-nav" id="form-tab" role="tablist" >
                        <li className="nav-item">
                            <Link disabled={true} className={`nav-link ${activeTab === 'personal' ? 'active' : ''}`}
                                id="pills-personal-tab" data-bs-toggle="tab" data-bs-target="#pills-personal" aria-controls="pills-personal"
                                aria-selected={activeTab === 'personal'}
                                title='Personal Details'
                            // onClick={() => handleTabClick('personal')}
                            >
                                {/* <img src={personalDetailsImg} width={24} height={24} /> */}
                                <PiStudentLight />
                                {/* <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M12 3C14.762 3 17 5.238 17 8C17 10.762 14.762 13 12 13C9.238 13 7 10.762 7 8C7 5.238 9.238 3 12 3" stroke="#7342FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /> <path d="M3 21C3.33333 19.6667 5 17 8 17H16C19 17 20.6667 19.6667 21 21" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /> </svg> */}
                                <div>
                                    <span className="steps">Step 1/5</span>{" "}
                                    Personal
                                </div>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link disabled={true} className={`nav-link ${activeTab === 'address' ? 'active' : ''}`}
                                id="pills-address-tab" data-bs-toggle="tab" data-bs-target="#pills-address" aria-controls="pills-address"
                                aria-selected={activeTab === 'address'}
                                title='Address Details'
                            // onClick={() => handleTabClick('address')}
                            >
                                <IoHomeOutline />
                                {/* <img src={addressDetailsImg} alt='address icon' width={24} height={24} /> */}
                                <div>
                                    <span className="steps">Step 2/5</span>{" "}
                                    Address
                                </div>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link
                                disabled={true} className={`nav-link ${activeTab === 'references' ? 'active' : ''}`}
                                id="pills-references-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#pills-references"
                                aria-controls="pills-references"
                                aria-selected={activeTab === 'references'}
                                title='Academic Details'
                            // onClick={() => handleTabClick('references')}
                            >
                                <img src={referencesDetailsImg} alt='references icon' width={24} height={24} />
                                <div>
                                    <span className="steps">Step 3/5</span>{" "}
                                    Academic
                                </div>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link
                                disabled={true} className={`nav-link ${activeTab === 'employment' ? 'active' : ''}`}
                                id="pills-employment-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#pills-employment"
                                aria-controls="pills-employment"
                                aria-selected={activeTab === 'employment'}
                                title='Document Details'
                            // onClick={() => handleTabClick('employment')}
                            >
                                {/* <img src={employmentDetailsImg} alt='employment icon' width={24} height={24} /> 
                                */}
                                <img src={documentDetailsImg} width={24} height={24} />
                                <div>
                                    <span className="steps">Step 4/5</span>{" "}
                                    Employment
                                </div>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link
                                disabled={true} className={`nav-link ${activeTab === 'documents' ? 'active' : ''}`}
                                id="pills-documents-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#pills-documents"
                                aria-controls="pills-documents"
                                aria-selected={activeTab === 'documents'}
                                title='Offer Letter Status '
                            // onClick={() => handleTabClick('documents')}
                            >
                                {/* <img src={documentDetailsImg} alt='document icon' width={24} height={24} /> */}
                                <img src={employmentDetailsImg} width={24} height={24} />
                                <div>
                                    <span className="steps">Step 5/5</span>{" "}
                                    Documents
                                </div>
                            </Link>
                        </li>
                    </ul>
                </div>
                {/* personal tab */}
                <div className="tab-content" id="pills-tabContent">
                    {activeTab === 'personal' && <PersonalDetailsForm setActiveTab={setActiveTab} studentDetails={data.studentDetails} />}
                    {activeTab === 'address' && <AddressDetailsForm setActiveTab={setActiveTab} studentDetails={data.studentDetails} />}
                    {activeTab === 'references' && <ReferenceDetailsForm setActiveTab={setActiveTab} />}
                    {activeTab === 'employment' && <EmploymentDetailsForm setActiveTab={setActiveTab} />}
                    {activeTab === 'documents' && <DocumentsDetailsForm setActiveTab={setActiveTab} />}
                </div>
            </div>
        </div>

    )
}

export default ApplicationForm