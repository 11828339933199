import React from 'react'

function Blogs() {
    return (
        <>
            <header className="sticky-top mobile">
                <div className="row clearfix">
                    <div className="col-2 text-start">
                        <a
                            className="btn-toggle"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#staticBackdrop"
                            aria-controls="staticBackdrop"
                        >
                            <i className="bi bi-list" />
                        </a>
                        <div
                            className="offcanvas offcanvas-start"
                            data-bs-backdrop="static"
                            tabIndex={-1}
                            id="staticBackdrop"
                            aria-labelledby="staticBackdropLabel"
                        >
                            <div className="offcanvas-header">
                                <h5 className="offcanvas-title" id="staticBackdropLabel">
                                    <a className="navbar-brand" href="index.html">
                                        <img
                                            src="images/logo.png"
                                            className="img-fluid"
                                            alt="Image goes here"
                                        />
                                    </a>
                                </h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="offcanvas"
                                    aria-label="Close"
                                />
                            </div>
                            <div className="offcanvas-body">
                                <ul className="navbar-nav text-center">
                                    <li className="nav-item">
                                        <a
                                            className="nav-link active"
                                            aria-current="page"
                                            href="index.html"
                                        >
                                            For Students
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="recruiters.html">
                                            For Recruiters
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="courses.html">
                                            Courses
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#">
                                            About Us
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a
                                            className="btn btn-transparent"
                                            href="#"
                                            data-bs-toggle="modal"
                                            data-bs-target="#login"
                                        >
                                            Login
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a
                                            className="btn btn-theme"
                                            href="#"
                                            data-bs-toggle="modal"
                                            data-bs-target="#register"
                                        >
                                            Register
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-8 text-center">
                        <a className="navbar-brand" href="index.html">
                            <img
                                src="images/logo.png"
                                className="img-fluid"
                                alt="Image goes here"
                            />
                        </a>
                    </div>
                    <div className="col-2 text-end">
                        <a href="#" className="btn-toggle">
                            <i className="bi bi-person-circle" />
                        </a>
                    </div>
                </div>
            </header>
            <nav className="navbar navbar-expand-lg sticky-top desktop">
                <div className="container">
                    <a className="navbar-brand" href="index.html">
                        <img
                            src="images/logo.png"
                            className="img-fluid"
                            alt="Image goes here"
                        />
                    </a>
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#edbucketNav"
                        aria-controls="edbucketNav"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon" />
                    </button>
                    <div className="collapse navbar-collapse" id="edbucketNav">
                        <ul className="navbar-nav ms-5">
                            <li className="nav-item">
                                <a
                                    className="nav-link active"
                                    aria-current="page"
                                    href="index.html"
                                >
                                    For Students
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="recruiters.html">
                                    For Recruiters
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="courses.html">
                                    Courses
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#">
                                    About Us
                                </a>
                            </li>
                        </ul>
                        <ul className="navbar-nav align-items-center ms-auto">
                            <li className="nav-item">
                                <a
                                    className="nav-link"
                                    href="#"
                                    data-bs-toggle="modal"
                                    data-bs-target="#login"
                                >
                                    Login
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="btn btn-theme"
                                    href="#"
                                    data-bs-toggle="modal"
                                    data-bs-target="#register"
                                >
                                    Register
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <section className="blog-intro">
                <div className="container">
                    <div className="row align-items-center clearfix">
                        <div className="col-12 col-lg-7">
                            <h1>Ed Blogs</h1>
                            <p>
                                Lorem Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                do eiusmod tempor incididunt ut labore
                                <br />
                                <br />
                                Interested? <a href="#">Let's talk</a>
                            </p>
                            <a href="#" className="btn btn-gradient">
                                Get in Touch
                            </a>
                        </div>
                        <div className="col-12 col-lg-5">
                            <div className="blog-imgholder">
                                <img src="images/blog-img.png" alt="Image goes here" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="accordion" id="blog">
                <section className="blog-section bg-f7faff">
                    <div className="container">
                        <div className="row clearfix">
                            <div className="col-12">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="blog-one">
                                        <button
                                            className="accordion-button"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#blogOne"
                                            aria-expanded="true"
                                            aria-controls="blogOne"
                                        >
                                            Topic 1 Title goes here
                                        </button>
                                    </h2>
                                    <div
                                        id="blogOne"
                                        className="accordion-collapse collapse show"
                                        aria-labelledby="blog-one"
                                        data-bs-parent="#blog"
                                    >
                                        <div className="accordion-body">
                                            <div className="row clearfix">
                                                <div className="col-12 col-lg-4">
                                                    <div className="eynk-box">
                                                        <img src="images/blog-1.png" />
                                                        <div className="eynk-title">
                                                            Blog title Lorem Doler lorem ipsum doler tlsds
                                                        </div>
                                                        <div className="eynk-text">
                                                            Lorem ipsum dolor sit amet, consectetur adipiscing
                                                            elit, sed do eiusmod tempor incididunt ut labore et
                                                            dolore magna aliqua. Ut enim ad minim veniam. Lorem
                                                            ipsum dolor sit amet, consectetur adipiscing eli...
                                                        </div>
                                                        <div className="bottom">
                                                            <img src="images/user.png" />
                                                            <div className="username">
                                                                <h6>
                                                                    Rashi Singh<span>Lorem ipsum Doler</span>
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-lg-4">
                                                    <div className="eynk-box">
                                                        <img src="images/blog-2.jpg" />
                                                        <div className="eynk-title">
                                                            Blog title Lorem Doler lorem ipsum doler tlsds
                                                        </div>
                                                        <div className="eynk-text">
                                                            Lorem ipsum dolor sit amet, consectetur adipiscing
                                                            elit, sed do eiusmod tempor incididunt ut labore et
                                                            dolore magna aliqua. Ut enim ad minim veniam. Lorem
                                                            ipsum dolor sit amet, consectetur adipiscing eli...
                                                        </div>
                                                        <div className="bottom">
                                                            <img src="images/user.png" />
                                                            <div className="username">
                                                                <h6>
                                                                    Rashi Singh<span>Lorem ipsum Doler</span>
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-lg-4">
                                                    <div className="eynk-box">
                                                        <img src="images/blog-3.jpg" />
                                                        <div className="eynk-title">
                                                            Blog title Lorem Doler lorem ipsum doler tlsds
                                                        </div>
                                                        <div className="eynk-text">
                                                            Lorem ipsum dolor sit amet, consectetur adipiscing
                                                            elit, sed do eiusmod tempor incididunt ut labore et
                                                            dolore magna aliqua. Ut enim ad minim veniam. Lorem
                                                            ipsum dolor sit amet, consectetur adipiscing eli...
                                                        </div>
                                                        <div className="bottom">
                                                            <img src="images/user.png" />
                                                            <div className="username">
                                                                <h6>
                                                                    Rashi Singh<span>Lorem ipsum Doler</span>
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="blog-section">
                    <div className="container">
                        <div className="row clearfix">
                            <div className="col-12">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="blog-two">
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#blogTwo"
                                            aria-expanded="false"
                                            aria-controls="blogTwo"
                                        >
                                            Topic 2 Title goes here
                                        </button>
                                    </h2>
                                    <div
                                        id="blogTwo"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="blog-two"
                                        data-bs-parent="#blog"
                                    >
                                        <div className="accordion-body">
                                            <div className="row clearfix">
                                                <div className="col-12 col-lg-4">
                                                    <div className="eynk-box">
                                                        <img src="images/blog-1.png" />
                                                        <div className="eynk-title">
                                                            Blog title Lorem Doler lorem ipsum doler tlsds
                                                        </div>
                                                        <div className="eynk-text">
                                                            Lorem ipsum dolor sit amet, consectetur adipiscing
                                                            elit, sed do eiusmod tempor incididunt ut labore et
                                                            dolore magna aliqua. Ut enim ad minim veniam. Lorem
                                                            ipsum dolor sit amet, consectetur adipiscing eli...
                                                        </div>
                                                        <div className="bottom">
                                                            <img src="images/user.png" />
                                                            <div className="username">
                                                                <h6>
                                                                    Rashi Singh<span>Lorem ipsum Doler</span>
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-lg-4">
                                                    <div className="eynk-box">
                                                        <img src="images/blog-2.jpg" />
                                                        <div className="eynk-title">
                                                            Blog title Lorem Doler lorem ipsum doler tlsds
                                                        </div>
                                                        <div className="eynk-text">
                                                            Lorem ipsum dolor sit amet, consectetur adipiscing
                                                            elit, sed do eiusmod tempor incididunt ut labore et
                                                            dolore magna aliqua. Ut enim ad minim veniam. Lorem
                                                            ipsum dolor sit amet, consectetur adipiscing eli...
                                                        </div>
                                                        <div className="bottom">
                                                            <img src="images/user.png" />
                                                            <div className="username">
                                                                <h6>
                                                                    Rashi Singh<span>Lorem ipsum Doler</span>
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-lg-4">
                                                    <div className="eynk-box">
                                                        <img src="images/blog-3.jpg" />
                                                        <div className="eynk-title">
                                                            Blog title Lorem Doler lorem ipsum doler tlsds
                                                        </div>
                                                        <div className="eynk-text">
                                                            Lorem ipsum dolor sit amet, consectetur adipiscing
                                                            elit, sed do eiusmod tempor incididunt ut labore et
                                                            dolore magna aliqua. Ut enim ad minim veniam. Lorem
                                                            ipsum dolor sit amet, consectetur adipiscing eli...
                                                        </div>
                                                        <div className="bottom">
                                                            <img src="images/user.png" />
                                                            <div className="username">
                                                                <h6>
                                                                    Rashi Singh<span>Lorem ipsum Doler</span>
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="blog-section bg-f7faff">
                    <div className="container">
                        <div className="row clearfix">
                            <div className="col-12">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="blog-three">
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#blogThree"
                                            aria-expanded="false"
                                            aria-controls="blogThree"
                                        >
                                            Topic 3 Title goes here
                                        </button>
                                    </h2>
                                    <div
                                        id="blogThree"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="blog-three"
                                        data-bs-parent="#blog"
                                    >
                                        <div className="accordion-body">
                                            <div className="row clearfix">
                                                <div className="col-12 col-lg-4">
                                                    <div className="eynk-box">
                                                        <img src="images/blog-1.png" />
                                                        <div className="eynk-title">
                                                            Blog title Lorem Doler lorem ipsum doler tlsds
                                                        </div>
                                                        <div className="eynk-text">
                                                            Lorem ipsum dolor sit amet, consectetur adipiscing
                                                            elit, sed do eiusmod tempor incididunt ut labore et
                                                            dolore magna aliqua. Ut enim ad minim veniam. Lorem
                                                            ipsum dolor sit amet, consectetur adipiscing eli...
                                                        </div>
                                                        <div className="bottom">
                                                            <img src="images/user.png" />
                                                            <div className="username">
                                                                <h6>
                                                                    Rashi Singh<span>Lorem ipsum Doler</span>
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-lg-4">
                                                    <div className="eynk-box">
                                                        <img src="images/blog-2.jpg" />
                                                        <div className="eynk-title">
                                                            Blog title Lorem Doler lorem ipsum doler tlsds
                                                        </div>
                                                        <div className="eynk-text">
                                                            Lorem ipsum dolor sit amet, consectetur adipiscing
                                                            elit, sed do eiusmod tempor incididunt ut labore et
                                                            dolore magna aliqua. Ut enim ad minim veniam. Lorem
                                                            ipsum dolor sit amet, consectetur adipiscing eli...
                                                        </div>
                                                        <div className="bottom">
                                                            <img src="images/user.png" />
                                                            <div className="username">
                                                                <h6>
                                                                    Rashi Singh<span>Lorem ipsum Doler</span>
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-lg-4">
                                                    <div className="eynk-box">
                                                        <img src="images/blog-3.jpg" />
                                                        <div className="eynk-title">
                                                            Blog title Lorem Doler lorem ipsum doler tlsds
                                                        </div>
                                                        <div className="eynk-text">
                                                            Lorem ipsum dolor sit amet, consectetur adipiscing
                                                            elit, sed do eiusmod tempor incididunt ut labore et
                                                            dolore magna aliqua. Ut enim ad minim veniam. Lorem
                                                            ipsum dolor sit amet, consectetur adipiscing eli...
                                                        </div>
                                                        <div className="bottom">
                                                            <img src="images/user.png" />
                                                            <div className="username">
                                                                <h6>
                                                                    Rashi Singh<span>Lorem ipsum Doler</span>
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <footer>
                <div className="container">
                    <div className="row clearfix">
                        <div className="col-12 col-lg-6">
                            <img src="images/footer-logo.png" className="img-fluid" />
                        </div>
                        <div className="col-4 col-lg-2">
                            <div className="ft-title">explore</div>
                            <ul className="ft-links clearfix">
                                <li>
                                    <a href="#">about</a>
                                </li>
                                <li>
                                    <a href="#">news</a>
                                </li>
                                <li>
                                    <a href="#">career</a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-4 col-lg-2">
                            <div className="ft-title">legal</div>
                            <ul className="ft-links clearfix">
                                <li>
                                    <a href="#">terms</a>
                                </li>
                                <li>
                                    <a href="#">privacy</a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-4 col-lg-2">
                            <div className="ft-title">follow</div>
                            <ul className="ft-links clearfix">
                                <li>
                                    <a href="#">
                                        facebook <i className="bi bi-facebook" />
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        Instagram <i className="bi bi-instagram" />
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        Twitter <i className="bi bi-twitter" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
            {/* Modal */}
            <div
                className="modal fade"
                id="login"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex={-1}
                aria-labelledby="staticBackdropLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        />
                        <h1>Login to Edbucket</h1>
                        <form method="post" id="loginform" action="" role="form">
                            <div className="form-group mb-3">
                                <input
                                    type="email"
                                    name="email"
                                    id="email"
                                    className="form-control"
                                    placeholder="Email"
                                    required=""
                                />
                            </div>
                            <div className="form-group mb-3">
                                <input
                                    type="password"
                                    name="password"
                                    id="password"
                                    className="form-control"
                                    placeholder="Password"
                                    required=""
                                />
                            </div>
                            <input
                                type="submit"
                                id="submit"
                                name="submit"
                                defaultValue="Login"
                                className="btn btn-gradient mt-32"
                            />
                        </form>
                        <div className="separator">
                            <span>or continue with</span>
                        </div>
                        <ul className="social-login">
                            <li>
                                <a href="#">
                                    <img src="images/google.png" />
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <img src="images/facebook.png" />
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <img src="images/twitter.png" />
                                </a>
                            </li>
                        </ul>
                        <p className="signup-option">
                            Don’t have an account yet?{" "}
                            <a href="#" data-bs-toggle="modal" data-bs-target="#register">
                                Register
                            </a>
                        </p>
                    </div>
                </div>
            </div>
            {/* Modal */}
            <div
                className="modal register fade"
                id="register"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex={-1}
                aria-labelledby="staticBackdropLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        />
                        <h1>Get Started</h1>
                        <form method="post" id="registerform" action="" role="form">
                            <div className="form-group mb-3">
                                <input
                                    type="text"
                                    name="name"
                                    id="name"
                                    className="form-control"
                                    placeholder="Name"
                                    required=""
                                />
                            </div>
                            <div className="form-group mb-3">
                                <input
                                    type="text"
                                    name="dob"
                                    id="dob"
                                    className="form-control"
                                    placeholder="Date of Birth"
                                    required=""
                                />
                            </div>
                            <div className="form-group mb-3">
                                <input
                                    type="email"
                                    name="email"
                                    id="email"
                                    className="form-control"
                                    placeholder="Email"
                                    required=""
                                />
                            </div>
                            <div className="form-group position-relative mb-3">
                                <input
                                    type="text"
                                    name="phone"
                                    id="phone"
                                    className="form-control pl-114"
                                    placeholder="Phone"
                                    required=""
                                />
                                <div className="country-code">
                                    <img src="images/india.png" />
                                    <select>
                                        <option value={+91}>+91</option>
                                    </select>
                                </div>
                            </div>
                            <input
                                type="submit"
                                id="submit"
                                name="submit"
                                defaultValue="Register"
                                className="btn btn-gradient mt-32"
                            />
                        </form>
                        <div className="separator">
                            <span>or continue with</span>
                        </div>
                        <ul className="social-login">
                            <li>
                                <a href="#">
                                    <img src="images/google.png" />
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <img src="images/facebook.png" />
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <img src="images/twitter.png" />
                                </a>
                            </li>
                        </ul>
                        <p className="signup-option">
                            Don’t have an account yet?{" "}
                            <a href="#" data-bs-toggle="modal" data-bs-target="#login">
                                Login
                            </a>
                        </p>
                    </div>
                </div>
            </div>
            {/* Modal */}
            <div
                className="modal register-courses fade"
                id="register-courses"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex={-1}
                aria-labelledby="staticBackdropLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        />
                        <div className="row clearfix">
                            <div className="col-12 col-lg-6 p-0  text-center">
                                <h6 className="text">Unlock all courses and destinations</h6>
                                <img
                                    src="images/3d-illustration-lg.png"
                                    className="illustration mx-auto"
                                    alt="Image goes here"
                                />
                            </div>
                            <div className="col-12 col-lg-6 p-0">
                                <div className="register-courses-form">
                                    <h1>Get Started</h1>
                                    <form method="post" id="registerform" action="" role="form">
                                        <div className="form-group mb-3">
                                            <input
                                                type="text"
                                                name="name"
                                                id="name"
                                                className="form-control"
                                                placeholder="Name"
                                                required=""
                                            />
                                        </div>
                                        <div className="form-group mb-3">
                                            <input
                                                type="text"
                                                name="dob"
                                                id="dob"
                                                className="form-control"
                                                placeholder="Date of Birth"
                                                required=""
                                            />
                                        </div>
                                        <div className="form-group mb-3">
                                            <input
                                                type="email"
                                                name="email"
                                                id="email"
                                                className="form-control"
                                                placeholder="Email"
                                                required=""
                                            />
                                        </div>
                                        <div className="form-group position-relative mb-3">
                                            <input
                                                type="text"
                                                name="phone"
                                                id="phone"
                                                className="form-control pl-114"
                                                placeholder="Phone"
                                                required=""
                                            />
                                            <div className="country-code">
                                                <img src="images/india.png" />
                                                <select>
                                                    <option value={+91}>+91</option>
                                                </select>
                                            </div>
                                        </div>
                                        <input
                                            type="submit"
                                            id="submit"
                                            name="submit"
                                            defaultValue="Register"
                                            className="btn btn-gradient mt-32"
                                        />
                                    </form>
                                    <div className="separator">
                                        <span>or continue with</span>
                                    </div>
                                    <ul className="social-login">
                                        <li>
                                            <a href="#">
                                                <img src="images/google.png" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <img src="images/facebook.png" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <img src="images/twitter.png" />
                                            </a>
                                        </li>
                                    </ul>
                                    <p className="signup-option">
                                        Don’t have an account yet?{" "}
                                        <a href="#" data-bs-toggle="modal" data-bs-target="#login">
                                            Login
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default Blogs