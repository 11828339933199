import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isStudent: false,
    updateStudentMessage: "",
    error: false,
    updateDetails: [{
        nationality: null
    }]
}

export const updateStudentSlice = createSlice({
    name: 'updateStudent',
    initialState,
    reducers: {
        updateStudentStart: (state) => {
            return { ...state, updateStudentMessage: "updating details started ...", error: false };
        },
        updateStudentSuccess: (state, action) => {
            return { ...state, updateStudentMessage: "updated details successfully", error: false, isStudent: true, updateDetails: action.payload };
        }

    }
})

export const { updateStudentStart, updateStudentSuccess } = updateStudentSlice.actions;

export default updateStudentSlice.reducer;