import React from 'react'
import RegisterModal from '../Modals/RegisterModal'
import { Link } from 'react-router-dom'
import GetStartedModal from '../Modals/GetStartedModal'
import { useSelector } from 'react-redux'


function HomeMainSection(props) {
    const { title, subtitle } = props;
    const activeTab = useSelector((state) => (state?.config?.activeTab))
    console.log("active tab is", activeTab)
    return (

        <div className='container-fluid p-0 '>
            <section className="hero ">
                <div className="container">
                    <div className="row align-items-center clearfix">
                        <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                            <div className="top-text">
                                <h1>{title}</h1>
                                <p className="banner-text">
                                    {subtitle}
                                </p>
                                <GetStartedModal />
                                <br />
                                <a href="#howitworks" className="btn btn-transparent mt-3 px-2">
                                    See How it Works <i className="bi bi-arrow-down" />
                                </a>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 col-xl-6 desktop tablet">
                            <img
                                src="images/landing-banner.png"
                                className="img-fluid"
                                alt="Image goes here"
                            />
                        </div>
                        <div className="col-12 col-md-6 col-xl-6 mobile p-0">
                            <img
                                src="images/landing-banner.png"

                                className="banner-sm"
                                alt="Image goes here"
                            />
                        </div>
                    </div>
                </div>
            </section>
            <section className="bg-f7faff clearfix p-3"
                data-bs-spy="scroll"
                data-bs-target="#edbucketNav"
                data-bs-root-margin="0px 0px -40%"
                data-bs-smooth-scroll="true"
                tabIndex={0}
                id="howitworks"
            >
                <div className="container">
                    <div className="row clearfix">
                        <div className="col-12 text-center">
                            <h2 className="title hiw">How it works</h2>
                        </div>
                        <div className="col-12">
                            <ul
                                className="nav nav-pills justify-content-center"
                                id="hiwtab"
                                role="tablist"
                            >
                                <li className="nav-item" role="presentation">
                                    <a
                                        className="nav-link active"
                                        id="students-tab"
                                        data-bs-toggle="pill"
                                        data-bs-target="#students"
                                        aria-controls="students"
                                        aria-selected="true"
                                    >
                                        For Students
                                    </a>
                                </li>
                                <li className="nav-item ms-1" role="presentation">
                                    <a
                                        className="nav-link"
                                        id="recruiters-tab"
                                        data-bs-toggle="pill"
                                        data-bs-target="#recruiters"
                                        aria-controls="recruiters"
                                        aria-selected="false"
                                    >
                                        For Recruiters / Counsellors
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-12 col-xl-12">
                            <div className="tab-content" id="pills-tabContent">
                                <div
                                    className="tab-pane fade show active"
                                    id="students"
                                    role="tabpanel"
                                    aria-labelledby="students-tab"
                                    tabIndex={0}
                                >
                                    <div className="row justify-content-center gx-5 clearfix">
                                        <div className="col-12 col-md-4 col-lg-4">
                                            <div className="works-grid text-center clearfix">
                                                <img src="images/hiw-student-1.png" alt="Image goes here" />
                                                <h3>Register with us</h3>
                                                <p>
                                                    Login and start browsing the courses from over 1500
                                                    universities from UK, US and Australia
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-4 col-lg-4">
                                            <div className="works-grid text-center clearfix">
                                                <img src="images/hiw-student-2.png" alt="Image goes here" />
                                                <h3>Receive Scholarships</h3>
                                                <p>
                                                    Instantly apply, upload academic documents and receive
                                                    scholarship offers
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-4 col-lg-4">
                                            <div className="works-grid text-center clearfix">
                                                <img src="images/hiw-student-3.png" alt="Image goes here" />
                                                <h3>Chat with us</h3>
                                                <p>Receive free expert advice from our friendly advisors</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="tab-pane fade"
                                    id="recruiters"
                                    role="tabpanel"
                                    aria-labelledby="recruiters-tab"
                                    tabIndex={0}
                                >
                                    <div className="row justify-content-center gx-5 clearfix">
                                        <div className="col-12 col-md-4 col-lg-4">
                                            <div className="works-grid text-center clearfix">
                                                <img src="images/get-in-touch.png" alt="Image goes here" />
                                                <h3>Get in Touch</h3>
                                                <p>
                                                    Login and start browsing the courses from over 1500
                                                    universities from UK, US and Australia
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-4 col-lg-4">
                                            <div className="works-grid text-center clearfix">
                                                <img
                                                    src="images/apply-get-verified.jpg"
                                                    alt="Image goes here"
                                                />
                                                <h3>Apply &amp; get Verified</h3>
                                                <p>
                                                    Instantly apply, upload academic documents and receive
                                                    scholarship offers
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-4 col-lg-4">
                                            <div className="works-grid text-center clearfix">
                                                <img src="images/you-all-setup.png" alt="Image goes here" />
                                                <h3>You are all setup</h3>
                                                <p>Receive free expert advice from our friendly advisors</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className=''

                id='ourcourses'>
                <div className="container">
                    <div className="row justify-content-center clearfix" >
                        <div className="col-12 text-lg-center ">
                            <h2 className="title">Select the right course for you</h2>
                        </div>
                        <div className="col-12 col-lg-10">
                            <ul className="course-lists">
                                <li>
                                    <a>Nursing Review Program</a>
                                </li>
                                <li>
                                    <a >Business - Accounting</a>
                                </li>
                                <li>
                                    <a >Early Childhood Care Education</a>
                                </li>



                            </ul>
                        </div>
                        <div className="col-12 text-center">
                            <GetStartedModal />

                        </div>
                    </div>
                </div>
            </section>
            <section className="bg-f7faff clearfix">
                <div className="container">
                    <div className="row clearfix">
                        <div className="col-12 text-center">
                            <h2 className="title she ">Say hello to Ed</h2>
                        </div>
                    </div>
                    <div className="row clearfix">
                        <div className="col-12 col-md-12 col-lg-10 offset-lg-1 offset-md-0">
                            <ul className="features-grid clearfix">
                                <li>
                                    <div className="row align-items-center clearfix">
                                        <div className="col-12 col-md-6 col-lg-6">
                                            <img
                                                src="images/ed-1.png"
                                                className="img-features"
                                                alt="Image goes here"
                                            />
                                        </div>
                                        <div className="col-12 col-md-6 col-lg-6">
                                            <div className="features">
                                                <h4>
                                                    {/* <span>Ed Feature 1</span> */}Guaranteed Scholarship
                                                </h4>
                                                <p>
                                                    With us you are guaranteed a scholarship from the school
                                                </p>
                                                <a>
                                                    Apply Now <i className="bi bi-chevron-right" />
                                                </a>

                                            </div>
                                        </div>
                                    </div>
                                </li>

                                <li>
                                    <div className="row align-items-center clearfix">
                                        <div className="col-12 mobile">
                                            <img
                                                src="images/ed-2.png"
                                                className="img-fluid"
                                                alt="Image goes here"
                                            />
                                        </div>
                                        <div className="col-12 col-md-6 col-lg-6">
                                            <div className="features">
                                                <h4>Course Search</h4>
                                                <p>
                                                    Search courses from our wide pool of partner universities
                                                </p>
                                                <a href="#ourcourses">
                                                    See all Courses <i className="bi bi-chevron-right" />
                                                </a>
                                                {/* <a href="\">
                                                    See all Courses <i className="bi bi-chevron-right" />
                                                </a> */}
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 col-lg-6 desktop text-end">
                                            <img
                                                src="images/ed-2.png"
                                                className="img-features"
                                                alt="Image goes here"
                                            />
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="row align-items-center clearfix">
                                        <div className="col-12 col-md-6 col-lg-6">
                                            <img
                                                src="images/ed-3.png"
                                                className="img-features"
                                                alt="Image goes here"
                                            />
                                        </div>
                                        <div className="col-12 col-md-6 col-lg-6">
                                            <div className="features">
                                                <h4>Ed Assistant</h4>
                                                <p>
                                                    Our friendly assistant will help you with the application
                                                    process
                                                </p>
                                                <a >
                                                    About Ed <i className="bi bi-chevron-right" />
                                                </a>
                                                {/* <a href="\">
                                                    About Ed <i className="bi bi-chevron-right" />
                                                </a> */}
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="row align-items-center clearfix">
                                        <div className="col-12 mobile">
                                            <img
                                                src="images/ed-4.png"
                                                className="img-fluid"
                                                alt="Image goes here"
                                            />
                                        </div>
                                        <div className="col-12 col-md-6 col-lg-6">
                                            <div className="features">
                                                <h4>English Test &amp; Interview Preparation</h4>
                                                <p>
                                                    Our team helps with english and university interview
                                                    preparations
                                                </p>
                                                <a href="">
                                                    Prepare with ED <i className="bi bi-chevron-right" />
                                                </a>
                                                {/* <a href="\">
                                                    Prepare with ED <i className="bi bi-chevron-right" />
                                                </a> */}
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 col-lg-6 desktop text-end">
                                            <img
                                                src="images/ed-4.png"
                                                className="img-features"
                                                alt="Image goes here"
                                            />
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <section className='p-0 pt-5 ' id='destinations'>
                <div className="container">
                    <div className="row clearfix">
                        <div className="col-12 text-center">
                            <h2 className="title pdsa">Popular destinations to study abroad</h2>
                            <p className="subtext">
                                We have partnered with over 120 world class education providers in
                                UK, US, and Australia
                            </p>
                        </div>
                        <div className="">
                            {/* Swiper */}
                            <div className="">
                                <div className="swiper study-destinations">
                                    <div className="swiper-wrapper">
                                        <div className="swiper-slide d-block d-lg-flex justify-content-between">
                                            <div className="destination-box col-12 col-lg-4 mb-4 mb-lg-0 me-0 me-lg-3">
                                                <img
                                                    src="images/australia-university.png"
                                                    alt="Image goes here"
                                                />
                                                <h4>Study in UK</h4>
                                                <p>Explore from over 10 universities and 100+ courses</p>
                                            </div>

                                            <div className="destination-box col-12 col-lg-4  mb-4 mb-lg-0 me-0 me-lg-3">
                                                <img
                                                    src=" images/ireland-university.jpg"
                                                    alt="Image goes here"
                                                />
                                                <h4>Study in Australia</h4>
                                                <p>Explore from over 10 universities and 100+ courses</p>
                                            </div>

                                            <div className="destination-box col-12 col-lg-4  mb-4 mb-lg-0 me-0 me-lg-3">
                                                <img
                                                    src="images/australia-university.png"
                                                    alt="Image goes here"
                                                />
                                                <h4>Study in Canada</h4>
                                                <p>Explore from over 10 universities and 100+ courses</p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                {/* <div className="destination-controls">
                                    <div className="swiper-button-prev" />
                                    <div className="swiper-button-next" />
                                </div> */}
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <section className="bg-f7faff p-0 pt-5 " id='ouruniversities'>
                <div className="container">
                    <div className="row clearfix">
                        <div className="col-12 text-center">
                            <h2 className="title dtu">Discover Top Universities</h2>
                            <p className="subtext">
                                We're partnered with over 120 world class education providers
                            </p>
                        </div>
                        <div className="">
                            {/* Swiper */}
                            <div className="">
                                <div className="swiper study-destinations">
                                    <div className="swiper-wrapper">
                                        <div className="swiper-slide d-block d-lg-flex justify-content-between">
                                            <div className="destination-box col-12 col-lg-4  mb-4 mb-lg-0 me-0 me-lg-3">
                                                <img
                                                    src="images/bridgeport-university.jpeg"
                                                    alt="Image goes here"
                                                />
                                                <h4>University of Bridgeport</h4>
                                                <p>Bridgeport, Connecticut</p>
                                            </div>

                                            <div className="destination-box col-12 col-lg-4  mb-4 mb-lg-0 me-0 me-lg-3">
                                                <img
                                                    src="images/colorado-university.jpeg"
                                                    alt="Image goes here"
                                                />
                                                <h4>Colorado Mesa University</h4>
                                                <p>Grand Junction, Colorado</p>
                                            </div>

                                            <div className="destination-box col-12 col-lg-4  mb-4 mb-lg-0 me-0 me-lg-3">
                                                <img
                                                    src="images/southeastern-university.jpeg"
                                                    alt="Image goes here"
                                                />
                                                <h4>Nova Southeastern University</h4>
                                                <p>Fort Lauderdale-Davie, Florida</p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                {/* <div className="destination-controls">
                                    <div className="swiper-button-prev" />
                                    <div className="swiper-button-next" />
                                </div> */}
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <section className='p-0' id='testimonials'>
                <div className="container">
                    <div className="row clearfix">
                        <div className="col-12 text-center">
                            <h2 className="title wdlsphu p-0 p-lg-1">
                                We don't like to boast,
                                <br />
                                hear it urself
                            </h2>
                        </div>
                        <div className="col-12">
                            <ul
                                className="nav nav-pills justify-content-center pb-5"
                                id="testimonials"
                                role="tablist"
                            >
                                <li className="nav-item" role="presentation">
                                    <a
                                        className="nav-link active"
                                        id="studentsTestimonials-tab"
                                        data-bs-toggle="pill"
                                        data-bs-target="#studentsTestimonials"
                                        aria-controls="studentsTestimonials"
                                        aria-selected="true"
                                    >
                                        For Students
                                    </a>
                                </li>
                                <li className="nav-item ms-1" role="presentation">
                                    <a
                                        className="nav-link"
                                        id="universitiesTestimonials-tab"
                                        data-bs-toggle="pill"
                                        data-bs-target="#universitiesTestimonials"
                                        aria-controls="universitiesTestimonials"
                                        aria-selected="false"
                                    >
                                        From Universities
                                    </a>
                                </li>
                            </ul>
                            <div className="tab-content" id="pills-tabContent">
                                <div
                                    className="tab-pane fade show active"
                                    id="studentsTestimonials"
                                    role="tabpanel"
                                    aria-labelledby="studentsTestimonials-tab"
                                    tabIndex={0}
                                >
                                    <div className="row clearfix">
                                        <div className="">
                                            {/* Swiper */}
                                            <div className="">
                                                <div className="d-block d-lg-flex justify-content-between">
                                                    <div className="user-box col-12 col-lg-4 me-lg-3 mb-4 mb-lg-2">
                                                        <img
                                                            src="images/deepkumar.png" alt="Deepkumar Thakkar"
                                                        />
                                                        <div className="text-start" style={{ color: '#413F5C' }}>
                                                            Amazing Team. Helped me through out my journey to
                                                            study at University of Bedfordshire. Super team
                                                            and great work!
                                                        </div>
                                                        <div className="text-start mt-3">
                                                            <div className="username">
                                                                <h6>
                                                                    Deepkumar Thakkar
                                                                </h6>
                                                                <p style={{ color: '#413F5C' }}>UK</p>
                                                            </div>
                                                        </div>
                                                        {/* <p>New York, USA</p> */}
                                                    </div>

                                                    <div className="user-box col-12 col-lg-4 me-lg-3 mb-4 mb-lg-2">
                                                        <img
                                                            src="images/lovepreet.png" alt="LovePreet"
                                                        />
                                                        <div className="text-start" style={{ color: '#413F5C' }}>
                                                            EDB helped me with admission and arranging a fully
                                                            funded MBA Program from London's top University.
                                                            Super team and great work!
                                                        </div>
                                                        <div className="text-start mt-3">
                                                            <div className="username">
                                                                <h6>
                                                                    Lovepreet Kaur
                                                                </h6>
                                                                <p style={{ color: '#413F5C' }}>MBA, UK</p>
                                                            </div>
                                                        </div>
                                                        {/* <p>New York, USA</p> */}
                                                    </div>

                                                    <div className="user-box col-12 col-lg-4 me-lg-3 ">
                                                        <img
                                                            src="images/bharti.png" alt="Bharti"
                                                        />
                                                        <div className="text-start" style={{ color: '#413F5C' }}>
                                                            ED helped me at every step and made my application
                                                            process super easy to study in the UK ! I even did
                                                            my AI internship with EDBUCKET!
                                                        </div>
                                                        <div className="text-start mt-3">
                                                            <div className="username">
                                                                <h6>
                                                                    Bharti Ravel
                                                                </h6>
                                                                <p style={{ color: '#413F5C' }}>UK</p>
                                                            </div>
                                                        </div>
                                                        {/* <p>New York, USA</p> */}
                                                    </div>
                                                    {/* <div className="destination-box col-4 me-3">
                                                                <img
                                                                    src="images/hult-business-school.png"
                                                                    alt="Image goes here"
                                                                />
                                                                <h4>Hult Business School</h4>
                                                                <p>New York, USA</p>
                                                            </div>

                                                            <div className="destination-box col-4">
                                                                <img
                                                                    src="images/University-of-East-London.png"
                                                                    alt="Image goes here"
                                                                />
                                                                <h4>Oxford University</h4>
                                                                <p>New York, USA</p>
                                                            </div> */}
                                                </div>


                                            </div>


                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="tab-pane fade"
                                    id="universitiesTestimonials"
                                    role="tabpanel"
                                    aria-labelledby="universitiesTestimonials-tab"
                                    tabIndex={0}
                                >
                                    <div className="row clearfix">
                                        <div className="">
                                            {/* Swiper */}
                                            <div className="">
                                                <div className=" d-block d-lg-flex justify-content-between">
                                                    <div className="user-box col-12 col-lg-4  me-lg-3 mb-4 mb-lg-2">
                                                        <img
                                                            src="images/deepkumar.png" alt="Deepkumar Thakkar"
                                                        />
                                                        <div className="text-start" style={{ color: '#413F5C' }}>
                                                            Amazing Team. Helped me through out my journey to
                                                            study at University of Bedfordshire. Super team
                                                            and great work!
                                                        </div>
                                                        <div className="text-start mt-3">
                                                            <div className="username">
                                                                <h6>
                                                                    Deepkumar Thakkar
                                                                </h6>
                                                                <p style={{ color: '#413F5C' }}>UK</p>
                                                            </div>
                                                        </div>
                                                        {/* <p>New York, USA</p> */}
                                                    </div>

                                                    <div className="user-box  col-12 col-lg-4 me-lg-3 mb-4 mb-lg-2">
                                                        <img
                                                            src="images/lovepreet.png" alt="LovePreet"
                                                        />
                                                        <div className="text-start" style={{ color: '#413F5C' }}>
                                                            EDB helped me with admission and arranging a fully
                                                            funded MBA Program from London's top University.
                                                            Super team and great work!
                                                        </div>
                                                        <div className="text-start mt-3">
                                                            <div className="username">
                                                                <h6>
                                                                    Lovepreet Kaur
                                                                </h6>
                                                                <p style={{ color: '#413F5C' }}>MBA, UK</p>
                                                            </div>
                                                        </div>
                                                        {/* <p>New York, USA</p> */}
                                                    </div>

                                                    <div className="user-box col-12 col-lg-4 me-lg-3 mb-4 mb-lg-2">
                                                        <img
                                                            src="images/bharti.png" alt="Bharti"
                                                        />
                                                        <div className="text-start" style={{ color: '#413F5C' }}>
                                                            ED helped me at every step and made my application
                                                            process super easy to study in the UK ! I even did
                                                            my AI internship with EDBUCKET!
                                                        </div>
                                                        <div className="text-start mt-3">
                                                            <div className="username">
                                                                <h6>
                                                                    Bharti Ravel
                                                                </h6>
                                                                <p style={{ color: '#413F5C' }}>UK</p>
                                                            </div>
                                                        </div>
                                                        {/* <p>New York, USA</p> */}
                                                    </div>
                                                    {/* <div className="destination-box col-4 me-3">
                                                                <img
                                                                    src="images/hult-business-school.png"
                                                                    alt="Image goes here"
                                                                />
                                                                <h4>Hult Business School</h4>
                                                                <p>New York, USA</p>
                                                            </div>

                                                            <div className="destination-box col-4">
                                                                <img
                                                                    src="images/University-of-East-London.png"
                                                                    alt="Image goes here"
                                                                />
                                                                <h4>Oxford University</h4>
                                                                <p>New York, USA</p>
                                                            </div> */}
                                                </div>


                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="bg-f7faff d-none">
                <div className="container">
                    <div className="row clearfix">
                        <div className="col-12 text-center">
                            <h2 className="title">These might help you</h2>
                        </div>
                        <div className="col-12">
                            <div className="row mb-40 clearfix">
                                <div className="col-12 col-md-6 col-lg-4">
                                    <div className="eynk-box">
                                        <img src="images/blog-1.png" />
                                        <div className="eynk-title">
                                            Blog title Lorem Doler lorem ipsum doler tlsds
                                        </div>
                                        <div className="eynk-text">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                            do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                            Ut enim ad minim veniam. Lorem ipsum dolor sit amet,
                                            consectetur adipiscing eli...
                                        </div>
                                        <div className="bottom">
                                            <img src="images/user.png" />
                                            <div className="username">
                                                <h6>
                                                    Rashi Singh<span>Lorem ipsum Doler</span>
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-4">
                                    <div className="eynk-box">
                                        <img src="images/blog-2.jpg" alt='user2Img' />
                                        <div className="eynk-title">
                                            Blog title Lorem Doler lorem ipsum doler tlsds
                                        </div>
                                        <div className="eynk-text">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                            do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                            Ut enim ad minim veniam. Lorem ipsum dolor sit amet,
                                            consectetur adipiscing eli...
                                        </div>
                                        <div className="bottom">
                                            <img src="images/user.png" />
                                            <div className="username">
                                                <h6>
                                                    Rashi Singh<span>Lorem ipsum Doler</span>
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-4">
                                    <div className="eynk-box">
                                        <img src="images/blog-3.jpg" alt='blog' />
                                        <div className="eynk-title">
                                            Blog title Lorem Doler lorem ipsum doler tlsds
                                        </div>
                                        <div className="eynk-text">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                            do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                            Ut enim ad minim veniam. Lorem ipsum dolor sit amet,
                                            consectetur adipiscing eli...
                                        </div>
                                        <div className="bottom">
                                            <img src="images/user.png" alt='user3Image' />
                                            <div className="username">
                                                <h6>
                                                    Rashi Singh<span>Lorem ipsum Doler</span>
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 text-center">
                            <a href="blogs.html" className="btn btn-theme">
                                See blogs
                            </a>
                        </div>
                    </div>
                </div>
            </section>
            <section className="bg-f7faff">
                <div className="container">
                    <div className="row align-items-center clearfix">
                        <div className="col-12 mobile">
                            <img
                                src="images/side-img.png"
                                className="img-fluid w-100"
                                alt="image-mobile"
                            />
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 offset-lg-1">
                            <div className="bottom-text">
                                <h4>Ready? Let's gooo!</h4>
                                <p className="banner-text">
                                    Register here and start browsing from over thousands of
                                    universities from UK, Australia and U.S
                                </p>
                                {/* <a
                                    href="#"
                                    className="btn btn-gradient"
                                    data-bs-toggle="modal"
                                    data-bs-target="#register"
                                >
                                    Get Started
                                </a> */}
                                <GetStartedModal />
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4 desktop">
                            <img
                                src="images/side-img.png"
                                className="img-fluid"
                                alt="side-image"
                            />
                        </div>
                    </div>
                </div>
            </section>



        </div>

    )
}

export default HomeMainSection