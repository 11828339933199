import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    isAgent: false,
    agentMessage: "",
    error: false,
    agentDetails: [],
    agentList: [],
    loading: true
}

export const getCounAgentSlice = createSlice({
    name: 'agentDetails',
    initialState,
    reducers: {
        getCounAgentDetailsStart: (state) => {
            return { ...state, agentMessage: "Fetching agent details started ...", error: false, };
        },
        getCounAgentDetailsSuccess: (state, action) => {
            return { ...state, agentMessage: "All details fetched successfully", error: false, loading: false, isAgent: true, agentDetails: action.payload };
        },
        getCounAgentListStart: (state) => {
            return { ...state, agentMessage: "Fetching agent list started" }
        },
        getCounAgentListSuccess: (state, action) => {
            return { ...state, isAgent: true, agentMessage: "Agent list fetched successfully", agentList: action.payload, loading: false }
        },
        resetCounAgent: (state) => {
            return { ...state, loading: true, agentList: [], agentDetails: [] }
        }

    }
})

export const { getCounAgentDetailsStart, getCounAgentDetailsSuccess, getCounAgentListStart, getCounAgentListSuccess, resetCounAgent } = getCounAgentSlice.actions;

export default getCounAgentSlice.reducer;