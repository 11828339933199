import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isTags: false,
    customTagsMessage: "",
    error: false,
    customMergeTags: []
}

export const customTagsSlice = createSlice({
    name: 'customTags',
    initialState,
    reducers: {
        getCustomTagsStart: (state) => {
            return { ...state, customTagsMessage: "Fetching custom tags started ...", error: false };
        },
        getCustomTagsSuccess: (state, action) => {
            return { ...state, customTagsMessage: "All custom tags fetched successfully", error: false, isTags: true, customMergeTags: action.payload };
        },
        createCustomTagsStart: (state) => {
            return { ...state, customTagsMessage: "Creation of custom tags started ...", error: false, };
        },
        createCustomTagsSuccess: (state, action) => {
            return { ...state, customTagsMessage: "All custom tags created successfully", error: false, isTags: true, };
        },
        deleteCustomTag: (state, action) => {
            const updatedTags = state.customMergeTags.filter(tag => tag.field_name !== action.payload.field_name);
            return { ...state, customMergeTags: updatedTags };
        }

    }
})

export const { getCustomTagsStart, getCustomTagsSuccess, createCustomTagsStart, createCustomTagsSuccess, deleteCustomTag } = customTagsSlice.actions;



// export default {
//     getTagsCustomReducer: getTagsCustomSlice.reducer,
//     createTagsCustomReducer: createTagsCustomSlice.reducer
// };

export default customTagsSlice.reducer;
