import React from 'react'
import { Link } from 'react-router-dom'

function Breadcrump({ items }) {
    return (

        <div className="row clearfix">
            <div className="col-12">
                {/* <nav aria-label="breadcrumb"> */}
                {/* <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <a href={data.backurl}>{data.main}</a>
                        </li>
                        <li
                            className="breadcrumb-item active"
                            aria-current="page"
                        >
                            {data.subdivision}
                        </li>
                    </ol> */}
                {items && items.length > 0 && (
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            {items.map((item, index) => (
                                <li
                                    key={index}
                                    className={index === items.length - 1 ? 'breadcrumb-item active' : 'breadcrumb-item'}
                                    aria-current={index === items.length - 1 ? 'page' : undefined}
                                >
                                    {index === items.length - 1 ? (
                                        item.text
                                    ) : (
                                        <Link to={item.path}>{item.text}</Link>
                                    )}
                                </li>
                            ))}
                        </ol>
                    </nav>
                )}
                {/* </nav> */}
            </div>
        </div>

    )
}

export default Breadcrump