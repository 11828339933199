import React, { useState } from 'react'
import LeftSidebar from '../../components/LeftSidebar'
import UnivLeftSidebar from '../../components/UnivLeftSidebar'
import { IoMdArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import EdpaySidebar from '../../components/EdpaySidebar';
import { SiChatbot } from 'react-icons/si';
import { CgInsights } from 'react-icons/cg';
import { RiQuestionAnswerFill } from 'react-icons/ri';

function Edpay() {
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState('Beneficiary')

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };
    return (
        <>
            <UnivLeftSidebar />

            <div className='col-md-10 ms-auto pe-0 ps-0 bg-white' style={{ height: '42rem' }}>
                <nav className="navbar navbar-light bg-white p-0 border d-flex justify-content-center " >
                    <span className="navbar-brand ps-3 branding"><b>Payment Solution</b></span>
                    {/* <i className="bi bi-info-circle"></i> */}
                </nav>

                <div className="d-flex justify-content-between">
                    <button
                        onClick={() => handleTabClick('Beneficiary')}
                        className={`btn border w-100 text-center m-3 p-3 fw-500 ${activeTab === 'Beneficiary' ? 'branding-boxShadow' : 'none'}`}
                        style={{
                            fontWeight: activeTab === 'Chatbot' ? 'bold' : 'normal',
                            // boxShadow: activeTab === 'Chatbot' ? '0 0 2px 2px skyblue' : 'none',
                        }}
                    ><SiChatbot size={30} /> Beneficiary</button>
                    <button
                        onClick={() => handleTabClick('Intermediary')}
                        className={`btn border w-100 text-center m-3 p-3 fw-500 ${activeTab === 'Intermediary' ? 'branding-boxShadow' : 'none'}`}
                        style={{
                            fontWeight: activeTab === 'Insights' ? 'bold' : 'normal',
                            // boxShadow: activeTab === 'Insights' ? '0 0 2px 2px skyblue' : 'none',
                        }}
                    ><CgInsights size={30} /> Intermediary</button>
                    <button
                        onClick={() => handleTabClick('Action')}
                        className={`btn border w-100 text-center m-3 p-3 fw-500 ${activeTab === 'Action' ? 'branding-boxShadow' : 'none'}`}
                        style={{
                            fontWeight: activeTab === 'Action' ? 'bold' : 'normal',
                            // boxShadow: activeTab === 'Action' ? '0 0 2px 2px skyblue' : 'none',
                        }}
                    ><RiQuestionAnswerFill size={25} /> Remitter</button>
                </div>

                {/* BRAND */}


            </div>
        </>
    )
}

export default Edpay