import { useEffect } from "react";
import { useNavigate } from "react-router-dom"

function Homepage() {
    const navigate = useNavigate();

    useEffect(() => {
        navigate('/students')
    }, [])

    return (
        <div></div>

    )
}

export default Homepage