import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getChatNotify } from '../../../@redux/dispatch/chatNotify';
import { useLocation } from 'react-router-dom';

function ViewDocumentsDetails({ setActiveTab }) {
    const userRole = useSelector((state) => (state?.config?.profile_id))
    const note = useSelector((state) => state.chatNotify?.messageRes?.[0]?.message_body)
    console.log("note in student ", note)
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const application_id = queryParams.get('application');
    const application = application_id.slice(-6);

    console.log("application id is: ", application)
    const dispatch = useDispatch();
    console.log("Username is ", userRole)
    console.log("userRole outside useEffect:", userRole);

    useEffect(() => {
        dispatch(getChatNotify(application))
    }, [])

    return (
        <>
            {/* <div className='text-center'>
                <h2 className='text-success'>Your Application is submitted successfully!</h2>

            </div> */}
            <div className='d-flex justify-content-around'>
                <h1 className='my-auto'>Notification : -</h1>
                <h2 className='text-success my-auto'>{note}</h2>
            </div>
            <div className='text-end mt-5'>
                <button className="btn btn-theme me-1" onClick={() => { setActiveTab("viewemployment") }}>Previous</button>
            </div>
        </>

    )
}

export default ViewDocumentsDetails