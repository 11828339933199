import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    getUploadedDoc: false,
    uploadedDocMessage: "",
    error: false,
    applicationDetails: []
}

export const getUploadedDocSlice = createSlice({
    name: 'getUploadedDoc',
    initialState,
    reducers: {
        getuploadedDocStart: (state) => {
            return { ...state, uploadedDocMessage: "Fetching document information started ...", error: false, applicationDetails: [] };
        },
        getUploadedDocSuccess: (state, action) => {
            return { ...state, uploadedDocMessage: "Document information fetched successfully", error: false, getUploadedDoc: true, applicationDetails: action.payload };
        },
        getResetDocs: (state) => {
            return { ...state, applicationDetails: [] }
        }

    }
})

export const { getuploadedDocStart, getUploadedDocSuccess, getResetDocs } = getUploadedDocSlice.actions;

export default getUploadedDocSlice.reducer;