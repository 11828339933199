import React from 'react'

function ExampleModalButton() {
    return (
        <>
            {/* Button trigger modal */}
            <div type="button" className='text-decortion-none' data-bs-toggle="modal" data-bs-target="#exampleModal" id="filter">
                <svg
                    width={15}
                    height={18}
                    viewBox="0 0 15 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <ellipse
                        cx="10.8334"
                        cy="4.83333"
                        rx="3.33333"
                        ry="3.33333"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <ellipse
                        cx="4.16674"
                        cy="13.1667"
                        rx="3.33333"
                        ry="3.33333"
                        transform="rotate(180 4.16674 13.1667)"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M0.833328 4.83325L7.5 4.83325"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M14.1668 13.1667L7.50016 13.1667"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
                Filters
            </div>
        </>

    )
}

export default ExampleModalButton