import axios from "axios";
import { createCustomTagsStart, createCustomTagsSuccess, getCustomTagsStart, getCustomTagsSuccess, deleteCustomTag } from "../../../actions/template/customMergeTags";
import { closeLoader, openLoader } from "../../../actions/config";
import apiUrl from "../../../ApiUrl";

export const getCustomTags = (body) => async (dispatch, getState) => {
    const baseUrl = getState().config.baseUrl
    dispatch(getCustomTagsStart());
    try {
        const response = await axios.get(`${apiUrl}/api/template/all/fields/details/custom/true?id=${body.id}`);
        console.log('response I am getting for custom tags are', response)
        dispatch(getCustomTagsSuccess(response))
    } catch (error) {
        console.log(error)
    }
};

export const createCustomTags = (body) => async (dispatch, getState) => {
    const baseUrl = getState().config.baseUrl
    dispatch(createCustomTagsStart());
    dispatch(openLoader());
    try {
        const response = await axios.post(`${apiUrl}/api/template/create/fields/batch/${body.id}`, body.data);
        // const response = await axios.post(`http://localhost:5000/api//api/template/create/fields/batch/`, body);
        console.log("Response I am getting as", response);
        dispatch(createCustomTagsSuccess(response.data))
        dispatch(getCustomTags(body))
    }
    catch (error) {
        console.log(error)
    }
    finally {
        dispatch(closeLoader());
    }
}

export const deleteCustomTags = (body) => async (dispatch, getState) => {
    const baseUrl = getState().config.baseUrl
    const tags = getState().customTags.customMergeTags
    try {
        const filterTags = tags.data.fields.filter(item => item.field_name !== body.field_name)
        dispatch(getCustomTagsSuccess({ data: { fields: filterTags } }))

        const response = await axios.delete(`${apiUrl}/api/template/fields/delete/${body.id}/${body.field_name}`)

        // dispatch(deleteCustomTag({ field_name: body.field_name }));
    }
    catch (error) {
        dispatch(getCustomTagsSuccess(tags))
        console.log("error is", error)
    }
}





