import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllMergeTagsFields } from '../../../@redux/dispatch/template/GetAllMergeTags'
import { generateOfferLetter, releaseOfferLetter } from '../../../@redux/dispatch/Generate_offer_letter_dispatch'
import { getReleasedLetter } from '../../../@redux/dispatch/Generate_offer_letter_dispatch/getReleasedOfferLetter'
import PostChatNotify from './PostChatNotify'
// import PDFViewer from '../../OpenPDF'

function ViewCounsellorStudentDocumentDetails({ setActiveTab }) {
    const dispatch = useDispatch()
    const documentFields = useSelector((state) => state?.allMergeTags?.allMergeTags?.fields)
    const personalValues = useSelector((state) => state?.agentStudentDetails?.studentDetails?.data?.[0])
    const course_id = useSelector((state) => state?.applicationDetails?.applicationDetails?.data?.[0]?.Select_Program?.["id"])
    const application_id = useSelector((state) => state?.applicationDetails?.applicationDetails?.data?.[0]?.id)
    const student_id = useSelector((state) => state?.applicationDetails?.applicationDetails?.data?.[0]?.Contact_Name?.["id"])
    const contactInfo = useSelector((state) => state?.applicationDetails?.applicationDetails?.data?.[0])
    const offerLetterStatus = useSelector((state) => state?.applicationDetails?.applicationDetails?.data?.[0]?.Offer_Letter_Counsellor)
    const offerLetterCounsellor = useSelector((state) => state?.applicationDetails?.applicationDetails?.data?.[0]?.Offer_Letter_Counsellor)
    const offerLetterStudent = useSelector((state) => state?.applicationDetails?.applicationDetails?.data?.[0]?.Offer_Letter_Student)
    const offerLetter = useSelector((state) => (state?.offer_letter?.offerLetter))
    console.log("Your offer letter is", offerLetter)
    console.log("offer letter status", offerLetterStatus)
    console.log("Offer letter counsellor is here:- ", offerLetterCounsellor)
    console.log("Offer letter student is here:- ", offerLetterStudent)
    const [formValues, setFormValues] = useState({})

    const generateValue = (fieldName) => {
        if (fieldName === "Correspondence_Address_Line1") {
            // setFormValues(prev => ({ ...prev, [fieldName]: personalValues?.Street_Address }))
            return personalValues?.Street_Address || "NA"
        }
        if (fieldName === "Correspondence_Address_Line2") {
            // setFormValues(prev => ({ ...prev, [fieldName]: personalValues?.Street_Address_Line_2 }))
            return personalValues?.Street_Address_Line_2
        }
        if (fieldName === "Course_Duration") {
            // setFormValues(prev => ({ ...prev, [fieldName]: contactInfo?.Course_Duration }))
            return contactInfo?.Course_Duration
        }
        if (fieldName === "Course_Level") {
            // setFormValues(prev => ({ ...prev, [fieldName]: contactInfo?.Course_Level }))
            return contactInfo?.Course_Level
        }
        if (fieldName === "Course_Name") {
            // setFormValues(prev => ({ ...prev, [fieldName]: contactInfo?.Select_Program?.["name"] }))
            return contactInfo?.Select_Program?.["name"]
        }
        if (fieldName === "Course_Status") {
            // setFormValues(prev => ({ ...prev, [fieldName]: contactInfo?.Course_Status }))
            return contactInfo?.Course_Status
        }
        if (fieldName === "Date_of_Birth") {
            // setFormValues(prev => ({ ...prev, [fieldName]: personalValues?.Date_of_Birth }))
            return personalValues?.Date_of_Birth
        }
        if (fieldName === "Full_Name") {
            // setFormValues(prev => ({ ...prev, [fieldName]: contactInfo?.Contact_Name?.["name"] }))
            return contactInfo?.Contact_Name?.["name"]
        }
        if (fieldName === "University_Name") {
            // setFormValues(prev => ({ ...prev, [fieldName]: contactInfo?.University_Name }))
            return contactInfo?.University_Name
        }
    }

    useEffect(() => {
        const data = {}
        documentFields?.forEach((field) => {
            if (field.type === "default") {
                data[field.field_name] = generateValue(field.field_name)
            }
        })
        setFormValues(prev => ({ ...prev, ...data }))
    }, [documentFields?.length])

    useEffect(() => {
        console.log("hello", formValues)
    }, formValues?.length)

    console.log('View course id is:-', course_id)
    console.log('View application id is:-', application_id)
    console.log('View student id is:-', student_id)
    console.log("View personal information of student", personalValues)
    console.log("Contact Info here is", contactInfo)

    useEffect(() => {
        console.log("document fields are", documentFields);
    }, [documentFields])

    useEffect(() => {
        dispatch(getAllMergeTagsFields(course_id))
    }, [course_id])

    const handleInputChange = (fieldName, value) => {
        setFormValues(prevState => ({
            ...prevState,
            [fieldName]: value
        }))
    }

    const handleRelease = (e) => {
        e.preventDefault();
        dispatch(releaseOfferLetter({ application_id: application_id }))
    }

    const handleViewBtn = (e) => {
        e.preventDefault()
        dispatch(getReleasedLetter({ application_id: application_id }))
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("Hello ji kaise h aap", formValues)
        const data = {
            Student_id: student_id,
            application_id: application_id,
            course_id: course_id,
            ...formValues
        }
        console.log("My whole data is ", data)
        dispatch(generateOfferLetter(data))
    }
    return (
        <div
            className="tab-pane fade show active"
            id="pills-viewemployment"
            role="tabpanel"
            aria-labelledby="pills-employment-tab"
        >
            <PostChatNotify />

            <form onSubmit={handleSubmit}>

                <div className="form-area">
                    <div className="form-head">
                        Your Employment Details{" "}
                        <span>We will use your document details while processing your applications</span>
                    </div>
                    <div className="form-subhead">
                        <hr />
                    </div>
                    <div className="row clearfix">
                        {
                            documentFields?.map((field, index) => (
                                <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6" key={index}>
                                    <label className={'form-check-label'} htmlFor={field.field_name}>{field.field_name}</label>
                                    {field.data_type === 'dropdown' ?
                                        <select id={field.field_name}
                                            name={field.field_name}
                                            className="form-select"
                                            onChange={e => handleInputChange(field.field_name, e.target.value)}
                                        >
                                            {field?.values?.split(',').map((value, index) => (
                                                <option key={index} >{value}</option>
                                            ))}

                                        </select>
                                        :
                                        <input
                                            type={field.data_type}
                                            id={field.field_name}
                                            name={field.field_name}
                                            className="form-control"
                                            value={field.type === "default" ? generateValue(field.field_name) : formValues[field.field_name]}
                                            placeholder={`Enter your ${field.field_name}`}
                                            onChange={e => handleInputChange(field.field_name, e.target.value)}
                                        />}

                                </div>
                            ))
                        }
                    </div>

                    <div className='container mt-3'>
                        <div className='row justify-content-end clearfix'>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 text-end p-4 form-bottom" >
                                <button className="btn btn-purple me-1" onClick={() => { setActiveTab("viewemployment") }}>Previous</button>
                                {/* {status !== 200 && } */}


                                {
                                    (!offerLetterCounsellor && !offerLetterStudent) && (
                                        <button className="btn btn-purple me-1" type='submit'>Generate Offer Letter</button>
                                    )

                                }
                                {
                                    (offerLetterCounsellor && !offerLetterStudent) && (
                                        <button type='button' className='btn btn-purple' onClick={handleRelease}>Release</button>
                                    )

                                }
                                {
                                    (offerLetterCounsellor && offerLetterStudent) && (
                                        <button type='button' className='btn btn-purple' onClick={handleViewBtn}>View</button>
                                    )
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}
export default ViewCounsellorStudentDocumentDetails