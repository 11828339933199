import React, { useState } from 'react'
import { AiOutlineHeart } from 'react-icons/ai'
import { FaRegShareFromSquare } from 'react-icons/fa6'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { AiFillEdit } from "react-icons/ai";
import UpdateUniversityModal from '../Modals/UpdateUniversityModal'
import { getUniversityDetailsId } from '../../@redux/dispatch/university'

function UniversityListCounsellor({ searchData }) {
    const Universities = useSelector((state) => (state?.universityList?.counUniversityList))
    const [isModalOpen, setIsModalOpen] = useState(false);
    const dispatch = useDispatch()

    console.log("universities list", Universities)
    const openModal = (e, Id) => {
        e.preventDefault();
        dispatch(getUniversityDetailsId(Id))

        setIsModalOpen(true);
    };
    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        Universities?.filter((university) => {
            return searchData.toLowerCase() === '' ?
                university : university?.universityResponse?.Name.toLowerCase().includes(searchData);
        })?.map((university, idx) => (
            <li key={idx}>
                <div>
                    <div className="course-box clearfix">
                        <div className="row clearfix">
                            <div className="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-xxl-3">
                                <div className="course-imgholder tablet desktop">
                                    <img
                                        src="images/dummy-image.png"
                                        alt="Image goes here"
                                    />
                                    <div className="university-logo">
                                        <img
                                            src="images/course-university.png"
                                            alt="Image goes here"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-sm-8 col-md-8 col-lg-9 col-xl-9 col-xxl-9">
                                <div className="course-details">
                                    <div className="row clearfix">
                                        <div className="col-12 col-md-8 col-lg-8 col-xl-9 col-xxl-9">
                                            <h2>{university?.universityResponse?.Name}</h2>
                                            <div className="info-area">
                                                <h3>
                                                    {university?.universityResponse?.Name}{", "}{university?.universityResponse?.Country}
                                                </h3>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-4 col-lg-4 col-xl-3 col-xxl-3 text-end">
                                            <Link className='me-3' title='edit university' role='button' onClick={(e) => { openModal(e, university?.universityResponse?.id) }}><AiFillEdit size={25} /></Link>
                                            <UpdateUniversityModal isOpen={isModalOpen} closeModal={closeModal} />
                                            <a href="">Visit Website</a>
                                        </div>
                                    </div>
                                    <p className="course-text content-desktop">
                                        <span>About the University</span>
                                        {university?.universityResponse?.University_Description}
                                        <span>English Requirement</span>
                                        {university?.universityResponse?.English_Requirement}
                                    </p>
                                    {/* <div className='d-flex justify-content-end '>
                                    <button className='btn btn-gradient'>Show Courses</button>
                                </div> */}
                                    <p className="course-text content-mobile">
                                        <span>About the University</span>
                                        {university?.universityResponse?.University_Description}
                                        <span>English Requirement</span>
                                        {university?.universityResponse?.English_Requirement}
                                    </p>
                                    <a
                                        href="#"
                                        className="btn-more readMore d-md-none d-lg-none d-xl-none d-xxl-none"
                                    >
                                        More Details
                                        <i className="bi bi-chevron-down" />
                                    </a>
                                </div>
                            </div>

                        </div>

                    </div>
                    <div className="uni-course">
                        {/* <h4 className="uni-course-title">Courses</h4> */}
                        <table className="table table-borderless course-table w-100 responsive-tables">
                            <thead>
                                <tr>
                                    <th>Course Name</th>
                                    <th>Duration</th>
                                    <th>Level</th>
                                    <th ></th>
                                </tr>
                            </thead>

                            <tbody>
                                {university?.courseListData?.map((courseData, idx) => {
                                    return <tr key={idx}>
                                        <td data-title="Course">
                                            {courseData?.Course_List?.name}
                                        </td>
                                        <td data-title="Duration">{courseData?.Duration}</td>
                                        <td data-title="Level">{courseData?.Course_Level}</td>
                                        <td className=''>
                                            <div className="action-tools ">
                                                <Link to="#" className="btn-circle">
                                                    <FaRegShareFromSquare size={16} />
                                                </Link>
                                                <Link to="#" className="btn-circle">
                                                    <AiOutlineHeart size={16} />
                                                </Link>
                                                <Link to={"/create-application?course=" + courseData?.Course_List?.name} className="btn btn-purple">
                                                    Apply
                                                </Link>
                                            </div>
                                        </td>
                                    </tr>
                                })}

                            </tbody>
                        </table>
                    </div>
                </div>
            </li>
        ))
    )
}

export default UniversityListCounsellor