import axios from "axios";
import { agentAddStudentStart, agentAddStudentSuccess } from "../../../actions/agents/addStudent";
import { closeLoader, openLoader } from "../../../actions/config";
import apiUrl from "../../../ApiUrl";

export const agentAddStudent = (body, navigate) => async (dispatch, getState) => {
    const baseUrl = getState().config.baseUrl
    dispatch(agentAddStudentStart());
    dispatch(openLoader());
    try {
        const response = await axios.post(

            `${apiUrl}/api/agent/create/student`,
            body
        );
        if (response) {
            dispatch(agentAddStudentSuccess(response.data))
        }

    } catch (error) {
        console.log(error)
    }
    finally {
        dispatch(closeLoader());
    }
};



