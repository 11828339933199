export default [{ "--color50": "#FDF3F4", "--color100": "#FBE8EB", "--color200": "#F6D5DA", "--color300": "#EA9DAA", "--color400": "#E58799", "--color500": "#D75C77", "--color600": "#C13D60", "--color700": "#A22E4F", "--color800": "#882947", "--color900": "#752642", "--color950": "#411020" },
{ "--color50": "#F0FDF9", "--color100": "#CBFCEE", "--color200": "#97F8DD", "--color300": "#5BEDCA", "--color400": "#27D4B0", "--color500": "#10BC9B", "--color600": "#0A977E", "--color700": "#0D7867", "--color800": "#0F6053", "--color900": "#124F46", "--color950": "#03302B" },
{ "--color50": "#FDF5FE", "--color100": "#FCEAFD", "--color200": "#F7D4FA", "--color300": "#F3B3F4", "--color400": "#ED85ED", "--color500": "#DF56DF", "--color600": "#C237BF", "--color700": "#A12A9C", "--color800": "#84247F", "--color900": "#73256D", "--color950": "#470B42" },
{ "--color50": "#F9F7F7", "--color100": "#F4EFF1", "--color200": "#EAE1E3", "--color300": "#D8C9CD", "--color400": "#C2AAB1", "--color500": "#AF929B", "--color600": "#906E7B", "--color700": "#775964", "--color800": "#654C56", "--color900": "#58434D", "--color950": "#2F2228" },
{ "--color50": "#FFF4FF", "--color100": "#FEE8FF", "--color200": "#FED0FE", "--color300": "#FCABF9", "--color400": "#F979F2", "--color500": "#EE35E3", "--color600": "#D326C5", "--color700": "#AF1CA0", "--color800": "#8F1982", "--color900": "#751A69", "--color950": "#4E0444" },
{ "--color50": "#F3FAF4", "--color100": "#E3F5E6", "--color200": "#C8EACE", "--color300": "#9DD8A7", "--color400": "#6BBD7A", "--color500": "#46A157", "--color600": "#358444", "--color700": "#2D6838", "--color800": "#275430", "--color900": "#22452A", "--color950": "#0E2514" },
{ "--color50": "#F1FCF9", "--color100": "#CEF9EB", "--color200": "#9DF2D7", "--color300": "#65E3C1", "--color400": "#35CCA8", "--color500": "#1CB08F", "--color600": "#138E75", "--color700": "#147260", "--color800": "#155A4E", "--color900": "#164B42", "--color950": "#062D28" },
{ "--color50": "#F9F7F7", "--color100": "#F3EEED", "--color200": "#E9E0DF", "--color300": "#D9CAC8", "--color400": "#C1ABA8", "--color500": "#A98D89", "--color600": "#927470", "--color700": "#79605C", "--color800": "#66514E", "--color900": "#574745", "--color950": "#2D2322" },
{ "--color50": "#F6F3FF", "--color100": "#EEE8FF", "--color200": "#DED5FF", "--color300": "#C7B3FF", "--color400": "#AC88FD", "--color500": "#965FFA", "--color600": "#8436F1", "--color700": "#7524DD", "--color800": "#621DBA", "--color900": "#511A98", "--color950": "#310E67" },
{ "--color50": "#FCF3F8", "--color100": "#FAE9F3", "--color200": "#F7D3E7", "--color300": "#F2AFD4", "--color400": "#E97DB6", "--color500": "#DE569A", "--color600": "#CB377B", "--color700": "#B02660", "--color800": "#912350", "--color900": "#6E1E3F", "--color950": "#4A0D26" },
{ "--color50": "#FEF1FB", "--color100": "#FDE6F8", "--color200": "#FECCF4", "--color300": "#FFA2EA", "--color400": "#FD69D9", "--color500": "#F730C1", "--color600": "#E81AA6", "--color700": "#CA0C88", "--color800": "#A60E6F", "--color900": "#8A115E", "--color950": "#550236" },
{ "--color50": "#F0F8FE", "--color100": "#DDEEFC", "--color200": "#C3E3FA", "--color300": "#9AD2F6", "--color400": "#6AB9F0", "--color500": "#479DEA", "--color600": "#2D7EDE", "--color700": "#296CCC", "--color800": "#2757A6", "--color900": "#254B83", "--color950": "#1B2F50" },
{ "--color50": "#FCFAEA", "--color100": "#F8F5C9", "--color200": "#F3E995", "--color300": "#ECD658", "--color400": "#E3BF21", "--color500": "#D5AB1D", "--color600": "#B78517", "--color700": "#926116", "--color800": "#7A4D19", "--color900": "#68401B", "--color950": "#3C220C" },
{ "--color50": "#F4F9F8", "--color100": "#DAEDE9", "--color200": "#B4DBD4", "--color300": "#87C1B9", "--color400": "#5DA49D", "--color500": "#448883", "--color600": "#3A7975", "--color700": "#2D5856", "--color800": "#274847", "--color900": "#243D3C", "--color950": "#102322" },
{ "--color50": "#E9F2FF", "--color100": "#D7E8FF", "--color200": "#B6D4FF", "--color300": "#8AB6FF", "--color400": "#5D88FF", "--color500": "#385CFF", "--color600": "#152BFF", "--color700": "#1427F7", "--color800": "#0D1FC6", "--color900": "#15269A", "--color950": "#0C135A" },
{ "--color50": "#F3F2FF", "--color100": "#EAE7FF", "--color200": "#D8D3FF", "--color300": "#BCB0FF", "--color400": "#9A83FF", "--color500": "#7B51FF", "--color600": "#6B2DFA", "--color700": "#5C1BE6", "--color800": "#5017C9", "--color900": "#40149E", "--color950": "#250A6B" },
{ "--color50": "#F5F3FF", "--color100": "#EDEAFD", "--color200": "#DDD7FD", "--color300": "#C3B7FB", "--color400": "#A78FF6", "--color500": "#8A61F1", "--color600": "#8049E8", "--color700": "#6A2ED3", "--color800": "#5926B1", "--color900": "#4A2191", "--color950": "#2D1362" },
{ "--color50": "#EBF0FF", "--color100": "#DBE3FF", "--color200": "#BFCBFF", "--color300": "#98A8FF", "--color400": "#6F7AFF", "--color500": "#4F4EFF", "--color600": "#402EFD", "--color700": "#3522E0", "--color800": "#2F21C1", "--color900": "#28228D", "--color950": "#1A1452" },
{ "--color50": "#FEFBEC", "--color100": "#FDF2C8", "--color200": "#FAE48D", "--color300": "#F7D052", "--color400": "#F5BC2A", "--color500": "#EF9B11", "--color600": "#D3770C", "--color700": "#AF540E", "--color800": "#8E4112", "--color900": "#753612", "--color950": "#542106" },
{ "--color50": "#F1FCF2", "--color100": "#DFF9E4", "--color200": "#C0F2CB", "--color300": "#73DF8B", "--color400": "#57D173", "--color500": "#31B64F", "--color600": "#23963D", "--color700": "#1F7633", "--color800": "#1D5E2D", "--color900": "#1A4D27", "--color950": "#092A12" },
{ "--color50": "#EDFEE7", "--color100": "#D7FBCC", "--color200": "#B1F79F", "--color300": "#81EF67", "--color400": "#49E028", "--color500": "#36C81A", "--color600": "#24A010", "--color700": "#1F7A11", "--color800": "#1D6014", "--color900": "#1B5215", "--color950": "#092D06" },
{ "--color50": "#FAF8FC", "--color100": "#F4EFF8", "--color200": "#EBE1F3", "--color300": "#DBCAE8", "--color400": "#C3A7D9", "--color500": "#AB85C7", "--color600": "#8E5FAF", "--color700": "#7E559B", "--color800": "#6A4980", "--color900": "#573C67", "--color950": "#3A2249" },
{ "--color50": "#FCF3F9", "--color100": "#FAE9F5", "--color200": "#F6D4EA", "--color300": "#EFB2D9", "--color400": "#E581BF", "--color500": "#D95BA5", "--color600": "#C53A85", "--color700": "#AA2C6C", "--color800": "#8D275A", "--color900": "#76254D", "--color950": "#47102B" },
{ "--color50": "#F2FBFA", "--color100": "#D4F3F0", "--color200": "#A9E6E1", "--color300": "#76D2CE", "--color400": "#4AB7B6", "--color500": "#34A6A7", "--color600": "#247A7D", "--color700": "#216164", "--color800": "#1E4F51", "--color900": "#1D4244", "--color950": "#0B2628" },
{ "--color50": "#F0F9FE", "--color100": "#DCF1FD", "--color200": "#C1E8FC", "--color300": "#97DAF9", "--color400": "#66C4F4", "--color500": "#42A9EF", "--color600": "#2C8DE4", "--color700": "#2476D1", "--color800": "#2360AA", "--color900": "#225186", "--color950": "#193252" },
{ "--color50": "#FDF6FC", "--color100": "#FAEDF9", "--color200": "#F4DAF1", "--color300": "#EBBCE4", "--color400": "#DE94D2", "--color500": "#CB6ABB", "--color600": "#A84896", "--color700": "#903B7F", "--color800": "#763267", "--color900": "#622D55", "--color950": "#3E1434" },
{ "--color50": "#FDF2F9", "--color100": "#FBE8F5", "--color200": "#FAD0ED", "--color300": "#F7AADE", "--color400": "#EF6AC0", "--color500": "#E74DAC", "--color600": "#D62C8D", "--color700": "#B91D71", "--color800": "#991B5E", "--color900": "#801B50", "--color950": "#4E092D" }]