import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isAgentStudents: false,
    agentStudentsMessage: "",
    error: false,
    agentStudentsList: [],
    loading: true
}

export const getAgentStudentsSlice = createSlice({
    name: 'getAgentStudents',
    initialState,
    reducers: {
        getAgentStudentsStart: (state) => {
            return { ...state, agentStudentsMessage: "Getting agent students list started ...", error: false, };
        },
        getAgentStudentsSuccess: (state, action) => {
            return { ...state, agentStudentsMessage: "All agent students fetched successfully", error: false, isAgentStudents: true, agentStudentsList: action.payload, loading: false };
        },
        resetAgentStudents: (state) => {
            return { ...state, agentStudentsList: [], loading: true }
        }
    }
})

export const { getAgentStudentsStart, getAgentStudentsSuccess, resetAgentStudents } = getAgentStudentsSlice.actions;

export default getAgentStudentsSlice.reducer;