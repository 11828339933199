import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    fetch_dns_record: {},
    error: false,
}

export const fetchDNSSlice = createSlice({
    name: 'fetch_dns',
    initialState,
    reducers: {
        fetch_dns_success: (state, action) => {
            return { ...state, fetch_dns_record: action.payload };
        },

    }
})

export const { fetch_dns_success } = fetchDNSSlice.actions;

export default fetchDNSSlice.reducer;
