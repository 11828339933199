import React from 'react';
import {
    Modal
} from 'reactstrap';

function ModalSchema(props) {
    const { content, isOpen, setIsOpen, outerClick = true } = props;
    const isMobileDevice = window.innerWidth <= 768;

    const toggleModal = () => {
        setIsOpen(!isOpen || outerClick);
    };

    return (
        <Modal
            isOpen={isOpen}
            toggle={toggleModal}
            {...props}
            centered
            fullscreen={isMobileDevice}
        >
            {content}
        </Modal>
    );
}

export default ModalSchema;
