import React, { useEffect, useRef, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import Select from 'react-select'
import { useDispatch, useSelector } from 'react-redux';
import { getCourseOptions } from '../../../@redux/referencesDetailsForm';
import { setCourseId, setFormDataFirstPart, setSelectedCourse } from '../../../@redux/actions/applications/CreateApplication';

function ReferenceDetailsForm({ setActiveTab }) {
    // const [selectedCourse, setSelectedCourse] = useState({})
    // const [formData1, setFormData1] = useState({ Preferred_intake: '', Last_Qualification: '', IELTS_PTE_score: '', English_Language_Certificate_IF_ANY: '', Were_you_introduced_to_TEMC_by_and_Agent_or_a_Coun: '' })
    const location = useLocation();
    const selectedCourse = useSelector(state => state.createNewApplication.selectedCourse)
    const formData1 = useSelector(state => state.createNewApplication.createApplicationFormData)
    const queryParams = new URLSearchParams(location.search)
    const course = queryParams.get('course');

    const debouncedSetStateRef = useRef(null);

    const DEBOUNCE_TIME = 300;

    const handleSearchChange = (value) => {
        if (!value) {
            return
        }
        clearTimeout(debouncedSetStateRef.current);

        debouncedSetStateRef.current = setTimeout(() => {
            console.log(value);
            dispatch(getCourseOptions(value))
        }, DEBOUNCE_TIME);
    };

    const dispatch = useDispatch()

    const handleInputChange = (e) => {

        const { name, value } = e.target;
        dispatch(setFormDataFirstPart({
            ...formData1,
            [name]: value,
        }))
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!selectedCourse.value) {
            // Display an error message or handle the validation as per your requirement
            alert("Please fill in Choose Course option ");

            return;
        }
        console.log("references form Data", formData1);
        setActiveTab("employment");
    }

    useEffect(() => {
        // console.log(selectedCourse, 777)
        if (selectedCourse?.value) {
            dispatch(setCourseId(selectedCourse?.value))
        }

    }, [selectedCourse])

    const courseOptions = useSelector(state => state.course?.courseOptions);
    const metaFields = useSelector(state => state.metaFields?.fieldsList?.fields)

    useEffect(() => {
        if (course)
            dispatch(getCourseOptions(course))
    }, [course])

    useEffect(() => {
        if (courseOptions?.length > 0 && course)
            dispatch(setSelectedCourse(courseOptions[0]))
        else if (!selectedCourse)
            dispatch(setSelectedCourse({}))
    }, [courseOptions])


    return (

        <div
            className="tab-pane fade show active"
            id="pills-references"
            role="tabpanel"
            aria-labelledby="pills-references-tab"
        >
            <form onSubmit={handleSubmit}>

                <div className="form-area">
                    <div className="form-head">
                        Your Academic Details {" "}
                        <span>We will use your academic details while processing your applications</span>
                    </div>
                    <div className="form-subhead">
                        <hr />
                    </div>
                    <div className="row clearfix">
                        <div className="col-12">
                            <label className='form-check-label' htmlFor='courseChoosen'>Choose Course</label>
                            {/* <Select className='p-3' placeholder="Choose Course" options={courses} onInputChange={handleSearchChange} /> */}
                            <Select
                                className='p-2 m-2'
                                value={selectedCourse}
                                options={courseOptions}
                                onInputChange={handleSearchChange}
                                onChange={(val) => dispatch(setSelectedCourse(val))}
                                required={true}
                            />
                        </div>
                        {Object.keys(selectedCourse)?.length > 0 && <>
                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                <label className='form-check-label'>Duration</label>
                                <input
                                    type="text"
                                    id='duration'
                                    name="duration"
                                    className="form-control"
                                    placeholder="Duration"
                                    value={selectedCourse?.duration || "NA"}
                                    readOnly
                                />
                            </div>
                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                <label className='form-check-label'>Country</label>
                                <input
                                    type="text"
                                    id='country'
                                    name="country"
                                    className="form-control"
                                    placeholder="Country"
                                    value={selectedCourse?.country || "NA"}
                                    readOnly
                                />
                            </div>
                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                <label className='form-check-label'>Course level</label>
                                <input
                                    type="text"
                                    id='courselevel'
                                    name="courselevel"
                                    className="form-control"
                                    placeholder="Course level"
                                    value={selectedCourse?.level || "NA"}
                                    readOnly
                                />
                            </div>
                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                <label className='form-check-label'>Type</label>
                                <input
                                    type="text"
                                    id='type'
                                    name="type"
                                    className="form-control"
                                    placeholder="Type"
                                    value={selectedCourse?.type || "NA"}
                                    readOnly
                                />
                            </div>
                        </>}
                        <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                            <label className='form-check-label' htmlFor='Preferred_intake'>Choose preferred in take</label>
                            <select className="form-select" name="Preferred_intake" id="Preferred_intake" onChange={handleInputChange} value={formData1?.Preferred_intake} required>
                                <option key={"-1"} value='' disabled>-select-</option>
                                {metaFields?.[10]?.pick_list_values.map((meta, index) => (
                                    <option key={index} value={meta.display_value}>
                                        {meta.display_value}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                            <label className='form-check-label'>Last qualification</label>
                            <input type="text" name="Last_Qualification" id="Last_Qualification" className="form-control" placeholder="Last qualification" onChange={handleInputChange} value={formData1?.Last_Qualification} required />
                        </div>
                        <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                            <label className='form-check-label'>IELTS PTE Score</label>
                            <input
                                type="text"
                                id='IELTS_PTE_score'
                                name="IELTS_PTE_score"
                                className="form-control"
                                placeholder="IELTS PTE Score"
                                value={formData1?.IELTS_PTE_score}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                            <label className='form-check-label' htmlFor='English_Language_Certificate_IF_ANY'>Do you have any English language certificate</label>
                            <select className="form-select" name="English_Language_Certificate_IF_ANY" id="English_Language_Certificate_IF_ANY" value={formData1?.English_Language_Certificate_IF_ANY} onChange={handleInputChange} required>
                                <option value='' disabled>-select-</option>
                                <option value="No">No</option>
                                <option value="Yes">Yes</option>
                            </select>
                        </div>
                        <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                            <label className='form-check-label' htmlFor='Were_you_introduced_by_an_agent'>Are you introduced by an agent or counsellor?</label>
                            <select className="form-select" name="Were_you_introduced_by_an_agent" id="Were_you_introduced_by_an_agent" value={formData1?.Were_you_introduced_to_TEMC_by_and_Agent_or_a_Coun} onChange={handleInputChange} required>

                                {metaFields?.[26]?.pick_list_values.map((meta, index) => (
                                    <option key={index} value={meta.display_value}>
                                        {meta.display_value}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <div className='container'>
                        <div className='row justify-content-end clearfix'>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 text-end p-4 form-bottom" >
                                <button className="btn btn-theme me-1" onClick={() => { setActiveTab("address") }}>Previous</button>
                                <button className="btn btn-theme " type='submit'>Next</button>
                            </div>
                        </div>
                    </div>
                </div>

            </form >
        </div >

    )
}
export default ReferenceDetailsForm