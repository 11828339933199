import axios from "axios";
import setBodyColor from "../../../setBodyColor";
import { fetch_dns_success } from "../../actions/fetch_dns";
import apiUrl from "../../ApiUrl";
import toastr from "toastr";

export const fetch_DNS = (body) => async (dispatch, getState) => {
    const baseUrl = getState().config.baseUrl
    console.log("base url for production in production pipeline", baseUrl)
    console.log('url is here as :- ', apiUrl);

    try {

        const response = await axios.post(`${apiUrl}/api/dns/fetch`, body);
        if (response && response.data && !response.data.error) {
            console.log("response i am getting for dns fetch", response.data)
            console.log("hue value i am getting is", response.data.hueVal)
            dispatch(fetch_dns_success(response.data))
            if (response.data?.hueVal !== null)
                setBodyColor(response.data?.hueVal)

            // alert("Application submitted successfully")
        }
        else if (response && response.data && response.data.error) {
            toastr.error(response.data.error)
        }
        else {
            throw new Error('No data received from the server');
        }
    }
    catch (error) {
        console.log("error is here:- ", error);
    }

};