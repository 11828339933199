import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import apiUrl from "../ApiUrl";

const initialState = {
    isSignup: false,
    code: "",
    signupMessage: "",
    error: false
}

export const signupSlice = createSlice({
    name: 'signup',
    initialState,
    reducers: {
        signupStart: (state) => {
            return { ...state, signupMessage: "Signing in...", error: false };
        },
        signupSuccess: (state, action) => {
            return { ...state, loginMessage: "Signup success", error: false, isSignup: true, code: action.payload };
        }
    }
})

export const { signupStart, signupSuccess } = signupSlice.actions;

export const signup = (body) => async (dispatch, getState) => {
    const baseUrl = getState().config.baseUrl
    dispatch(signupStart())
    try {

        const response = await axios.post(

            `${apiUrl}/api/signup`,
            body
        );
        dispatch(signupSuccess(response.data?.data[0]?.code))
        console.log(response.data);

    }
    catch (error) {
        console.log("error is here:- ", error);
    }

}

export default signupSlice.reducer;