import React from 'react'

function TestimonialModal() {
    return (
        <>
            <div
                className="modal fade"
                id="testimonial"
                data-bs-keyboard="false"
                tabIndex={-1}
                aria-labelledby="staticBackdropLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        />
                        <video
                            src="images/v-video.mp4"
                            type="video/mp4"
                            controls=""
                            playsInline=""
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default TestimonialModal