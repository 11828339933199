import axios from "axios";
import { getCustomTagsStart, getCustomTagsSuccess } from "../../../actions/template/customMergeTags";
import { createTemplateStart, createTemplateSuccess, getTemplateContentStart, getTemplateContentSuccess, setEditorReady, updateTemplateStart, updateTemplateSuccess } from "../../../actions/template/templateContent";
import { closeLoader, openLoader } from "../../../actions/config";
import apiUrl from "../../../ApiUrl";

export const getTemplate = (body) => async (dispatch, getState) => {
    const baseUrl = getState().config.baseUrl
    dispatch(getTemplateContentStart());
    try {
        dispatch(setEditorReady(false))
        const response = await axios.get(`${apiUrl}/api/template/search/${body.id}`);
        dispatch(getTemplateContentSuccess(response.data))
        dispatch(setEditorReady(true))
    } catch (error) {
        console.log(error)
    }
};

export const createTemplate = (body) => async (dispatch, getState) => {
    const baseUrl = getState().config.baseUrl
    dispatch(createTemplateStart())
    dispatch(openLoader());
    try {
        const response = await axios.post(
            `${apiUrl}/api/template/create`,
            body,
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
        );
        console.log("Response I am getting as", response);
        if (response.status === 200) {
            // alert("Your template saved successfully")
        }
        dispatch(createTemplateSuccess());
    }

    catch (error) {
        console.log(error)
    }
    finally {
        dispatch(closeLoader());
    }
}

export const updateTemplate = (body) => async (dispatch, getState) => {
    const baseUrl = getState().config.baseUrl
    dispatch(updateTemplateStart())
    try {
        const response = await axios.patch(`${apiUrl}/api/template/update`, body);
        // const response = await axios.post(`http://localhost:5000/api//api/template/create/fields/batch/`, body);
        console.log("Response I am getting as", response);
        dispatch(updateTemplateSuccess(response.data))
    }
    catch (error) {
        console.log(error)
    }
}







