import React, { useEffect, useState } from 'react';
// import details from '../../data.json'
import Footer from '../../components/Footer'
import axios from "axios"
import { Link, useNavigate } from "react-router-dom";
import UnivLeftSidebar from '../../components/UnivLeftSidebar';
import { useDispatch, useSelector } from 'react-redux';
import MobMenuHeader from '../../components/MobMenuHeader';
import { LiaGreaterThanSolid } from "react-icons/lia"
import BottomMenubar from '../../components/BottomMenubar/BottomMenubar';
import DashboardStudent from '../../components/DashboardStudent';
import DashboardAgent from '../../components/DashboardAgent';
import DashboardCounsellor from '../../components/DashboardCounsellor';
import { sessionCheck } from '../../@redux/dispatch/config';
import Loader from '../../components/Loader';
import { getData, getUploadLogo } from '../../@redux/dispatch/customisation_dispatch';

function Dashboard() {
    // const logo = useSelector((state) => (state?.customisation?.get_data?.logoUrl))
    // console.log("logo url for setting index page", logo)
    const domainAttr = useSelector(state => (state.config?.userAttributes))
    const domainValue = domainAttr?.find(item => item.Name === "custom:domainName")?.Value
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userRole = useSelector((state) => (state?.config?.profile_id))
    useEffect(() => {
        dispatch(sessionCheck(navigate))
    }, []);

    //Below is used for branding in setting page
    useEffect(() => {

        if (userRole === 'agent') {
            if (domainValue !== undefined) {
                dispatch(getData({ domainName: domainValue }))
            }
        }


    }, [])

    return (
        <div className="col-sm-12 col-md-12 ms-sm-auto col-lg-12 col-xl-10 col-xxl-10 ">
            {/* <Loader /> */}
            {userRole === 'student' && <DashboardStudent />}
            {userRole === 'agent' && <DashboardAgent />}
            {userRole === 'counsellor' && <DashboardCounsellor />}
            <BottomMenubar />

            {/* <Footer /> */}
        </div>
    )
}

export default Dashboard