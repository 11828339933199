import React from 'react'
import { NavLink } from 'react-router-dom'
import { RiSearchLine, RiUser3Line } from "react-icons/ri";
import { AiOutlineBank, AiOutlineMenu } from "react-icons/ai";
import { RxDashboard } from 'react-icons/rx';
import { CgNotes } from 'react-icons/cg';
import { PiPresentationBold } from 'react-icons/pi';
import { FiSettings } from 'react-icons/fi';
import { BiLogOut } from 'react-icons/bi';
import { useSelector } from 'react-redux';
function MobMenuHeader() {
    const imgUrl = useSelector(state => state.customisation.logoUrlRes)

    return (

        <header className=" mobile tablet sticky-top bg-white mb-4">
            <div className="container-fluid">
                <div className="row align-items-center clearfix">
                    <div className="col-6 text-start">
                        <NavLink to="/" activeClassName="active">
                            <img src={(imgUrl && imgUrl?.logo && imgUrl?.logo?.endsWith("undefined")) ? '/images/logo.svg' : imgUrl?.logo} width={151} height={24} />
                            {/* <img src='https://image-upload-s3-api.s3.us-east-2.amazonaws.com/logo.svg' width={151} height={24} /> */}
                        </NavLink>
                    </div>
                    <div className="col-6">
                        <ul className="toolbar">
                            <li>
                                <NavLink to="#" className="bell" activeClassName="active">
                                    <RiSearchLine size={25} />
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    data-bs-toggle="offcanvas"
                                    to="#mobileMenu"
                                    role="button"
                                    aria-controls="mobileMenuExample"
                                    activeClassName="active"
                                >
                                    <AiOutlineMenu size={25} />
                                </NavLink>
                                <div
                                    className="offcanvas offcanvas-end "
                                    tabIndex={-1}
                                    id="mobileMenu"
                                    aria-labelledby="mobileMenuExampleLabel"
                                >
                                    <div className="offcanvas-header">
                                        <button
                                            type="button"
                                            className="btn-close"
                                            data-bs-dismiss="offcanvas"
                                            aria-label="Close"
                                        />
                                    </div>
                                    <div className="offcanvas-body ">
                                        <ul className="nav mobileNav flex-column">
                                            <li className="nav-item">
                                                <NavLink
                                                    className="nav-link "
                                                    aria-current="page"
                                                    to="/dashboard"
                                                    activeClassName="active"
                                                >
                                                    <RxDashboard /> Dashboard
                                                </NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink className="nav-link" to="/applications" activeClassName="active">
                                                    <CgNotes /> Applications
                                                </NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink className="nav-link" to="/courses" activeClassName="active">
                                                    <PiPresentationBold /> Courses
                                                </NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink className="nav-link" to="/universities" activeClassName="active">
                                                    <AiOutlineBank /> Universities
                                                </NavLink>
                                            </li>
                                            <hr />
                                            <li className="nav-item">
                                                <NavLink className="nav-link" to="/#" activeClassName="active">
                                                    <RiUser3Line /> Profile
                                                </NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink className="nav-link" to="/branding" activeClassName="active">
                                                    <FiSettings /> Branding
                                                </NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink className="nav-link" to="/#" activeClassName="active">
                                                    <BiLogOut /> Logout
                                                </NavLink>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </li>

                        </ul>
                    </div>
                </div>
            </div>
        </header>

    )
}

export default MobMenuHeader