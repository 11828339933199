import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    isMergeTags: false,
    mergeTagsMessage: "",
    error: false,
    mergeTags: []
}

export const getMergeTagsDefaultSlice = createSlice({
    name: 'getMergeTagsDefault',
    initialState,
    reducers: {
        getMergeTagsStart: (state) => {
            return { ...state, mergeTagsMessage: "Fetching merge tags started ...", error: false, };
        },
        getMergeTagsSuccess: (state, action) => {
            return { ...state, mergeTagsMessage: "All merge tags fetched successfully", error: false, isMergeTags: true, mergeTags: action.payload };
        }

    }
})

export const { getMergeTagsStart, getMergeTagsSuccess } = getMergeTagsDefaultSlice.actions;

export default getMergeTagsDefaultSlice.reducer;