import React, { useEffect, useState } from 'react'
import ModalSchema from '../Modals/ModalSchema'
import { useDispatch, useSelector } from 'react-redux'
import {
    Modal
} from 'reactstrap';
import { BeatLoader } from 'react-spinners';
import { useNavigate } from 'react-router-dom';
import { sessionCheck } from '../../@redux/dispatch/config';

function Loader() {
    const dnsFetchRes = useSelector(state => state.fetch_dns?.fetch_dns_record)
    const navigate = useNavigate();
    const dispatch = useDispatch();
    useEffect(() => {
        if (dnsFetchRes.length !== 0) {
            dispatch(sessionCheck(navigate))
        }

    }, []);

    const data = useSelector(state => state.config.loaderMessage)

    const content = <div className='bg-transparent'>
        <div className="d-flex align-items-center justify-content-center" >
            {/* <div className="spinner-border text-primary" style={{ width: '4rem', height: '4rem', borderWidth: '0.3rem' }} role="status">
                <span className="sr-only">Loading...</span>
            </div> */}

            <BeatLoader color="#924DFF" size="30px" />
        </div>

        <h3 className='text-center m-3 text-primary'>{data}</h3>
    </div>


    const [kkk, setIsOpen] = useState(true)
    const [isModalOpen, setIsModalOpen] = useState(true);

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };
    const isOpen = useSelector(state => state.config.loader)


    return (

        <Modal isOpen={(isOpen && isModalOpen)} centered contentClassName='bg-transparent' backdropClassName='opacity-50' >
            {content}
        </Modal>
    )

}

export default Loader