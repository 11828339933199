import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createTemplate } from '../../@redux/dispatch/template/templateContent'
import { Editor } from '@tinymce/tinymce-react'
import { BeatLoader } from 'react-spinners'

function TinyMCEeditor({ combineTags = { current: [] }, editorRef }) {
    const templateContent = useSelector((state) => state.templateContent?.templateContent)
    const isEditorReady = useSelector((state) => state.templateContent?.isEditorReady)

    const [isReady, setIsReady] = useState(false)

    useEffect(() => {
        console.log("combined tags", combineTags)
    }, [])
    // const handleAiRequest = (request, respondWith) => {
    //     respondWith.string(() => Promise.reject("See docs to implement AI Assistant"));
    // };
    useEffect(() => {

        return () => {
            if (editorRef.current) {
                editorRef.current.remove();
            }
        };
    }, [editorRef, combineTags]);

    useEffect(() => {
        if (combineTags.current.length && isEditorReady) {
            setTimeout(() => {
                setIsReady(true)
            }, 100)
        }
    }, [combineTags.current, isEditorReady])

    if (isReady)
        return (
            <Editor
                force_hex_style_colors={true}
                key={combineTags.length}

                apiKey='sl2y13ajfmo2pic6tgq8tjbtod23sbbr32p9got2hzmh1p72'

                onInit={(evt, editor) => editorRef.current = editor}
                initialValue={templateContent || " "}
                // scriptLoading={{ async: true, defer: true, delay: 2000 }}
                init={{
                    // editor_selector: '#my_div_for_toolbar',
                    height: '100%',
                    // fixed_toolbar_container: "#my_div_for_toolbar",
                    menubar: true,
                    // element_format: 'xhtml',
                    // entity_encoding: 'raw',
                    plugins: ' tinycomments mentions anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount checklist mediaembed casechange export formatpainter pageembed permanentpen footnotes advtemplate advtable advcode editimage tableofcontents mergetags powerpaste tinymcespellchecker autocorrect a11ychecker typography inlinecss',
                    toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | align lineheight | tinycomments | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
                    tinycomments_mode: 'embedded',
                    tinycomments_author: 'Author name',
                    mergetags_prefix: '${{',
                    mergetags_suffix: '}}',
                    mergetags_list: combineTags.current,
                    // ai_request: handleAiRequest,

                }}
            />
        )
    return (
        <>
            Loading...
            <BeatLoader className='text-center mt-5 pt-5' color="#924DFF" size="40px" />
        </>
    )



}

export default TinyMCEeditor
