import React from 'react'

function Actions() {
    return (
        <div className='text-center' style={{ height: '77vh' }}>
            <h2 className='  mt-5' style={{ color: '#333' }}>Customization Coming Soon <span role="img" aria-label="excited">🤩</span>...</h2>
            <p className='' style={{ fontSize: '1.2rem', color: '#666', }}>Stay tuned for exciting updates!</p>

        </div>
    )
}

export default Actions