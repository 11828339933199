import axios from "axios";
import { getAgentApplicationsStart, getAgentApplicationsSuccess, getApplicationsStart, getApplicationsSuccess, getCounApplicationsStart, getCounApplicationsSuccess } from "../../actions/applications/GetApplications";
import apiUrl from "../../ApiUrl";

export const getApplications = () => async (dispatch, getState) => {
    dispatch(getApplicationsStart());
    const baseUrl = getState().config.baseUrl
    try {
        const response = await axios.get(

            `${apiUrl}/api/application`
        );
        dispatch(getApplicationsSuccess(response?.data))
    } catch (error) {
        console.log(error)
    }
};

export const getAgentsApplications = () => async (dispatch, getState) => {
    dispatch(getAgentApplicationsStart());
    const baseUrl = getState().config.baseUrl
    try {
        const response = await axios.get(

            `${apiUrl}/api/agent/application-list`
        );
        dispatch(getAgentApplicationsSuccess(response?.data))
    } catch (error) {
        console.log(error)
    }
};

export const getCounsellorsApplications = () => async (dispatch, getState) => {
    dispatch(getCounApplicationsStart());
    const baseUrl = getState().config.baseUrl
    try {
        const response = await axios.get(

            `${apiUrl}/api/counsellor/application-list`
        );
        dispatch(getCounApplicationsSuccess(response?.data))
    } catch (error) {
        console.log(error)
    }
};



