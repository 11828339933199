import axios from "axios";
import { updateStudentStart, updateStudentSuccess } from "../../actions/students/updateStudent";
import { getStudentDetails } from "./getDetails";
import { closeLoader, openLoader } from "../../actions/config";
import apiUrl from "../../ApiUrl";

export const updateStudent = (body) => async (dispatch, getState) => {
    const baseUrl = getState().config.baseUrl
    dispatch(updateStudentStart());
    dispatch(openLoader());
    try {
        const response = await axios.put(

            `${apiUrl}/api/profile/update/details`,
            body
        );
        if (response.data) {
            // alert('Profile updated successfully')
            dispatch(getStudentDetails())
        }
        // dispatch(updateStudentSuccess(response.data))
    } catch (error) {
        console.log(error)
    }
    finally {
        dispatch(closeLoader());

    }
};



