import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    addStudentMessage: "",
    error: false,
    agentAddStudentData: {}
}

export const agentAddStudentSlice = createSlice({
    name: 'agentAddStudent',
    initialState,
    reducers: {
        agentAddStudentStart: (state) => {
            return { ...state, addStudentMessage: "adding agent's student  ...", error: false, agentAddStudentData: {} };
        },
        agentAddStudentSuccess: (state, action) => {
            return { ...state, addStudentMessage: "Agent's student created successfully", error: false };
        },
        addStudentFirstPart: (state, action) => {
            return { ...state, addStudentFirstPart: action.payload }
        }

    }
})

export const { agentAddStudentStart, agentAddStudentSuccess, addStudentFirstPart } = agentAddStudentSlice.actions;

export default agentAddStudentSlice.reducer;