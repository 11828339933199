import React from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap';


function NewsletterModal({ isOpen, closeModal }) {
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={closeModal}
            contentLabel="Newsletter Modal"
            className="shadow-lg rounded-5 modal-lg custom-modal-width"

        >
            <ModalHeader className='d-flex justify-content-center' >
                <h1>Newsletter Subscription</h1>
                <button
                    type="button"
                    className="btn-close"
                    onClick={closeModal}

                />

            </ModalHeader>

            <ModalBody >
                <div className='d-flex justify-content-between align-items-center' >

                    <div className='col-5 text-center '>
                        <h1>
                            Fulfil your dreams to study in the UK, US and Australia
                        </h1>
                        <p>Explore, prepare, apply. Ed will help you at each step. We make it simple for you!</p>
                    </div>

                    <div className='col-6'>
                        <form
                        >
                            <div className='row clearfix'>
                                <div className="col-12 ">
                                    <label className='form-check-label' htmlFor='email'>Email</label>
                                    <input className="form-control" id='email' name='email' type='email' placeholder='Enter Your Email...'
                                    />
                                </div>
                                <p className='text-secondary opacity-75'>Additional Information (*optional)</p>
                                <div className="col-12 ">
                                    <label className='form-check-label' htmlFor='fullName'>Full Name</label>
                                    <input className="form-control" id='fullName' name='fullName' type='text' placeholder='Enter Your Name Here...'
                                    />
                                </div>

                                <div className="col-12 ">
                                    <label className='form-check-label' htmlFor='message'>Message</label>
                                    <textarea className="form-control " id='message' name='message' type='text' rows={4} placeholder='Enter Your Message Here....'
                                    />
                                </div>
                            </div>

                            <div className='text-end'>
                                <button className='btn btn-gradient px-5'>
                                    Subscribe
                                </button>
                            </div>

                        </form>
                    </div>
                </div>

            </ModalBody>

        </Modal>

    )
}

export default NewsletterModal