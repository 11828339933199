import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

function ViewEmploymentDetails({ setActiveTab }) {
    const employmentData = useSelector(state => state.applicationDetails?.applicationDetails?.data)
    if (!employmentData) {
        // Handle the case where employmentData is null or undefined
        return <div>No employment data available</div>;
    }
    return (
        <div
            className="tab-pane fade show active"
            id="pills-viewemployment"
            role="tabpanel"
            aria-labelledby="pills-employment-tab"
        >
            <form >
                <div className="form-area">
                    <div className="form-head">
                        Your Employment Details{" "}
                        <span>We will use your employment details while processing your applications</span>
                    </div>
                    <div className="form-subhead">
                        <hr />
                    </div>
                    <div className="row clearfix">
                        <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                            <label className='form-check-label' htmlFor='Disability_or_Impairment'>Any disability or impairment ?</label>
                            <input
                                type="text"
                                id='Disability_or_Impairment'
                                name="Disability_or_Impairment"
                                className="form-control"
                                placeholder="Disability_or_Impairment"
                                value={employmentData && (employmentData?.[0]?.Disability_or_Impairment === 'false' ? 'No' : 'Yes')}
                                disabled
                            />
                        </div>
                        <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                            <label className='form-check-label' htmlFor='How_did_you_hear_about_TEMC'>How do you heard about TEMC</label>
                            <input
                                type="text"
                                id='How_did_you_hear_about_TEMC'
                                name="How_did_you_hear_about_TEMC"
                                className="form-control"
                                placeholder="How_did_you_hear_about_TEMC"
                                value={employmentData && (employmentData?.[0]?.How_did_you_hear_about_TEMC || "NA")}
                                disabled
                            />
                        </div>
                        <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                            <label className='form-check-label' htmlFor='Please_upload_photo_identification_of_yourself'>Please upload photo(s) of identification</label>
                            <div className="field-icon">
                                <input

                                    name="Please_upload_photo_identification_of_yourself"
                                    id="Please_upload_photo_identification_of_yourself"
                                    className="form-control"
                                    placeholder="Upload File"
                                    value={employmentData && (employmentData?.[0]?.Please_upload_photo_identification_of_yourself?.[0]?.file_Name || "NA")}
                                    disabled
                                />
                            </div>
                        </div>

                        <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                            <label className='form-check-label' htmlFor='Academic_Transcript'>Please upload academic transcript(s)</label>
                            <div className="field-icon" >
                                <input name="Academic_Transcript" id="Academic_Transcript" className="form-control" placeholder="Upload File"
                                    value={employmentData && (employmentData?.[0]?.Academic_Transcript?.[0]?.file_Name || "NA")}
                                    // value={"NA"}
                                    disabled />
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                            <label className='form-check-label' htmlFor='Electronic_signature'>Electronic signature</label>
                            <input
                                type="text"
                                id='Electronic_signature'
                                name="Electronic_signature"
                                className="form-control"
                                placeholder="Electronic_signature"
                                value={employmentData && (employmentData?.[0]?.Electronic_signature === false ? 'No' : 'Yes')}
                                disabled
                            />
                        </div>
                        <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                            <label htmlFor='Date' className='form-check-label'>Date </label>
                            <input
                                type="date" name="Date" id="Date" className="form-control" placeholder="date"
                                value={employmentData && (employmentData?.[0]?.Date || "NA")}
                                disabled
                            />
                        </div>
                    </div>

                    <div className='container mt-3'>
                        <div className='row justify-content-end clearfix'>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 text-end p-4 form-bottom" >
                                <button className="btn btn-theme me-1" onClick={() => { setActiveTab("viewreferences") }}>Previous</button>
                                <button className="btn btn-theme me-1" onClick={() => { setActiveTab("viewdocuments") }}>Next</button>
                            </div>
                        </div>
                    </div>
                </div>

            </form >
        </div >

    )
}

export default ViewEmploymentDetails