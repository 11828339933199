import React, { useEffect, useState } from 'react'
import Branding from './branding';
import Themeing from './themeing';
import Portal from './portal';
import { CgWebsite } from "react-icons/cg";
import { IoMdColorWand } from "react-icons/io";
import { FaLink } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';
import { getData, getUploadLogo } from '../../@redux/dispatch/customisation_dispatch';

function Settings() {
    const [activeTab, setActiveTab] = useState('brand')
    const dispatch = useDispatch()
    const domainAttr = useSelector(state => (state.config?.userAttributes))
    const logo = useSelector((state) => (state?.customisation?.get_data?.logoUrl))
    console.log("logo url for setting index page", logo)
    console.log("user attributes are", domainAttr)
    const domainValue = domainAttr?.find(item => item.Name === "custom:domainName")?.Value
    console.log("domain value is ", domainValue)
    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    useEffect(() => {
        // if (domainValue !== undefined) {
        //     dispatch(getData({ domainName: domainValue }))
        // }
        if (logo?.length !== 0) {
            dispatch(getUploadLogo('brandingImg', logo))
        }

    }, [])


    return (
        <div className='col-md-10 ms-auto pe-0 ps-0 bg-white'>
            <nav className="navbar navbar-light bg-white p-0 border d-flex justify-content-center" >
                <span className="navbar-brand ps-3 branding" ><b>BRANDING</b> </span>
            </nav>

            <div className="d-flex justify-content-between">
                <button
                    onClick={() => handleTabClick('brand')}
                    className={`btn border w-100 text-center m-3 p-3 fw-500 ${activeTab === 'brand' ? 'branding-boxShadow' : 'none'}`}
                    style={{
                        fontWeight: activeTab === 'brand' ? 'bold' : 'normal',
                        // boxShadow: activeTab === 'brand' ? '0 0 2px 2px skyblue' : 'none',
                    }}
                ><CgWebsite size={30} /> Brand</button>
                <button
                    onClick={() => handleTabClick('theme')}
                    className={`btn border w-100 text-center m-3 p-3 fw-500 ${activeTab === 'theme' ? 'branding-boxShadow' : 'none'}`}
                    style={{
                        fontWeight: activeTab === 'theme' ? 'bold' : 'normal',
                        // boxShadow: activeTab === 'theme' ? '0 0 2px 2px skyblue' : 'none',
                    }}
                ><IoMdColorWand size={30} /> Theme</button>
                <button
                    onClick={() => handleTabClick('portal')}
                    className={`btn border w-100 text-center m-3 p-3 fw-500  ${activeTab === 'portal' ? 'branding-boxShadow ' : 'none'}`}
                    style={{
                        fontWeight: activeTab === 'portal' ? 'bold' : 'normal',
                        // boxShadow: activeTab === 'portal' ? '0 0 2px 2px skyblue' : 'none',
                    }}
                ><FaLink size={25} /> Portal Link</button>
            </div>

            {/* BRAND */}
            {activeTab === 'brand' && (
                <Branding setActiveTab={setActiveTab} />
            )}



            {/* THEME */}
            {activeTab === 'theme' && (
                <Themeing setActiveTab={setActiveTab} />
            )}

            {/* PORTAL */}
            {activeTab === 'portal' && (
                <Portal setActiveTab={setActiveTab} />
            )}

        </div>
    )
}

export default Settings