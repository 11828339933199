import axios from "axios";
import { getApplicationsStart, getApplicationsSuccess } from "../../actions/applications/GetApplications";
import { getFieldsStart, getFieldsSuccess } from "../../actions/applications/MetaData";
import apiUrl from "../../ApiUrl";

export const getMetaFields = () => async (dispatch, getState) => {
    const baseUrl = getState().config.baseUrl
    dispatch(getFieldsStart());
    try {
        const response = await axios.get(

            `${apiUrl}/api/application/metadata`
        );
        console.log("Available meta fields are :- ", response.data.fields);
        dispatch(getFieldsSuccess(response.data))
    } catch (error) {
        console.log(error)
    }
};



