import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    messageRes: {},
    loading: true,
    error: false
}

export const chatNotifySlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        getChatNotifyStart: (state) => {
            return { ...state, loading: true };
        },
        getChatNotifySuccess: (state, action) => {
            return { ...state, error: false, messageRes: action.payload, loading: false };
        },


    }
})

export const { getChatNotifyStart, getChatNotifySuccess } = chatNotifySlice.actions;

export default chatNotifySlice.reducer;