import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import ModalSchema from '../ModalSchema'
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { createCourse } from '../../../@redux/dispatch/course';

function AddCoursesModal({ isOpen, closeModal }) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const Universities = useSelector((state) => (state?.university?.univeristy_list?.data?.[0]))
    const [formData, setFormData] = useState({});
    const dispatch = useDispatch();


    const handleInputChange = (e) => {
        const { name, value } = e.target
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("Form Data: ", formData)
        dispatch(createCourse(formData, closeModal))
    }

    useEffect(() => {
        console.log("Universities details for course page is ", Universities?.Assigned_Counsellor?.name)
        if (Universities) {
            setFormData({
                ...formData,
                universityName: Universities?.Name,
                universityId: Universities?.id,
                assignedCounsellorName: Universities?.Assigned_Counsellor?.name,
                assignedCounsellorId: Universities?.Assigned_Counsellor?.id

            })
        }

    }, [Universities])

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={closeModal}
            contentLabel="New Application Modal"
            className="shadow-lg rounded-5 modal-lg"
        >
            <ModalHeader className='d-flex justify-content-center'>
                <h1>Add New Courses</h1>
                <button
                    type="button"
                    className="btn-close"
                    // onClick={() => setIsModalOpen(false)}
                    onClick={closeModal}

                />

            </ModalHeader>

            <ModalBody>
                <div>
                    <form onSubmit={handleSubmit}>
                        <div className='row clearfix'>
                            <div className="col-12 ">
                                <label className='form-check-label' htmlFor='courseName'>Course Name</label>
                                <input className="form-control" id='courseName' name='courseName' rows="4" placeholder='Enter Your Course Name Here...' value={formData.courseName} onChange={handleInputChange} required />
                            </div>
                            <div className="col-12 ">
                                <label className='form-check-label' htmlFor='courseDescription'>Course Description</label>
                                <textarea className="form-control" id='courseDescription' name='courseDescription' rows="4" placeholder='Enter Your Description Here...' value={formData.courseDescription} onChange={handleInputChange} required />
                            </div>

                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 ">
                                <label className='form-check-label' htmlFor='courseFee'>Course Fee</label>
                                <input className="form-control" id='courseFee' name='courseFee' placeholder='Enter Course Fees' value={formData.courseFee} onChange={handleInputChange} required />
                            </div>
                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                <label className='form-check-label' htmlFor='country'>Country</label>
                                <input className="form-control" id='country' name='country' placeholder='Enter Your Country' value={formData.country} onChange={handleInputChange} required />
                            </div>
                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 ">
                                <label className='form-check-label' htmlFor='courseURL'>Course URL</label>
                                <input type='url' className="form-control" id='courseURL' name='courseURL' placeholder='Enter Your Course URL' value={formData.courseURL} onChange={handleInputChange} required />
                            </div>
                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 ">
                                <label className='form-check-label' htmlFor='department'>Department</label>
                                <select className="form-select" name="department" id="department" value={formData.department} onChange={handleInputChange} required>
                                    <option value='' >-None-</option>
                                    <option value="Accounting">Accounting</option>
                                </select>
                            </div>
                            <div className="col-12">
                                <label className='form-check-label' htmlFor='universityName'>University Name</label>
                                <input className="form-control" name="universityName" id="universityName" value={Universities?.Name} onChange={handleInputChange} required disabled />

                            </div>
                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 ">
                                <label className='form-check-label' htmlFor='assignedCounsellorName'>Counsellor Name</label>
                                <input type='text' className="form-control" id='assignedCounsellorName' name='assignedCounsellorName' value={Universities?.Assigned_Counsellor?.name} onChange={handleInputChange} placeholder='Enter Your Name' required disabled />
                            </div>

                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 ">
                                <label className='form-check-label' htmlFor='duration'>Duration</label>
                                <select className="form-select" name="duration" id="duration" value={formData.duration} onChange={handleInputChange} required>
                                    <option value='None' >-None-</option>
                                    <option value="39 weeks">39 weeks</option>
                                    <option value="40 weeks">40 weeks</option>
                                    <option value="41 weeks">41 weeks</option>
                                    <option value="42 weeks">42 weeks</option>

                                </select>
                            </div>

                            <div className="col-12 ">
                                <label className='form-check-label' htmlFor='requirement'>English Requirement</label>
                                <textarea className="form-control" id='requirement' name='requirement' placeholder='Enter English Requirement Criteria...' value={formData.requirement} onChange={handleInputChange} required />
                            </div>


                        </div>

                        <div className='text-end'>
                            <button className='btn btn-gradient px-5'>
                                SUBMIT
                            </button>
                        </div>

                    </form>
                </div>
            </ModalBody>

        </Modal>

    )
}

export default AddCoursesModal