import React, { useEffect, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import axios from "axios"
import './left.module.css'
import { RxDashboard } from 'react-icons/rx'
import { PiPresentationBold, PiStudent } from 'react-icons/pi'
import { CgNotes } from 'react-icons/cg'
import { AiOutlineBank } from 'react-icons/ai'
import { RiUser3Line } from 'react-icons/ri'
import { FiSettings } from 'react-icons/fi'
import { BiLogOut } from 'react-icons/bi'
import { useSelector } from 'react-redux'

function LeftSidebar() {
    const userData = useSelector((state) => (state.config?.userData?.Full_Name))


    return (

        <nav
            id="sidebarMenu"
            className=" col-md-4 col-lg-12 col-xl-2 col-xxl-2 d-md-block sidebar desktop tablet collapse overflow-auto"
        >
            <div className="position-sticky pt-3 sidebar-sticky autoScroll ">
                <div className="side-header">
                    <Link to="/">
                        <img
                            src="images/logo.svg"
                            className="img-fluid mx-auto"
                            alt="Image goes here"
                        />

                    </Link>

                    <div className="avatar">
                        <img src="images/profile-pic.png" alt="Image goes here" />
                        <p className="username">{userData}</p>
                    </div>
                </div>
                <ul className="nav flex-column">
                    <li className="nav-item">
                        <NavLink
                            className="nav-link "
                            aria-current="page"
                            to="/dashboard"
                            activeClassName="active"
                        >
                            <RxDashboard /> Dashboard
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink className="nav-link" to="/student-list" activeClassName="active" >
                            <PiStudent /> Students
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink className="nav-link" aria-current="page" to="/counsellors" activeClassName="active">
                            <PiStudent /> Counsellors
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink className="nav-link" to="/agents" activeClassName="active" >
                            <PiStudent /> Agents
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink className="nav-link" to="/applications" activeClassName="active" >
                            <CgNotes /> Applications
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink className="nav-link" to="/courses" activeClassName="active" >
                            <PiPresentationBold /> Courses
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink className="nav-link" to="/universities" activeClassName="active" >
                            <AiOutlineBank /> Universities
                        </NavLink>
                    </li>
                    {/* <li className="nav-item" >
                            <NavLink className="nav-link" to="/learn" activeClassName="active" >
                                <svg
                                    width={14}
                                    height={16}
                                    viewBox="0 0 14 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M12.4545 6.77775C10.4844 6.89296 8.5898 7.58157 6.99992 8.76019C5.41004 7.58157 3.51539 6.89296 1.54537 6.77775V8.58664C1.22336 8.58729 0.914785 8.71685 0.687485 8.94685C0.460186 9.17684 0.332771 9.48844 0.333253 9.81314V10.4267C0.333253 11.1044 1.54537 11.776 1.54537 11.776V13.0025C3.15507 13.1069 4.70747 13.6451 6.04052 14.5609C6.37712 14.7964 6.69764 15.0544 6.99992 15.3333C7.3022 15.0544 7.62272 14.7964 7.95931 14.5609C9.29236 13.6451 10.8448 13.1069 12.4545 13.0025V11.6532C12.7765 11.6525 13.0851 11.523 13.3124 11.293C13.5397 11.063 13.6671 10.7514 13.6666 10.4267V9.81314C13.6671 9.48844 13.5397 9.17684 13.3124 8.94685C13.0851 8.71685 12.7765 8.58729 12.4545 8.58664V6.77775ZM6.99992 13.7579C5.74294 12.829 4.29171 12.2027 2.7575 11.927V8.16864C3.9333 8.40748 5.04966 8.88169 6.04052 9.56319C6.37712 9.79872 6.69764 10.0568 6.99992 10.3356C7.3022 10.0568 7.62272 9.79872 7.95931 9.56319C8.95013 8.88147 10.0665 8.40706 11.2423 8.16803V11.927C9.70813 12.2027 8.2569 12.829 6.99992 13.7579ZM6.39386 5.55553H7.60598V6.16664H6.39386V5.55553ZM6.39386 3.11108H7.60598V3.7222H6.39386V3.11108Z"
                                        fill="#110F33"
                                    />
                                    <path
                                        d="M6.39367 6.77779H7.60579V7.3889H6.39367V6.77779ZM5.18155 3.72223C5.34229 3.72223 5.49644 3.65785 5.6101 3.54324C5.72376 3.42863 5.78761 3.2732 5.78761 3.11112C5.78818 3.03151 5.77304 2.95258 5.74309 2.87892C5.71314 2.80527 5.66896 2.73834 5.61313 2.68205C5.55731 2.62576 5.49094 2.58122 5.41789 2.55101C5.34484 2.52081 5.26656 2.50555 5.18761 2.50612C5.10842 2.50572 5.02993 2.52105 4.95661 2.55124C4.8833 2.58142 4.81659 2.62587 4.76032 2.68205C4.64666 2.79551 4.58235 2.94985 4.58155 3.11112C4.58075 3.27239 4.64351 3.42737 4.75603 3.54197C4.86855 3.65658 5.02162 3.72142 5.18155 3.72223Z"
                                        fill="#110F33"
                                    />
                                    <path
                                        d="M8.81822 3.72222C9.15294 3.72222 9.42428 3.44862 9.42428 3.11111C9.42428 2.7736 9.15294 2.5 8.81822 2.5C8.4835 2.5 8.21216 2.7736 8.21216 3.11111C8.21216 3.44862 8.4835 3.72222 8.81822 3.72222Z"
                                        fill="#110F33"
                                    />
                                    <path
                                        d="M9.42423 5.55564H4.57575C4.09369 5.55515 3.6315 5.36184 3.29063 5.01813C2.94976 4.67442 2.75805 4.20839 2.75757 3.7223V2.50008C2.75805 2.014 2.94976 1.54797 3.29063 1.20426C3.6315 0.860543 4.09369 0.667233 4.57575 0.666748H9.42423C9.9063 0.667233 10.3685 0.860543 10.7094 1.20426C11.0502 1.54797 11.2419 2.014 11.2424 2.50008V3.7223C11.2419 4.20839 11.0502 4.67442 10.7094 5.01813C10.3685 5.36184 9.9063 5.55515 9.42423 5.55564ZM4.57575 1.88897C4.41506 1.88913 4.261 1.95357 4.14738 2.06814C4.03375 2.18271 3.96985 2.33805 3.96969 2.50008V3.7223C3.96985 3.88433 4.03375 4.03968 4.14738 4.15425C4.261 4.26882 4.41506 4.33325 4.57575 4.33342H9.42423C9.58492 4.33325 9.73898 4.26882 9.85261 4.15425C9.96623 4.03968 10.0301 3.88433 10.0303 3.7223V2.50008C10.0301 2.33805 9.96623 2.18271 9.85261 2.06814C9.73898 1.95357 9.58492 1.88913 9.42423 1.88897H4.57575Z"
                                        fill="#110F33"
                                    />
                                </svg>
                                Learn
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/prepare" activeClassName="active" >
                                <svg
                                    width={14}
                                    height={14}
                                    viewBox="0 0 14 14"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M12.7293 3.8811L3.61041 13H1V10.3896L10.1189 1.27069C10.4798 0.909771 11.0641 0.909771 11.4241 1.27069L12.7293 2.57589C13.0902 2.93681 13.0902 3.52111 12.7293 3.8811Z"
                                        stroke="#110F33"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M8.46753 2.92944L11.0706 5.53247"
                                        stroke="#110F33"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                                Prepare
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/edpay" activeClassName="active" >
                                <svg
                                    width={16}
                                    height={16}
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M12.2104 4.26161L10.5509 1.92595C10.1039 1.29693 9.26932 1.14602 8.64953 1.58217L4.79272 4.29625"
                                        stroke="#110F33"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M12.2104 7.62964H13.9648C14.3524 7.62964 14.6666 7.96128 14.6666 8.37039V10.5926C14.6666 11.0017 14.3524 11.3334 13.9648 11.3334H12.2104C11.2415 11.3334 10.4561 10.5043 10.4561 9.48151V9.48151C10.4561 8.45875 11.2415 7.62964 12.2104 7.62964V7.62964Z"
                                        stroke="#110F33"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M13.9649 7.62951V6.14802C13.9649 5.12525 13.1794 4.29614 12.2105 4.29614H3.08764C2.11872 4.29614 1.33325 5.12525 1.33325 6.14802V12.8148C1.33325 13.8375 2.11872 14.6666 3.08764 14.6666H12.2105C13.1794 14.6666 13.9649 13.8375 13.9649 12.8148V11.3333"
                                        stroke="#110F33"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                                EdPay
                            </NavLink>
                        </li> */}
                    <hr className="divider" />
                    <li className="nav-item">
                        <NavLink className="nav-link" to="/profile" activeClassName="active" >
                            <RiUser3Line /> Profile
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        {/* <NavLink className="nav-link" to="#" activeClassName="active" ></NavLink> */}
                        <NavLink className="nav-link" to="/settings" activeClassName="active" >
                            <FiSettings /> Settings
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink className="nav-link" to="/logout" activeClassName="active" >
                            <BiLogOut />  Logout
                        </NavLink>
                    </li>
                </ul>
            </div>
        </nav>

    )
}

export default LeftSidebar