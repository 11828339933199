import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import MobMenuHeader from '../MobMenuHeader';
import UnivLeftSidebar from '../UnivLeftSidebar';
import Breadcrump from '../Structures/Breadcrump/Breadcrump';
import Footer from '../Footer';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { agentStudentDetailsId } from '../../@redux/dispatch/agents/GetAgentStudentDetailsReducer';
import Skeleton from 'react-loading-skeleton';
import { getCounsellorStudents } from '../../@redux/dispatch/counsellor/students';

function LoaderSkeleton() {
    return (
        <Skeleton count={4} className='py-3 my-1 mx-2' />
    )
}

function NoDataFound() {
    return (
        <div className='title mt-5'>
            <h2 className='text-primary'>Oops ! No students are listed yet 🥺</h2>
        </div>
    )
}

function Lists() {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const data = useSelector((state) => state?.counStudents?.counStudentsList)
    const handleRowClick = (Id) => {
        // dispatch(applicationDetails(Id))
        dispatch(agentStudentDetailsId(Id));
        navigate("view-student?profile_id=" + Id)
    }
    return (
        <>
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone</th>
                </tr>
            </thead>
            {data?.map((student, index) => (
                <tbody key={index}>
                    <tr role='button' onClick={() => { handleRowClick(student?.id) }}>
                        <td data-title="Name"> {student?.Full_Name}</td>
                        <td data-title="Email">{student?.Email}</td>
                        <td data-title="Phone">{student?.Phone}</td>
                        {console.log("This student id is:- ", student?.id)}
                    </tr>
                </tbody>
            ))}
        </>

    )
}


function StudentListCounsellor() {
    const userName = useSelector((state) => (state?.config?.userRole?.Name))
    const data = useSelector((state) => state?.counStudents?.counStudentsList)
    const loading = useSelector((state) => state?.counStudents?.loading)
    console.log("List of student for counsellor", data)
    console.log("User name for counsellor ", userName)
    console.log("Loading data for counsellor", loading)

    const dispatch = useDispatch()

    const breadcrumps = [
        { text: 'Home', path: '/dashboard' },
        { text: 'Students ', path: '/student-list' }
    ]

    useEffect(() => {
        dispatch(getCounsellorStudents())
    }, [])

    return (

        <div className="col-sm-12 col-md-9 ms-sm-auto col-lg-12 col-xl-10 col-xxl-10 ps-5 ps-lg-2">
            <div className="dashboard-right">
                <div className="desktop tablet">
                    <div className="row  top-header sticky-top clearfix">
                        <Breadcrump items={breadcrumps} />

                        <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-5 col-xxl-5">
                            <h2>Students ({data?.length})</h2>
                        </div>
                        <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-7 col-xxl-7 d-flex justify-content-end align-items-center">
                            {/* <div className="search-bar">
                                            <input
                                                type="text"
                                                name="search"
                                                id="search"
                                                className="search-area"
                                                placeholder="Search for Students"
                                            />
                                        </div> */}
                            {/* <button className="btn btn-purple" onClick={handleAddStudent}>
                                            Add Student
                                        </button> */}

                        </div>
                    </div>
                </div>
                <div className="row mobile clearfix">
                    <div className="col-12">
                        <h2>Hello,{userName}</h2>

                        <p className="subtext p-0">Welcome back!</p>
                    </div>
                </div>
                <div className="row clearfix">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                        <div className="student-table-box overflow-auto" style={{ height: '75vh' }}>
                            <table
                                className="table table-borderless student-data text-center"
                                id="studentList"
                            >
                                {loading && <LoaderSkeleton />}
                                {(!loading && data?.length === undefined) && <NoDataFound />}
                                {(!loading && data?.length > 0) && <Lists />}
                            </table>
                            <nav className="my-5" aria-label="Page navigation example">
                                {/* <ul className="pagination justify-content-center">
                                                <li className="page-item">
                                                    <Link className="page-link">
                                                        <i className="bi bi-chevron-left" />
                                                    </Link>
                                                </li>
                                                <li className="page-item">
                                                    <Link className="page-link active" to="#">
                                                        1
                                                    </Link>
                                                </li>
                                                <li className="page-item">
                                                    <Link className="page-link" to="#">
                                                        2
                                                    </Link>
                                                </li>
                                                <li className="page-item">
                                                    <Link className="page-link" to="#">
                                                        3
                                                    </Link>
                                                </li>
                                                <li className="page-item">
                                                    <Link className="page-link" to="#">
                                                        <i className="bi bi-chevron-right" />
                                                    </Link>
                                                </li>
                                            </ul> */}
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    )
}

export default StudentListCounsellor