import { TextField } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react'
import { FiUpload } from 'react-icons/fi'
import { useDispatch, useSelector } from 'react-redux';
import { generateSSL, getData, insertData, uploadLogo } from '../../../@redux/dispatch/customisation_dispatch';
import Loader from './../../../components/Loader'

function Branding({ setActiveTab }) {
    const sslResponse = useSelector((state) => (state?.customisation?.generate_ssl_record))
    const insertDataResponse = useSelector((state) => (state?.customisation?.insert_data_record?.status))
    const getDataResponse = useSelector((state) => (state?.customisation?.get_data))
    const userBrandName = useSelector((state) => (state?.customisation?.get_data?.brandName))
    const userDomainName = useSelector((state) => (state?.customisation?.get_data?.domainName))
    const hueVal = useSelector((state) => (state?.customisation?.get_data?.hueVal))
    const logo = useSelector((state) => (state?.customisation?.get_data?.logoUrl))
    const imgUrl = useSelector(state => state.customisation?.logoUrlRes)
    const customisationRes = useSelector(state => state.customisation)

    console.log("get data responses are", getDataResponse)
    console.log("logo responses are", logo)
    console.log("hue val  responses here is in branding page ", hueVal)
    console.log("customisation response is here ", customisationRes)
    console.log("image response for setting branding is here ", imgUrl)

    // console.log("insert data responses are", insertDataResponse)

    const fileInputRef = useRef(null)
    const [selectedImage, setSelectedImage] = useState(null);
    const [brandName, setBrandName] = useState();
    const [domainName, setDomainName] = useState();
    const [uploadImage, setUploadImage] = useState();
    const dispatch = useDispatch()

    useEffect(() => {
        setBrandName(userBrandName)
    }, [userBrandName])


    const handleImageChange = () => {
        const file = fileInputRef.current.files[0];
        console.log("Image file inside handle image change", file)
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const dataURL = e.target.result;
                setSelectedImage(dataURL);
                setUploadImage(file);
            }
            reader.readAsDataURL(file);
        }
        setSelectedImage(file);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("Selected image is here:- ", uploadImage)
        if (uploadImage)

            dispatch(generateSSL({
                "domainName": domainName || userDomainName,
                "hueVal": hueVal,
                "brandName": brandName,
                "logo": uploadImage,
            }, setActiveTab))
        else
            dispatch(insertData({ domainName: userDomainName, brandName, hueVal, "logoUrl": logo }, setActiveTab))


        // console.log("domainName", domainName || userDomainName,
        //     "hueVal", hueVal,
        //     "brandName", brandName,
        //     "logo", uploadImage,)


        // dispatch(uploadLogo(uploadImage))
    }

    // useEffect(() => {
    //     // console.log("selected image is", selectedImage)
    //     // console.log("selected brand name is", brandName)
    // }, [selectedImage, brandName])

    return (
        <>
            {/* <Loader /> */}
            <div className='overflow-auto' style={{ height: '76vh' }}>
                <div className="mt-5 w-50 p-0 ps-3 mx-auto shadow border rounded-3">
                    <form className='p-4 m-3' onSubmit={handleSubmit}>
                        <div className="form-group mb-4">
                            <label className='form-check-label mb-2'>Brand Name</label>
                            <input
                                type="text"
                                id='brand'
                                name="brand"
                                className="form-control"
                                placeholder="Enter your brand name"
                                onChange={(e) => { setBrandName(e.target.value) }}
                                value={brandName}

                            />
                        </div>
                        <div className="form-group ">
                            <label className='form-check-label mb-2'>Domain Name</label>
                            <input
                                type="text"
                                id='domain'
                                name="domain"
                                className="form-control"
                                placeholder="Enter your domain name"
                                onChange={(e) => { setDomainName(userDomainName || e.target.value) }}
                                value={userDomainName}
                                disabled={userDomainName ? true : false}
                            />
                        </div>
                        <div>
                            <label className='form-check-label d-flex flex-column justify-content-center mt-3 py-3' htmlFor='uploadLogo' role='button' style={{ borderStyle: 'dotted', backgroundColor: '#f5f5f5' }}>
                                {/* {(selectedImage || imgUrl) ? <img src={selectedImage || imgUrl?.brandingImg} alt='logo' /> : <>
                                    <div className="d-flex mt-3" >
                                        <FiUpload className='mx-auto' size={25} />
                                    </div>
                                    <p className='text-center'>Upload Logo</p>
                                </>
                                } */}
                                {(logo || selectedImage) ? <img src={selectedImage || imgUrl?.brandingImg} alt='logo' /> :
                                    <>
                                        <div className="d-flex mt-3" >
                                            <FiUpload className='mx-auto' size={25} />
                                        </div>
                                        <p className='text-center'>Upload Logo</p>
                                    </>
                                }
                            </label>
                            <input id='uploadLogo' type='file' accept='image/*' hidden onChange={handleImageChange} ref={fileInputRef} />
                            <div className='text-end'>
                                {userDomainName?.length !== undefined ?
                                    <button type='submit' className='mt-3 btn btn-purple'>
                                        Update
                                    </button>
                                    :
                                    <button type='submit' className='mt-3 btn btn-purple'>
                                        Save
                                    </button>}
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}
export default Branding