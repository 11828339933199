import React from 'react'
import { useSelector } from 'react-redux'

function ViewStudentAddressDetails({ setActiveTab }) {
    const addressData = useSelector((state) => state.agentStudentDetails?.studentDetails?.data?.[0])


    return (
        <div
            className="tab-pane fade show active"
            id="pills-studentaddress"
            role="tabpanel"
            aria-labelledby="pills-studentaddress-tab"
        >
            <form
            // onSubmit={handleUpdate}
            >

                <div className="form-area">
                    <div className="form-head">
                        Your Address Details{" "}
                        <span>We will use your address details while processing your applications</span>
                    </div>
                    <div className="form-subhead">
                        <hr />
                    </div>
                    <div className="row clearfix">
                        <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                            <label className='form-check-label'>Street Address Line 1</label>
                            <input
                                type="text"
                                id='Street_Address'
                                name="Street_Address"
                                className="form-control"
                                placeholder="Street Address Line 1"
                                value={addressData?.Street_Address}
                                disabled
                            // onChange={handleInputChange}
                            />
                        </div>
                        <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                            <label className='form-check-label'>Street Address Line 2</label>
                            <input
                                type="text"
                                id='Street_Address_Line_2'
                                name="Street_Address_Line_2"
                                className="form-control"
                                placeholder="Street Address Line 2"
                                value={addressData?.Street_Address_Line_2}
                                disabled
                            // onChange={handleInputChange}
                            />
                        </div>
                        <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                            <label className='form-check-label'>City</label>
                            <input
                                type="text"
                                name="City"
                                id="City"
                                className="form-control"
                                placeholder="City"
                                value={addressData?.City}
                                disabled
                            // onChange={handleInputChange}
                            />
                        </div>
                        <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                            <label className='form-check-label'>State/Province</label>
                            <input
                                type="text"
                                id='Mailing_State'
                                name="Mailing_State"
                                className="form-control"
                                placeholder="State"
                                value={addressData?.Mailing_State}
                                disabled
                            // onChange={handleInputChange}
                            />
                        </div>

                        <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                            <label className='form-check-label'>Postal/Zip Code</label>
                            <input
                                type="Number"
                                id='Mailing_Zip'
                                name="Mailing_Zip"
                                className="form-control"
                                placeholder="Postal Code"
                                value={addressData?.Mailing_Zip}
                                disabled
                            // onChange={handleInputChange}
                            />
                        </div>

                        <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                            <label className='form-check-label'>Country</label>
                            <input
                                type='text'
                                name='Country'
                                id='Country'
                                className='form-control'
                                placeholder='Country'
                                value={addressData?.Country}
                                disabled
                            // onChange={handleInputChange}
                            />
                        </div>
                    </div>

                    <div className='container'>
                        <div className='row justify-content-end clearfix'>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 text-end p-4 form-bottom" >
                                <button className="btn btn-theme me-1" onClick={() => { setActiveTab("studentpersonal") }}>Previous</button>
                            </div>
                        </div>
                    </div>
                </div>


            </form>
        </div>
    )
}

export default ViewStudentAddressDetails