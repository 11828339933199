import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { postChatNotify } from '../../../@redux/dispatch/chatNotify';
import { useLocation } from 'react-router-dom';

function PostChatNotify() {
    const [message, setMessage] = useState('')
    const dispatch = useDispatch()
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search)
    const application_id = queryParams.get('application');

    const application = application_id.slice(-6)

    console.log("Application id :- ", application)

    const handleInputChange = (e) => {
        setMessage(e.target.value);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(postChatNotify({ text: message }, application))
        setMessage('')
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="form-container">
                <h3 className="formTitle">
                    Remarks / Notes
                </h3>
                <p className="formText">
                    Add any remarks related to application
                </p>
                <div className="row gx-5 clearfix">
                    <div className="col-12 col-sm-6 col-md-7 col-lg-8 col-xl-9 col-xxl-9">
                        <div className="form-group ">
                            <input
                                name="message"
                                id="message"
                                className="form-control"
                                placeholder='Enter Your Message ...'
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>

                    <div className="col-12 col-sm-6 col-md-5 col-lg-4 col-xl-3 col-xxl-3">
                        <button className='btn btn-purple my-2' type='submit'>Send</button>
                    </div>

                </div>
            </div>
        </form>
    )
}

export default PostChatNotify